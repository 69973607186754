
const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {

    // const url = `http://localhost:5000/api/v1/rent/contract/follow/details/list`;
    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/rent/contract/follow/details/list`

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}



export const update = (values: any) => {

    // const url = process.env.REACT_APP_API_BASE_URL + `/v1/seisan/follow/list`;

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follow/details/update`;
    // let url = `http://localhost:5000/api/v1/rent/contract/follow/details/update`;

    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(values))
    // alert(JSON.stringify(values))


    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}





export const follow_delete = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follow/details/delete`;
    // let url = `http://localhost:5000/api/v1/rent/contract/follow/details/delete`;

    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(values))
    // alert(JSON.stringify(values))

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}




export const easy_update = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follow/details/easy`;
    // let url = `http://localhost:5000/api/v1/rent/contract/follow/details/easy`;

    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(values))
    // alert(JSON.stringify(values))


    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}