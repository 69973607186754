import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

var moment = require('moment-timezone');


const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;

    max-width: 250px;
    border-radius: 12px;
    background: white;
    margin-bottom: 2px;
    text-align: left;

`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 5 0 5px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const Title = styled.div`
    padding:15px;
    text-align:left;
`;

const Detail = styled.div`
    padding:15px;
    padding-top:0px;
    font-size:10px;
    height:40px;
    text-align:left;
`;

const Menu1 = styled.div`
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #dcdcdc;
    font-weight:bold;
    text-align:center;
`;


//export default class LineTextComponent extends React.Component {
class LineRightType5Component extends Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],

            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            lists: this.props.msg.message_text.split(','),
        };
    }

    pageOpen = (url) => {

        window.open(url)
    }

    render() {
        return (
            <Balloon className="col-xs-12">
                <Chatting>
                    <Time>
                        <div>{this.props.users && this.props.msg.user_id > 0  ? (this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id))[0]?this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id))[0].nickname: "") : ""}</div>
                        <div>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</div>
                    </Time>

                    <Says>
                        {/* <List> */}
                            {/* <ListItem> */}
                                {/* <div><img className="l_img" border="0" src={"https://img.global-center.co.jp/v1/view/line-applicationform.jpg"} width="200" alt="" /></div> */}
                                <Title>口座振替受付サービスのご案内</Title>
                                <Detail>下記のボタンの口座振替受付サービスからお手続きをお願いいたします。</Detail>
                                <Menu1 onClick={()=>this.pageOpen(this.props.msg.message_text)} className="col-xs-12">口座振替受付サービスへ</Menu1>
                            {/* </ListItem> */}
                        {/* </List> */}
                    </Says>

                </Chatting>

            </Balloon>

        )
    }
}
//コンテナ
const mapStateToProps = state => {
    return {
        users: state.masterReducer.users,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        // get_firestore_listen(state) {
        //     dispatch(Line.get_firestore_listen(state))
        // },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineRightType5Component);