
const authentication = require('../../../react-azure-adb2c2').default;




export function update(values: any) {


    const token = authentication.getAccessToken();

    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/api/v1/rent/responses/end/` + values.rent_response_id
    // const url = `http://localhost:5000/api/v1/rent/responses/end/` + values.rent_response_id

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}