const authentication = require('../../../react-azure-adb2c2').default;


export const insert = (value: any) => {

    // let url = `http://localhost:5000/v1/rent/tasks/insert`;
    let url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/tasks/insert`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const update = (value: any) => {
    // alert( JSON.stringify(value))

    // let url = `http://localhost:5000/v1/rent/tasks/update/`+ value.id;
    let url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/tasks/update/`+ value.id;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const total_csv = (values: any) => {

    // let url = `http://localhost:5000/v1/rent/tasks/totalcsv`;
    let url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/tasks/totalcsv`;
    // alert(url)
  
    const token = authentication.getAccessToken();
  
    return fetch(url, {
      method: 'post',
      body: JSON.stringify(values),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(response => response.blob())
    .then(payload => ({ payload }))
    // .then(res => res.json())
    // .then(payload => ({ payload }))
    .catch(error => ({ error }));
  
  };