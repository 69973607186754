
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { startSubmit, stopSubmit, reset, submit } from 'redux-form';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as RentResponsesApi from '../Api/RentResponsesApi';
import * as rent_m_agents_api from '../Api/rent_m_agents_api';

import * as rent_m_medea_api from '../Api/rent_m_medea_api';
import * as rent_m_reasons_api from '../Api/rent_m_reasons_api';
import * as rent_m_schools_api from '../Api/rent_m_schools_api';
import * as rent_m_jobs_api from '../Api/rent_m_jobs_api';
import * as rent_m_response_end_reasons_api from '../Api/rent_m_response_end_reasons_api';
import * as laravel_api from '../Api/laravel_api';

import * as rent_hearing_sheets_api from '../Api/rent_hearing_sheets_api';

// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import * as Customers from '../../Customers/Saga/Customers';
import * as Contracts from '../../Contracts/Saga/Contracts';
import * as Mail from '../../Mail/Saga/Mail';

import * as ResponseList from '../../ResponseList/Saga/ResponseList';

import * as Line from '../../Line/Saga/Line';
import * as Files from '../../Files/Saga/Files';
import moment from 'moment';


import * as CustomerContacts from '../../CustomerContacts/Saga/CustomerContacts';

// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}


export interface ResponsesState {
  response_tabs: any;

  responselist: any;
  response_matching_list: any;
  responselist_loading: number;
  response_detail_loading: boolean;
  responseedit: number;
  responseloding: number;

  responsedetail: any;
  responsedetail2: any;
  responseloding2: number;
  responseedit2: number;

  responseid: number;
  customer_logs: any;
  customer_logs_loading: number;
  response_matching_list_loading: number;

  response_matching_select_list: any;
  response_matching_select_list_loading: number;
  // matching: any;
  // introductions: any;
  // introductions_loading:number;
  response_add_loding: boolean;
  responses_search_list: any;
  responses_coming_list: any;

  response_add_disp: boolean;
  response_add_detail: any;
  customer_add_detail: any;
  line_user_id: string;
  hearing_sheet_id: string;
  response_add_select_mail_id_list: any;
  modalResponseDetail: boolean;
  modalMatching: boolean,

  rent_responses_editor: any

  rent_m_agents: any,
  rent_m_medea: any,
  rent_m_reasons: any;
  rent_m_schools: any;
  rent_m_jobs: any;
  rent_m_response_end_reasons: any;

  rent_hearing_sheets: any;

  response_detail_dialog_disp: any;

  response_attention_disp: any;


  rent_response_date_editor:any;
  response_preparation_sheet_loading:boolean;

  rent_responses_free_edit_disp: boolean;
  rent_responses_free_update_alert_disp: any;

  rent_responses_update_status: boolean;

}

const initialState: ResponsesState = {
  response_tabs: "",
  responselist: [],
  response_matching_list: [],
  responselist_loading: 0,
  responseedit: 0,
  responseloding: 0,
  response_detail_loading: false,
  responsedetail: {},
  responseedit2: 0,
  responseloding2: 0,

  responsedetail2: {},

  responseid: 0,
  customer_logs: [],
  customer_logs_loading: 0,
  response_matching_list_loading: 0,

  response_matching_select_list: [],
  response_matching_select_list_loading: 0,
  // matching: [],
  // introductions:[],
  // introductions_loading:0,
  response_add_loding: false,
  responses_search_list: [],
  responses_coming_list: [],

  response_add_disp: false,
  response_add_detail: [],
  customer_add_detail: [],
  line_user_id: "",
  hearing_sheet_id: "",
  response_add_select_mail_id_list: [],
  modalResponseDetail: false,
  modalMatching: false,

  rent_responses_editor: { rank: 0, sex: 1, request_tatemono_types: "", request_area: "", response_requests: "23,30,7", request_chomei: "5,6,7", request_shougakkou: "43,44", request_chuugakkou: "5,7,8" },
  rent_m_agents: [],
  rent_m_medea: [],
  rent_m_reasons: [],
  rent_m_schools: [],
  rent_m_jobs: [],
  rent_m_response_end_reasons: [],
  rent_hearing_sheets: [],

  response_detail_dialog_disp: false,

  response_attention_disp: false,

  rent_response_date_editor:{first_time_at:"", line_way_at:"", apo_get_at:""},
  response_preparation_sheet_loading:false,

  rent_responses_free_edit_disp: false,
  rent_responses_free_update_alert_disp: {type: 0, time:""},

  rent_responses_update_status: false, 
}



///action 
const actionCreator = actionCreatorFactory();

export const change_response_tabs = actionCreator<number>('CHANGE_RESPONSE_TABS');

export const responseeditchange = actionCreator<number>('RESPONSE_EDIT');
export const change_responseid = actionCreator<number>('CHANGE_RESPONSE_ID');
export const responseloding = actionCreator<number>('RESPONSE_LODING');

export const responseUpdate = actionCreator<any>('RESPONSE_UPDATE');
// export const responseUpdateSuccess = actionCreator<any>("RESPONSE_UPDATE_SUCCESS");
export const responseUpdateFailure = actionCreator("RESPONSE_UPDATE_FAILURE");

// export const responseInsert = actionCreator<{ values: any, customerid: number, history: any; }>('RESPONSE_INSERT');
// export const responseInsertSuccess = actionCreator("RESPONSE_INSERT_SUCCESS");
// export const responseInsertFailure = actionCreator("RESPONSE_INSERT_FAILURE");

export const change_response_add_loding = actionCreator<any>('CHANGE_RESPONSE_ADD_LOADING');





export const get_response_matching_list = actionCreator<any>('GET_RESPONSE_MATCHING_LIST');
export const get_response_matching_list_success = actionCreator("GET_RESPONSE_MATCHING_LIST_SUCCESS");
export const change_response_matching_list_loading = actionCreator<number>('CHANGE_RESPONSE_MATCHING_LIST_LOADING');


export const get_responses_search_list = actionCreator<any>('GET_RESPONSES_SEARCH_LIST');
export const get_responses_search_list_success = actionCreator("GET_RESPONSES_SEARCH_LIST_SUCCESS");
export const change_responses_search_list_loading = actionCreator<number>('CHANGE_RESPONSES_SEARCH_LIST_LOADING');

export const get_responses_coming_list = actionCreator<any>('GET_RESPONSES_COMING_LIST');
export const change_responses_coming_list = actionCreator<any>('CHANGE_RESPONSES_COMING_LIST');

export const get_response_matching_select_list = actionCreator<any>('GET_RESPONSE_MATCHING_SELECT_LIST');
export const get_response_matching_select_list_success = actionCreator("GET_RESPONSE_MATCHING_SELECT_LIST_SUCCESS");
export const change_response_matching_select_list_loading = actionCreator<number>('CHANGE_RESPONSE_MATCHING_SELECT_LIST_LOADING');



export const responsedetailData = actionCreator('RESPONSE_DETAIL');
export const responsedetailSuccess = actionCreator("RESPONSE_DETAIL_SUCCESS");
export const responsedetail2Success = actionCreator("RESPONSE_DETAIL2_SUCCESS");
export const responsedetailFailure = actionCreator("RESPONSE_DETAIL_FAILURE");


export const change_responsedetail = actionCreator<any>("CHANGE_RESPONSEDETAIL");

export const change_response_detail_loading = actionCreator<any>('CHANGE_RESPONSE_DETAIL_LOADING');



export const get_customer_logs = actionCreator<{ responseId: number }>('GET_CUSTOMER_LOGS');
export const get_customer_logs_success = actionCreator("GET_CUSTOMER_LOGS_SUCCESS");
export const get_customer_logs_failure = actionCreator("GET_CUSTOMER_LOGS_FAILURE");

export const change_customer_logs_loading = actionCreator<number>('CHANGE_CUSTOMER_LOGS_LOADING');


export const add_response = actionCreator<any>('ADD_RESPONSE');

export const change_rent_responses_editor = actionCreator<any>('CHANGE_RENT_RESPONSES_EDITOR');


export const download_response_preparation_sheet = actionCreator<any>('DOWNLOAD_RESPONSE_PREPARATION_SHEET');
export const change_response_preparation_sheet_loading = actionCreator<any>('CHANGE_RESPONSE_PREPARATION_SHEET_LOADING');
export const open_response_edit = actionCreator<any>('OPEN_RESPONSE_EDIT');
export const open_response_detail_dialog = actionCreator<any>('OPEN_RESPONSE_DETAIL_DIALOG');

// export const change_matching = actionCreator<any>('CHANGE_MATCHING');


// export const merge_response_mails = actionCreator<any>('MERGE_RESPONSE_MAILS');

// export const get_introductions = actionCreator<any>('GET_INTRODUCTIONS');
// export const get_introductions_success = actionCreator<any>('GET_INTRODUCTIONS_SUCCESS');

// export const insert_introductions = actionCreator<any>('INSERT_INTRODUCTIONS');
// export const insert_introductions_success = actionCreator<any>('INSERT_INTRODUCTIONS_SUCCESS');


// export const delete_map_introduction = actionCreator<any>('DELETE_MAP_INTRODUCTION');

// export const change_introductions_loading = actionCreator<number>('INTRODUCTIONS_LOADING');
export const change_response_add_disp = actionCreator<any>('CHANGE_RESPONSE_ADD_DISP');

export const open_response_add = actionCreator<any>('OPEN_RESPONSE_ADD');


export const change_response_add_detail = actionCreator<any>('CHANGE_RESPONSE_ADD_DETAIL');
export const change_customer_add_detail = actionCreator<any>('CHANGE_CUSTOMER_ADD_DETAIL');
export const change_line_user_id = actionCreator<any>('CHANGE_LINE_USER_ID');
export const change_hearing_sheet_id = actionCreator<any>('CHANGE_HEARING_SHEET_ID');
export const change_response_add_select_mail_id_list = actionCreator<any>('CHANGE_RESPONSE_ADD_SELECT_MAIL_ID_LIST');
export const change_modalResponseDetail = actionCreator<any>('CHANGE_MODALRESPNSEDETAIL');
export const change_modalResponseDetailset = actionCreator<any>('CHANGE_MODALRESPNSEDETAILSET');
export const merge_response_mails = actionCreator<any>('MERGE_RESPONSE_MAILS');

export const change_modalMatching = actionCreator<any>('CHANGE_MODALMATCHING');


export const delete_responses = actionCreator<any>('DELETE_RESPONSES');

export const get_rent_m_agents = actionCreator<any>('GET_RENT_M_AGENTS');
export const change_rent_m_agents = actionCreator<any>('CHANGE_RENT_M_AGENTS');

export const get_rent_m_medea = actionCreator<any>('GET_RENT_M_MEDEA');
export const change_rent_m_medea = actionCreator<any>('CHANGE_RENT_M_MEDEA');
export const get_rent_m_reasons = actionCreator<any>("GET_RENT_M_REASONS");
export const change_rent_m_reasons = actionCreator<any>("CHANGE_RENT_M_REASONS");
export const get_rent_m_schools = actionCreator<any>("GET_RENT_M_SCHOOLS");
export const change_rent_m_schools = actionCreator<any>("CHANGE_RENT_M_SCHOOLS");
export const get_rent_m_jobs = actionCreator<any>("GET_RENT_M_JOBS");
export const change_rent_m_jobs = actionCreator<any>("CHANGE_RENT_M_JOBS");
export const get_rent_m_response_end_reasons = actionCreator<any>("GET_RENT_M_REAPONSE_END_REASONS");
export const change_rent_m_response_end_reasons = actionCreator<any>("CHANGE_RENT_M_REAPONSE_END_REASONS");

export const get_rent_hearing_sheets = actionCreator("GET_RENT_HEARING_SHEETS");
export const change_rent_hearing_sheets = actionCreator<any>('CHANGE_RENT_HEARING_SHEETS');

export const change_response_detail_dialog_disp = actionCreator<boolean>('CHANGE_RESPONSE_DETAIL_DIALOG_DISP');

export const download_rent_hearing_sheet = actionCreator<boolean>('DOWNLOAD_RENT_HEARING_SHEET');

export const change_response_attention_disp = actionCreator<boolean>('CHANGE_RESPONSE_ATTENTION_DISP');

export const change_rent_response_date_editor = actionCreator<any>('CHANGE_RENT_RESPONSE_DATE_EDITOR');
export const update_rent_response_date_edit = actionCreator<any>('UPDATE_RENT_RESPONSE_DATE_EDIT');

export const change_rent_responses_free_edit_disp = actionCreator<boolean>('CHANGE_RENT_RESPONSES_FREE_EDIT_DISP');
export const update_rent_responses_free = actionCreator<any>('UPDATE_RENT_RESPONSES_FREE');
export const change_rent_responses_free_update_alert_disp = actionCreator<any>('CHANGE_RENT_RESPONSES_FREE_UPDATE_ALERT_DISP');

export const change_rent_responses_update_status = actionCreator<any>('CHANGE_RENT_RESPONSES_UPDATE_STATUS');


export const responsesReducer = reducerWithInitialState(initialState)
  .case(change_response_tabs, (state, payload) => ({ ...state, response_tabs: payload }))
  .case(change_responseid, (state, payload) => ({ ...state, responseid: payload }))
  .case(responseeditchange, (state, payload) => ({ ...state, responseedit: payload }))
  .case(responseloding, (state, payload) => ({ ...state, responseloding: payload }))
  // .case(responseUpdateSuccess, (state, payload) => ({ ...state, responsedetail: payload }))
  .case(change_responsedetail, (state, payload) => ({ ...state, responsedetail: payload }))
  .case(get_customer_logs_success, (state, payload) => ({ ...state, customer_logs: payload }))
  .case(change_customer_logs_loading, (state, payload) => ({ ...state, customer_logs_loading: payload }))
  // .case(change_responselist_loading, (state, payload) => ({ ...state, responselist_loading: payload }))

  .case(change_response_detail_loading, (state, payload) => ({ ...state, response_detail_loading: payload }))


  // .case(change_matching, (state, payload) => ({ ...state, matching: payload }))
  .case(change_response_add_loding, (state, payload) => ({ ...state, response_add_loding: payload }))

  .case(get_response_matching_list_success, (state, payload) => ({ ...state, response_matching_list: payload }))

  .case(change_response_matching_list_loading, (state, payload) => ({ ...state, response_matching_list_loading: payload }))


  .case(get_responses_search_list_success, (state, payload) => ({ ...state, responses_search_list: payload }))
  .case(change_responses_coming_list, (state, payload) => ({ ...state, responses_coming_list: payload }))

  .case(change_responses_search_list_loading, (state, payload) => ({ ...state, responses_search_list_loading: payload }))



  .case(get_response_matching_select_list_success, (state, payload) => ({ ...state, response_matching_select_list: payload }))

  .case(change_response_matching_select_list_loading, (state, payload) => ({ ...state, response_matching_select_list_loading: payload }))

  // .case(get_introductions_success, (state, payload) => ({ ...state, introductions: payload }))

  // .case(change_introductions_loading, (state, payload) => ({ ...state, introductions_loading: payload }))

  .case(change_response_add_disp, (state, payload) => ({ ...state, response_add_disp: payload }))
  .case(change_response_add_detail, (state, payload) => ({ ...state, response_add_detail: payload }))
  .case(change_customer_add_detail, (state, payload) => ({ ...state, customer_add_detail: payload }))
  .case(change_line_user_id, (state, payload) => ({ ...state, line_user_id: payload }))
  .case(change_hearing_sheet_id, (state, payload) => ({ ...state, hearing_sheet_id: payload }))
  .case(change_response_add_select_mail_id_list, (state, payload) => ({ ...state, response_add_select_mail_id_list: payload }))
  .case(change_modalResponseDetailset, (state, payload) => ({ ...state, modalResponseDetail: payload }))



  .case(change_rent_responses_editor, (state, payload) => ({ ...state, rent_responses_editor: payload }))

  .case(change_rent_m_agents, (state, payload) => ({ ...state, rent_m_agents: payload }))


  .case(change_rent_m_medea, (state, payload) => ({ ...state, rent_m_medea: payload }))
  .case(change_rent_m_reasons, (state, payload) => ({ ...state, rent_m_reasons: payload }))
  .case(change_rent_m_schools, (state, payload) => ({ ...state, rent_m_schools: payload }))
  .case(change_rent_m_jobs, (state, payload) => ({ ...state, rent_m_jobs: payload }))
  .case(change_rent_m_response_end_reasons, (state, payload) => ({ ...state, rent_m_response_end_reasons: payload }))

  .case(change_rent_hearing_sheets, (state, payload) => ({ ...state, rent_hearing_sheets: payload }))

  .case(change_response_detail_dialog_disp, (state, payload) => ({ ...state, response_detail_dialog_disp: payload }))

  .case(change_response_attention_disp, (state, payload) => ({ ...state, response_attention_disp: payload }))

  .case(change_rent_response_date_editor, (state, payload) => ({ ...state, rent_response_date_editor: payload }))
  .case(change_response_preparation_sheet_loading, (state, payload) => ({ ...state, response_preparation_sheet_loading: payload }))

  .case(change_rent_responses_free_edit_disp, (state, payload) => ({ ...state, rent_responses_free_edit_disp: payload }))
  .case(change_rent_responses_free_update_alert_disp, (state, payload) => ({ ...state, rent_responses_free_update_alert_disp: payload }))

  .case(change_rent_responses_update_status, (state, payload) => ({ ...state, rent_responses_update_status: payload }))

  // .case(get_introductions_success, (state, payload) => ({ ...state, introductions: payload }))


  .case(responsedetailSuccess, (state, payload) => ({
    ...state,
    responsedetail: payload,
    responseloding: 1,
    responseedit: 0
  }))

  .case(change_modalMatching, (state, payload) => ({ ...state, modalMatching: payload }))




  .case(responsedetail2Success, (state, payload) => ({
    ...state,
    responsedetail2: payload,
    responseloding2: 1,
    responseedit2: 0
  }))
// .case(responseListSuccess, (state, payload) => ({ ...state, responselist: payload }));





// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const responsesSaga = [takeEvery('RESPONSE_UPDATE', handleRentReasonsesUpdateRequest),
// takeEvery('RESPONSE_INSERT', handleRentReasonsesInsertRequest),
// takeEvery('RESPONSE_LIST', handleRentReasonsesListRequest),
takeEvery('RESPONSE_DETAIL', handleRentReasonsesDetailRequest),
takeEvery('GET_CUSTOMER_LOGS', handle_get_customer_logs_Request),
// takeEvery('GET_RESPONSE_MATCHING_LIST', handle_get_response_matching_list),
// takeEvery('GET_RESPONSE_MATCHING_SELECT_LIST', handle_get_response_matching_select_list),
// takeEvery('MERGE_RESPONSE_MAILS', handle_merge_response_mails),
// takeEvery('GET_INTRODUCTIONS', handle_get_introductions),
// takeEvery('INSERT_INTRODUCTIONS', handle_insert_introductions),
takeEvery('GET_RESPONSES_SEARCH_LIST', handle_get_responses_search_list),
takeEvery('GET_RESPONSES_COMING_LIST', handle_get_responses_coming_list),
takeEvery('MERGE_RESPONSE_MAILS', handle_merge_response_mails),
takeEvery('CHANGE_MODALRESPNSEDETAIL', handle_change_modalResponseDetail),

// takeEvery('ADD_RESPONSE', handle_add_response),


takeEvery('DOWNLOAD_RESPONSE_PREPARATION_SHEET', handle_download_response_preparation_sheet),
takeEvery('OPEN_RESPONSE_EDIT', handle_open_response_edit),
takeEvery('OPEN_RESPONSE_DETAIL_DIALOG', handle_open_response_detail_dialog),

takeEvery('OPEN_RESPONSE_ADD', handle_open_response_add),

takeEvery('DELETE_RESPONSES', handle_delete_responses),

takeEvery('GET_RENT_M_AGENTS', handle_get_rent_m_agents),


takeEvery('GET_RENT_M_MEDEA', handle_get_rent_m_medea),
takeEvery('GET_RENT_M_REASONS', handle_get_rent_m_reasons),
takeEvery('GET_RENT_M_SCHOOLS', handle_get_rent_m_schools),
takeEvery('GET_RENT_M_JOBS', handle_get_rent_m_jobs),
takeEvery('GET_RENT_M_REAPONSE_END_REASONS', handle_get_rent_m_response_end_reasons),

takeEvery('GET_RENT_HEARING_SHEETS', handle_get_rent_hearing_sheets),
takeEvery('DOWNLOAD_RENT_HEARING_SHEET', handle_download_rent_hearing_sheet),
takeEvery('UPDATE_RENT_RESPONSE_DATE_EDIT', handle_update_rent_response_date_edit),

takeEvery('UPDATE_RENT_RESPONSES_FREE', handle_update_rent_responses_free),

  // takeEvery('DELETE_MAP_INTRODUCTION', handle_delete_map_introduction),


];
// export default responsesSaga;
function* handle_change_modalResponseDetail(action: any) {

  if (action.payload === true) {

    yield put(change_modalResponseDetailset(true));
  } else if (action.payload === false) {

    yield put(change_responsedetail({}));
    yield put(change_responseid(0));
    yield put(change_modalResponseDetailset(false));
    yield put(change_rent_hearing_sheets([]));
    yield put(Customers.customerDetailSuccess([]));
    yield put(Customers.change_rent_customer_editor_forms([]));
    yield put(Customers.change_customeredit(0));
    yield put(Line.change_line_open_flg(false));
    yield put(Line.get_lines_success([]));
    yield put(Line.get_line_user_details_success([]));
    yield put(Line.change_line_users([]));
    yield put(change_responsedetail([]));
    yield put(CustomerContacts.change_customer_contacts_timeline(false));
    yield put(CustomerContacts.get_rent_customer_contacts_success([]));

    yield put(Contracts.change_rent_contract_details({}));
    yield put(Contracts.change_rent_contract_details_new({}));
    yield put(Contracts.change_rent_contract_details_old({}));
    yield put(Contracts.change_rent_contract_workflows({}));
    // yield put(Contracts.change_rent_contract_editor_forms({}));
    yield put(Contracts.change_rent_contract_id(0));

    yield put(Mail.close_mail())

    yield put(change_responses_coming_list({}))

  }

}


function* handleRentReasonsesUpdateRequest(action: any): any {
  const state = yield select();

  let rent_responses_editor = state.responsesReducer.rent_responses_editor
  let response_add_select_mail_id_list = state.responsesReducer.response_add_select_mail_id_list

  yield put(change_rent_responses_update_status(true));
  
  if (rent_responses_editor.id > 0) {
    const { payload, error } = yield call(RentResponsesApi.update, rent_responses_editor);
    if (payload && !error) {

      yield put(change_response_add_select_mail_id_list([]));
      // 成功時アクション呼び出し
      yield put(change_responsedetail(payload));
      yield put(responseloding(1));
      yield put(responseeditchange(0));

      // 来店登録時の必須からの保存の場合はダイアログ閉じる。
      yield put(change_response_detail_dialog_disp(false));

      //飛込の場合来店登録あるかチェック
      yield put(CustomerContacts.check_customer_contacts_raiten([]));
    } else {
      alert(error)
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  } else {
    let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms

    let line_user_id = state.responsesReducer.line_user_id

    const { payload, error } = yield call(RentResponsesApi.add, {
      rent_customer_editor_forms: rent_customer_editor_forms,
      rent_responses_editor: rent_responses_editor,
      response_add_select_mail_id_list: response_add_select_mail_id_list,
      line_user_id: line_user_id
    });
    if (payload && !error) {

      console.log(payload)
      if(payload == 1){
      // すでに登録済みの時
        alert("この反響はすでに登録済みです！")
      }else if(payload == 2){
        // すでに登録済みの時
          // alert("信用情報！")
          yield put(change_response_attention_disp(true));
          rent_customer_editor_forms.attentions_check = 0
          yield put(Customers.change_rent_customer_editor_forms(rent_customer_editor_forms));
      }else{
      // 正常に反響作成された時
      // 成功時アクション呼び出し
      // yield put(responseInsertSuccess(payload));
      yield put(change_response_add_disp(0));
      yield put(change_responseid(payload.id));
      yield put(change_modalResponseDetail(true));
      yield put(change_modalMatching(false));
      yield put(change_response_add_select_mail_id_list([]));

      const state = yield select();
      // 成功時アクション呼び出し
      yield put(ResponseList.responseList(state.responselist.formData));
      // action.payload[0].history.push('/customer/'+payload.id);
      yield put(change_response_add_loding(false));

      //飛込の場合来店登録あるかチェック
      yield put(CustomerContacts.check_customer_contacts_raiten([]));

      }
    } else {
      alert(error)
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  }

  yield put(change_rent_responses_update_status(false));
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

}

function* handle_update_rent_responses_free(action: any): any {
  const state = yield select();

  let rent_responses_editor = state.responsesReducer.rent_responses_editor


    const { payload, error } = yield call(RentResponsesApi.update_free, rent_responses_editor);
    if (payload && !error) {

      // 成功時アクション呼び出し
      yield put(change_responsedetail(payload));
      
      yield put(change_rent_responses_free_update_alert_disp({type:2, time:moment().format("YYYY/MM/DD HH:mm:ss")}));

    } else {
      alert(error)
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
}


function* handle_open_response_add(action: any): any {
  const state = yield select();

  let rent_customer_editor_forms = {
    rent_response_id: 0,
    rent_customer_id: 0,
    contract_form: action.payload.contract_form ? action.payload.contract_form : 0,
    name1: action.payload.name1 ? action.payload.name1 : "",
    kana1: action.payload.kana1 ? action.payload.kana1 : "",
    name2: '',
    kana2: '',
    rent_m_country_id: 0,
    rent_m_country_remarks: '',
    sex: action.payload.sex ? action.payload.sex : 1,
    memo: '',
    tel1: action.payload.tel1 ? action.payload.tel1 : "",
    tel1_remarks: '',
    tel2: '',
    tel2_remarks: '',

    fax: '',
    fax_remarks: '',
    mail1: action.payload.mail1 ? action.payload.mail1 : "",

    mail1_remarks: '',
    mail2: '',
    mail2_remarks: '',
    rent_m_corporate_id: 0,
    office_rent_m_corporate_id: 0,
    office_name: '',


    post: action.payload.post ? action.payload.post : "",
    add1: action.payload.add1 ? action.payload.add1 : "",
    add2: action.payload.add2 ? action.payload.add2 : "",
    birthday: action.payload.birthday ? action.payload.birthday : null,

    remarks: '',
    rent_m_agent_id: 0,
    agent_etc: "",
    executive: "",

    attentions_check:1,
  }
  // console.log("ついかするよ")
  // console.log(state.masterReducer.user_details.amSectionId)
  // console.log(action.payload.section_id)
  let rent_responses_editor = {
    id: 0,
    rank: 0,
    date: moment().format('YYYY/MM/DD'),
    request_area: "",
    response_requests: action.payload.response_requests ? action.payload.response_requests : "",
    request_chomei: "",
    request_shougakkou: "",
    request_chuugakkou: "",
    areas: action.payload.areas ? String(action.payload.areas) : "",
    building_name: action.payload.building_name ? action.payload.building_name : "",
    request_to_plan: action.payload.request_to_plan ? action.payload.request_to_plan : 0,
    request_from_plan: action.payload.request_from_plan ? action.payload.request_from_plan : 0,
    request_tatemono_types: "",

    request_to_cost: action.payload.request_to_cost ? action.payload.request_to_cost : 0,
    request_from_cost: action.payload.request_from_cost ? action.payload.request_from_cost : 0,
    student_independent: action.payload.student_independent ? action.payload.student_independent : 0,
    mypage_request_number_of_people: action.payload.mypage_request_number_of_people ? action.payload.mypage_request_number_of_people : 0,

    alone_family: 0,
    reserveroom: "",
    gakunen: action.payload.gakunen ? action.payload.gakunen : 0,
    highschool: "",
    request_year: 0,

    rent_m_school_id: action.payload.rent_m_school_id ? action.payload.rent_m_school_id : 0,
    rent_m_reason_id: action.payload.rent_m_reason_id ? action.payload.rent_m_reason_id : 0,
    primary_user_id: state.masterReducer.user_details.id,

    coming_user_id: (action.payload.rent_m_way_id ? action.payload.rent_m_way_id : 5) === 5 ? state.masterReducer.user_details.id : "",
    // first_contact_section_id: state.masterReducer.user_details.amSectionId,
    // first_contact_section_id: state.masterReducer.user_details.amSectionId == 16 ? 3 : state.masterReducer.user_details.amSectionId,
    first_contact_section_id: state.masterReducer.user_details.id == 1392 || state.masterReducer.user_details.id == 1015 ? 56 : state.masterReducer.user_details.amSectionId,
    user_id: state.masterReducer.user_details.id,

    // section_id: action.payload.section_id ? action.payload.section_id : state.masterReducer.user_details.amSectionId,
    section_id: action.payload.section_id ? action.payload.section_id : state.masterReducer.user_details.amSectionId == 16 ? 3 : state.masterReducer.user_details.amSectionId,
    web_user_id: (state.masterReducer.user_details.amSectionId == 10 || state.masterReducer.user_details.amSectionId == 26 || state.masterReducer.user_details.amSectionId == 37 || state.masterReducer.user_details.amSectionId == 55) ? state.masterReducer.user_details.id : null,
    web_section_id: (state.masterReducer.user_details.amSectionId == 10 || state.masterReducer.user_details.amSectionId == 26 || state.masterReducer.user_details.amSectionId == 37 || state.masterReducer.user_details.amSectionId == 55) ? state.masterReducer.user_details.amSectionId : null,

    responsed_buildings: action.payload.building_name,

    free_residence: action.payload.free_residence,
    free_moving_reason: action.payload.free_moving_reason,
    free_person_attributes: action.payload.free_person_attributes,
    free_office_school: action.payload.free_office_school,
    free_number_of_people: action.payload.free_number_of_people,
    free_rent: action.payload.free_rent,
    free_madori: action.payload.free_madori,
    free_rent_remarks: action.payload.free_rent_remarks,
    free_area: action.payload.free_area,
    free_move_in_period: action.payload.free_move_in_period,
    free_car: action.payload.free_car,
    free_remarks: action.payload.free_remarks,


    rent_m_medium_id: action.payload.rent_m_medium_id ? action.payload.rent_m_medium_id : 0,
    medium_remarks: action.payload.medium_remarks ? action.payload.medium_remarks : "",
    rent_m_agent_id: 0,
    request_moving_time: action.payload.request_moving_time ? action.payload.request_moving_time : 0,
    rent_m_way_id: action.payload.rent_m_way_id ? action.payload.rent_m_way_id : 5,

    mail_is_tel: action.payload.tel1 ? 1 : 0,






    mypage_student_independent: action.payload.mypage_student_independent ? action.payload.mypage_student_independent : 0,//ok
    mypage_rent_m_job_id: action.payload.mypage_rent_m_job_id ? action.payload.mypage_rent_m_job_id : null,//★
    mypage_office_name: action.payload.mypage_office_name ? action.payload.mypage_office_name : null,//ok
    mypage_office_add: action.payload.mypage_office_add ? action.payload.mypage_office_add : null,//ok
    mypage_student_examinee: action.payload.mypage_student_examinee ? action.payload.mypage_student_examinee : null,//★
    mypage_request_rent_m_school_id: action.payload.mypage_request_rent_m_school_id ? action.payload.mypage_request_rent_m_school_id : null,//ok
    mypage_request_gakunen: action.payload.mypage_request_gakunen ? action.payload.mypage_request_gakunen : null,//ok
    mypage_request_rent_m_highschool_id: action.payload.mypage_request_rent_m_highschool_id ? action.payload.mypage_request_rent_m_highschool_id : null,//★
    mypage_request_areas_request: action.payload.mypage_request_areas_request ? action.payload.mypage_request_areas_request : null,//★
    mypage_request_areas: action.payload.mypage_request_areas ? action.payload.mypage_request_areas : null,//ok
    mypage_request_from_cost: action.payload.mypage_request_from_cost ? action.payload.mypage_request_from_cost : 0,//ok
    mypage_request_to_cost: action.payload.mypage_request_to_cost ? action.payload.mypage_request_to_cost : 0,//ok
    mypage_rent_current: action.payload.mypage_rent_current ? action.payload.mypage_rent_current : null,//ない
    mypage_request_plans: action.payload.mypage_request_plans ? action.payload.mypage_request_plans : null,//ない
    request_media: action.payload.request_media ? action.payload.request_media : null,//ない
    mypage_introducer: action.payload.mypage_introducer ? action.payload.mypage_introducer : 0,//ない
    reason_remarks: action.payload.reason_remarks ? action.payload.reason_remarks : null,//ない
    mypage_request_moving_time: action.payload.mypage_request_moving_time ? action.payload.mypage_request_moving_time : 0,//ok
    // mypage_request_number_of_people: action.payload.mypage_request_number_of_people,//ない
    mypage_request_number_of_people_adult: action.payload.mypage_request_number_of_people_adult ? action.payload.mypage_request_number_of_people_adult : 0,//ない
    mypage_request_number_of_people_child: action.payload.mypage_request_number_of_people_child ? action.payload.mypage_request_number_of_people_child : 0,//ない
    mypage_request_number_of_standardcar: action.payload.mypage_request_number_of_standardcar ? action.payload.mypage_request_number_of_standardcar : 0,//★
    mypage_request_number_of_lightcar: action.payload.mypage_request_number_of_lightcar ? action.payload.mypage_request_number_of_lightcar : 0,//★
    mypage_dog: action.payload.mypage_dog ? action.payload.mypage_dog : 0,//ない
    mypage_dog_count: action.payload.mypage_dog_count ? action.payload.mypage_dog_count : 0,//ない
    mypage_dog_remarks: action.payload.mypage_dog_remarks ? action.payload.mypage_dog_remarks : null,//ない
    mypage_cat: action.payload.mypage_cat ? action.payload.mypage_cat : 0,//ない
    mypage_cat_count: action.payload.mypage_cat_count ? action.payload.mypage_cat_count : 0,//ない
    mypage_cat_remarks: action.payload.mypage_cat_remarks ? action.payload.mypage_cat_remarks : null,//ない
    mypage_response_requests: action.payload.mypage_response_requests ? action.payload.mypage_response_requests : null,//ok
    mypage_request_bike: action.payload.mypage_request_bike ? action.payload.mypage_request_bike : null,//★
    mypage_request_remarks: action.payload.mypage_request_remarks ? action.payload.mypage_request_remarks : null,//★
    mypage_edited_at: action.payload.mypage_edited_at ? action.payload.mypage_edited_at : moment().format("YYYY-MM-DD"),//★


    rent_hearing_sheet_id: action.payload.rent_hearing_sheet_id ? action.payload.rent_hearing_sheet_id : null,





  }



  yield put(change_response_add_disp(1));


  // 成功時アクション呼び出し
  yield put(change_rent_responses_editor(rent_responses_editor));

  yield put(Customers.change_rent_customer_editor_forms(rent_customer_editor_forms));

  // 信用情報初期化
  yield put(change_response_attention_disp(false));

}



function* handle_get_responses_search_list(action: any) {

  yield put(change_responses_search_list_loading(1));
  const reset: any = [];
  yield put(get_responses_search_list_success(reset));
  const { payload, error } = yield call(RentResponsesApi.responses_search_list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  console.log(payload)
  if (payload && !error) {
    yield put(get_responses_search_list_success(payload));
    yield put(change_responses_search_list_loading(0));
    // yield put(change_responselist_loading(0));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}

function* handle_get_responses_coming_list(action: any) {


  const { payload, error } = yield call(RentResponsesApi.responses_coming_list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  console.log(payload)
  if (payload && !error) {
    yield put(change_responses_coming_list(payload));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}


function* handle_download_response_preparation_sheet(action: any): any {
  yield put(change_response_preparation_sheet_loading(true))

    const state = yield select();

    let responsedetail = state.responsesReducer.responsedetail

    const { payload, error } = yield call(laravel_api.excel, responsedetail.id)
    if (payload && !error) {
        console.log(payload)
        const a = document.createElement('a');
        a.download = "準備シート.pdf";
        a.href = URL.createObjectURL(payload);
        a.click();

    } else {
        alert(error)
    }

    yield put(change_response_preparation_sheet_loading(false))
    // yield put(change_vacancy_log_backdrop(false))
}

function* handle_open_response_edit(action: any): any {
  const state = yield select();
  // 成功時アクション呼び出し
  let responsedetail = state.responsesReducer.responsedetail

  yield put(change_rent_responses_editor(responsedetail));
  yield put(responseeditchange(1));

  if(state.responsesReducer.rent_m_schools.length == 0){
    yield put(get_rent_m_schools([]));
  }
  

  
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}

function* handle_open_response_detail_dialog(action: any): any {
  const state = yield select();
  // 成功時アクション呼び出し
  let responsedetail = state.responsesReducer.responsedetail

  yield put(change_rent_responses_editor(responsedetail));
  yield put(change_response_detail_dialog_disp(true));
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}





function* handleRentReasonsesDetailRequest(action: any) {

  yield call(change_response_detail_loading, true);
  // let { payload } = action;

  const { payload, error } = yield call(RentResponsesApi.getdetail, action.payload.rent_response_id);

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  //yield fork(payload.customerId
  if (payload && !error) {
    payload.request_areaset = payload.areas ? payload.areas.split(",").map(Number) : [];
    payload.request_tatemono_typesset = payload.request_tatemono_types ? payload.request_tatemono_types.split(",").map(Number) : [];

    payload.check_group = payload.response_requests ? payload.response_requests.split(",").map(Number) : [];
    payload.check_chomei = payload.request_chomei ? payload.request_chomei.split(",").map(Number) : [];
    payload.check_shougakkou = payload.check_shougakkou ? payload.check_shougakkou.split(",").map(Number) : [];
    payload.check_chuugakkou = payload.request_chuugakkou ? payload.request_chuugakkou.split(",").map(Number) : [];
    if (payload.check_group.indexOf(24) >= 0) {
      payload.gasu = true;
      payload.sel_gas = 24;
    }

    if (payload.check_group.indexOf(23) >= 0) {
      payload.gasu = true;
      payload.sel_gas = 23;
    }
    if (payload.check_group.indexOf(22) >= 0) {
      payload.gasu = true;
      payload.sel_gas = 22;
    }
    if (payload.check_group.indexOf(95) >= 0) {
      payload.gasu = true;
      payload.sel_gas = 95;
    }



    if (payload.check_group.indexOf(27) >= 0) {
      payload.denki = true;
      payload.sel_denki = 27;
    }
    if (payload.check_group.indexOf(28) >= 0) {
      payload.denki = true;
      payload.sel_denki = 28;
    }
    if (payload.check_group.indexOf(93) >= 0) {
      payload.denki = true;
      payload.sel_denki = 93;
    }
    if (payload.check_group.indexOf(122) >= 0) {
      payload.denki = true;
      payload.sel_denki = 122;
    }



    if (payload.check_group.indexOf(29) >= 0) {
      payload.ih = true;
      payload.sel_ih = 29;
    }
    if (payload.check_group.indexOf(30) >= 0) {
      payload.ih = true;
      payload.sel_ih = 30;
    }
    if (payload.check_group.indexOf(59) >= 0) {
      payload.ih = true;
      payload.sel_ih = 59;
    }
    if (payload.check_group.indexOf(92) >= 0) {
      payload.ih = true;
      payload.sel_ih = 92;
    }



    if (payload.check_group.indexOf(26) >= 0) {
      payload.gasuka = true;
      payload.sel_gaska = 26;
    }
    if (payload.check_group.indexOf(25) >= 0) {
      payload.gasuka = true;
      payload.sel_gaska = 25;
    }
    if (payload.check_group.indexOf(94) >= 0) {
      payload.gasuka = true;
      payload.sel_gaska = 94;
    }



    if (payload.check_group.indexOf(31) >= 0) {
      payload.denkika = true;
      payload.sel_denkika = 31;
    }
    if (payload.check_group.indexOf(32) >= 0) {
      payload.denkika = true;
      payload.sel_denkika = 32;
    }





    if (payload.check_group.indexOf(123) >= 0) {
      payload.ihka = true;
      payload.sel_ihka = 123;
    }
    if (payload.check_group.indexOf(124) >= 0) {
      payload.ihka = true;
      payload.sel_ihka = 124;
    }




    if (payload.check_group.indexOf(58) >= 0) {
      payload.eakon = true;
      payload.sel_eakon = 58;
    }
    if (payload.check_group.indexOf(7) >= 0) {
      payload.eakon = true;
      payload.sel_eakon = 7;
    }
    if (payload.check_group.indexOf(8) >= 0) {
      payload.eakon = true;
      payload.sel_eakon = 8;
    }
    if (payload.check_group.indexOf(119) >= 0) {
      payload.eakon = true;
      payload.sel_eakon = 119;
    }
    if (payload.check_group.indexOf(151) >= 0) {
      payload.eakon = true;
      payload.sel_eakon = 151;
    }
    if (payload.check_group.indexOf(121) >= 0) {
      payload.eakon = true;
      payload.sel_eakon = 121;
    }

    if (payload.check_group.indexOf(115) >= 0) {
      payload.eakon = true;
      payload.sel_eakon = 115;
    }

    payload.first_contact_at = payload.first_contact_at ? moment(payload.first_contact_at).utc().format("YYYY/MM/DD HH:mm") : "" //初回リアクション

    if (action.payload.responses_search === true) {
      yield put(responsedetail2Success(payload));
    } else {
      yield put(responsedetailSuccess(payload));
    }

    let customer: any = [];
    customer.no = 1;

    customer.customerid = payload.rent_customer_id;
    customer.rent_response_id = payload.id;
    console.log(customer);
    yield put(Customers.customerDetail(customer));

    yield call(change_response_detail_loading, false);
    yield put(Line.get_line_users(payload.rent_customer_id));
    yield put(Files.get_files(action.payload.rent_response_id));

    if(payload.rent_hearing_sheet_id > 0) {
      yield put(get_rent_hearing_sheets(payload.rent_hearing_sheet_id));
    }

    let rent_response_date_editor: any = {};
    rent_response_date_editor.date = payload.date //反響日
    rent_response_date_editor.first_contact_at = payload.first_contact_at ? moment(payload.first_contact_at).format("YYYY/MM/DD HH:mm") : "" //初回リアクション
    rent_response_date_editor.line_way_at = payload.line_way_at //LINE誘導
    rent_response_date_editor.apo_get_at = payload.apo_get_at //アポ取得日
    rent_response_date_editor.coming_first_intend_at = payload.coming_first_intend_at //初回来店予定日
    rent_response_date_editor.prepare_at = payload.prepare_at  //準備完了日
    rent_response_date_editor.coming_intend_datetime = payload.coming_intend_datetime//来店日
     rent_response_date_editor.application_date = ""//申込日

    
    yield put(change_rent_response_date_editor(rent_response_date_editor));

    //
    yield put(change_rent_responses_free_edit_disp(false));

  }

  // let customer2: any = [];
  // customer2.no = 2;
  // customer2.customerid = payload.rentCustomerId2;
  // yield put(Customers.customerDetail(customer2));
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}


function* handle_get_customer_logs_Request(action: any) {
  yield put(change_customer_logs_loading(1));


  const { payload, error } = yield call(RentResponsesApi.get_by_customerid_list, action.payload);

  if (payload && !error) {
    // 成功時アクション呼び出し
    console.log(payload)
    yield put(get_customer_logs_success(payload));
    yield put(change_customer_logs_loading(0));
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_merge_response_mails(action: any): any {

  const { payload, error } = yield call(RentResponsesApi.merge_response_mails, action.payload);

  if (payload && !error) {
    // 成功時アクション呼び出し
    console.log(payload)
    // yield put(get_customer_logs_success(payload));
    // yield put(change_customer_logs_loading(0));
    yield put(change_responseid(action.payload.responseId));
    yield put(change_modalResponseDetail(true));
    yield put(change_modalMatching(false));
    //  yield put(submit('formset'));
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し
    yield put(ResponseList.responseList(state.responselist.formData));
    // action.payload.history.push('/customer/'+action.payload.responseId);
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}





function* handle_delete_responses(action: any): any {

  // yield put(change_responses_search_list_loading(1));
  const reset: any = [];
  // yield put(get_responses_search_list_success(reset));
  const { payload, error } = yield call(RentResponsesApi.response_delete, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し
  console.log(payload)
  if (payload && !error) {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し
    yield put(ResponseList.responseList(state.responselist.formData));
    yield put(change_modalResponseDetail(false));

    // yield put(change_responselist_loading(0));
  }
  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}


function* handle_get_rent_m_agents(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_agents_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_agents(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}



function* handle_get_rent_m_medea(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_medea_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_medea(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}

// 理由
function* handle_get_rent_m_reasons(action: any) {

  const { payload, error } = yield call(rent_m_reasons_api.list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    yield put(change_rent_m_reasons(payload))
  } else {

  }
}

// 学校
function* handle_get_rent_m_schools(action: any) {

  const { payload, error } = yield call(rent_m_schools_api.list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    yield put(change_rent_m_schools(payload))
  } else {

  }
}

// 職業
function* handle_get_rent_m_jobs(action: any) {

  const { payload, error } = yield call(rent_m_jobs_api.list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    yield put(change_rent_m_jobs(payload))
  } else {

  }
}

// 追客終了理由
function* handle_get_rent_m_response_end_reasons(action: any) {
  const { payload, error } = yield call(rent_m_response_end_reasons_api.list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    yield put(change_rent_m_response_end_reasons(payload))
  } else {

  }
}

// 職業
function* handle_get_rent_hearing_sheets(action: any) {
  // alert(action.payload)
  const { payload, error } = yield call(rent_hearing_sheets_api.list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    yield put(change_rent_hearing_sheets(payload))
  } else {

  }
}

function* handle_download_rent_hearing_sheet(action: any): any {
  const state = yield select();

  const { payload, error } = yield call(rent_hearing_sheets_api.download, action.payload)
  if (payload && !error) {
    console.log(payload)
    // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
    const a = document.createElement('a');
    a.download = "来店カード.pdf";
    a.href = URL.createObjectURL(payload);
    a.click();
    // check_contract_sheet_file[i] = {}
    // check_contract_sheet_file[i].file =payload
  } else {
    alert(error)
  }

}

function* handle_update_rent_response_date_edit(action: any): any {


  const state = yield select();

  let responsedetail = state.responsesReducer.responsedetail
  let rent_response_date_editor = state.responsesReducer.rent_response_date_editor
  rent_response_date_editor.id = responsedetail.id

  // alert(responsedetail.id)
  // return
  const { payload, error } = yield call(RentResponsesApi.date_update, rent_response_date_editor);
  if (payload && !error) {

    
    responsedetail.first_contact_at = rent_response_date_editor.first_contact_at //初回リアクション
    responsedetail.line_way_at = rent_response_date_editor.line_way_at //LINE誘導
    responsedetail.apo_get_at = rent_response_date_editor.apo_get_at //アポ取得日
    responsedetail.coming_first_intend_at = rent_response_date_editor.coming_first_intend_at //初回来店予定日
    responsedetail.prepare_at = rent_response_date_editor.prepare_at  //準備完了日


    
    yield put(change_responsedetail(JSON.parse(JSON.stringify(responsedetail))));


  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}