import React, { Component } from 'react';

import { connect } from 'react-redux';

import ContractCreate from './ContractCreate';
import ContractMoneyDisp from './ContractMoneyDisp';
import ContractInfoDisp from './ContractInfoDisp';
import ContractAttention from './ContractAttention';
import ContractCosignerDisp from './ContractCosignerDisp';
import ContractApplication from './ContractApplication';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import PdfDisplay from './PdfDisplay';
// import CustomerInfoDisp from './_CustomerInfoDisp.tsx_';
import Customer from '../Customers/Index';
import Car from './Car';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Family from './Family';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
// import Button from '@mui/material/Button';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CardActions from '@mui/material/CardActions';
// import CustomerInfoDispResponse from '../Customers/CustomerInfoDispComponent';
import * as Mail from '../Mail/Saga/Mail';
import * as Files from '../Files/Saga/Files';
import * as MailTemplate from '../MailTemplate/Saga/MailTemplate';
import ContractSpecialDisp from './ContractSpecialDisp';
import ContractVer from './ContractVer';
import Attentions from './Attentions';
// import CustomerEdit from './CustomerEdit';
// import ContractZenhoren from './ContractZenhoren';
import ContractReqEdit from './ContractReqEdit';
import ContractFlow from './ContractFlow';
import ContractEdit from './ContractEdit';
import EmergencyContactEdit from './EmergencyContactEdit';
import ContractSpecialEdit from './ContractSpecialEdit';
import ContractSheetModal from './ContractSheetModal';

import LineSign from '../Line/LineSign';
// import CustomerInfoEdit from '../Customers/CustomerInfoEditComponent';
import Loading from '../Layout/LoadingComponent';
import TextField from '@mui/material/TextField';
import EstimatesInfoEditComponent from '../Estimates/EstimatesInfoEditComponent';
import Grid from '@mui/material/Grid';
import ContractEtcMoneyDisp from './ContractEtcMoneyDisp';
import CarEdit from './CarEdit';
import ContractEtc from './ContractEtc';
import ContractEtcEdit from './ContractEtcEdit';
import ContractIppan from './ContractIppan';
import ContractIppanEdit from './ContractIppanEdit';
import ContractDocDl from './ContractDocDl';
import ContractReSend from './ContractReSend';

import * as Estimates from '../Estimates/Saga/Estimates';
import * as Contracts from './Saga/Contracts';
import * as Responses from '../ResponseDetail/Saga/Responses';
import { Row, Col } from 'reactstrap';
import ContactsComponent from '../CustomerContacts/ContactsComponent';
import "react-input-range/lib/css/index.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FilesComponent from '../Files/Index';
import MailResponseComponent from '../Mail/MailResponseComponent';
import ContractMoneyMonth from './ContractMoneyMonth';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import withStyles from '@mui/styles/withStyles';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import LineDispByUser from '../Line/LineDispByUser';
import CardHeader from '@mui/material/CardHeader';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import { compose } from 'redux'

const styles = (theme) => ({

  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: 20000,
    color: '#fff',
  },

})

const zoomlist = [
  { value: 75, label: "75%" },
  { value: 100, label: "100%" },
  { value: 125, label: "125%" },
  { value: 150, label: "150%" },
  { value: 200, label: "200%" },
  { value: 300, label: "300%" },
  { value: 400, label: "400%" },
]

class ContractsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 125,
      refresh: false,
      rent_contract_id: props.responsedetail.rent_contract_id ? props.responsedetail.rent_contract_id : (props.match ? props.match.params.rent_contract_id : props.responsedetail.type === 1 ? 19 : 18)
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };


    props.get_estimates({ rentEstimateId: 0, tatemonoId: this.props.tatemonoId, roomId: this.props.roomId });
    // props.customerNewDispSet(1)
    // props.change_customeredit(0)

    const rent_contract_id = props.responsedetail.rent_contract_id ? props.responsedetail.rent_contract_id : (props.match ? props.match.params.rent_contract_id : props.responsedetail.type === 1 ? 19 : 18);


    props.get_rent_contract_details(rent_contract_id)

    this.props.change_file_tab("f4")

    this.props.get_rent_m_reasons()
    this.props.get_rent_m_schools()
    this.props.get_rent_m_relations()
    this.props.get_rent_m_job_categorys()

    this.props.get_rent_m_agents()
    this.props.get_tm_kanri_corp_list()
    this.props.get_m_penalties()
    this.props.get_rent_m_contract_types()
    this.props.get_rent_m_traders()


    this.props.get_rent_m_money_templates()

    // this.props.get_rent_contract_families(rent_contract_id)

    // this.props.get_response_mails(props.responsedetail.id)
    this.numPages = this.numPages.bind(this);

    this.check_update_rent_contracts = this.check_update_rent_contracts.bind(this);


    this.check_update_cars = this.check_update_cars.bind(this);



    this.close_check_rent_contract_editor_disp = this.close_check_rent_contract_editor_disp.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleFileDispClose = this.handleFileDispClose.bind(this);
    this.change_refresh = this.change_refresh.bind(this)

    this.props.change_mail_template_category_default(73)
  }


  change_refresh() {
    this.setState({ refresh: !this.state.refresh })
  }
  close_check_rent_contract_editor_disp(value) {
    this.props.check_rent_contract_editor_disp(value)
    if (!this.state.rent_contract_id || this.state.rent_contract_id == 18 || this.state.rent_contract_id == 19) {
      this.props.change_response_tabs('r1')
    }
  }
  componentWillUnmount() {
    this.props.change_contracts_rent_application_doc_uri(null);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  check_update_rent_contracts() {

    this.props.update_rent_contracts()
  }

  check_update_cars() {

    this.props.update_cars()
  }

  handleZoom(type) {

    var index = zoomlist.findIndex(a => a.value == this.state.value)

    if (type == 1) {
      this.setState({ value: zoomlist[index - 1].value })
    } else if (type == 2) {
      this.setState({ value: zoomlist[index + 1].value })
    }

  }

  handleFileDispClose() {
    this.props.change_file_viewer_dialog_disp(false)
    this.setState({
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 125,
    })
  }


  render() {
    var work_unselected = []

    // let taikyo = this.props.rent_contract_editor_forms.rent_contract_monies ? "" : ""
    let works = this.props.rent_contract_editor_forms.rent_contract_monies ? this.props.rent_contract_editor_forms.rent_contract_monies.map(function (value) {
      if (value.rent_m_account_id == 11 && value.works == 0) {
        work_unselected.push("住宅総合保険料の取扱を選択してください。")
      } else if (value.rent_m_account_id == 14 && value.works == 0) {
        work_unselected.push("カギ交換料の取扱を選択してください。")
      } else if (value.rent_m_account_id == 15 && value.works == 0) {
        work_unselected.push("室内抗菌料の取扱を選択してください。")
      } else if (value.rent_m_account_id == 25 && value.works == 0) {
        work_unselected.push("室内消毒料の取扱を選択してください。")
      }
      return value
    }) : []

    return (
      <div>
        {this.props.customer_contacts_calendar.filter((x) => x.contents.way == 3).length > 0 || (this.state.rent_contract_id != 18 || this.state.rent_contract_id != 19 || this.state.rent_contract_id) ?

          <div>
            <Row className="item-outer-outer">


              <Col lg="6" className="item-outer-outer" style={{ paddingRight: 0 }}>

                <Card style={{ margin: 5 }}>
                  <CardContent>
                    <ContractVer />
                  </CardContent>
                </Card>
                {this.props.rent_contract_workflows ?
                  <Card style={{ margin: 5 }}>
                    <ContractFlow />

                  </Card> : ''
                }
                {/* <Card style={{ margin: 5 }}>
                  <ContractZenhoren />

                </Card> */}

                {/* <Card style={{ margin: 5 }}>
                  <CardContent>
                    <Attentions />
                  </CardContent>
                </Card> */}

                <Card style={{ margin: 5 }}>

                  <CardContent>

                    {/* 
                {(this.props.customerloding === 0) ? <Loading /> : this.props.customeredit === 0 ? <CustomerInfoDisp
                  // change_customerloding0={null}
                  // // customerdetailData={null}
                  // change_customerloding1={null}
                  // change_customerloding2={null}
                  // responsedetail={null}
                  // select={0}
                  // responseUpdate={null}
                  // edit={1}
                  // customerno={1}
                  // handleSelectCustomer={null}
                  customerdetail={this.props.customerdetail}
                // customereditchange={this.props.change_customeredit}
                // handleCustomerreset={this.handleCustomerreset}
                /> : <CustomerInfoEdit closed={1} />}
                */}
                    {/* {(this.props.customerloding === 0) ? <Loading /> : */}

                    {this.props.responsedetail.id ? < Customer rent_contract_id={this.state.rent_contract_id} rent_response_id={this.props.responsedetail.id} /> : ""}
                    {/* <CustomerEdit /> */}
                  </CardContent>

                </Card>

                <Card style={{ margin: 5 }}>
                  <CardContent>
                    {this.props.contract_detail_edit_disp === false && this.props.rent_contract_details_new ? <ContractInfoDisp /> : <EstimatesInfoEditComponent />}
                    <div style={{ marginTop: 10, marginBottom: 10 }} >
                      <ContractMoneyDisp />
                    </div>
                    <ContractEtcMoneyDisp />

                  </CardContent>

                  {this.props.rent_contract_details.counting === 1 || this.props.rent_contract_details.counting === 2 ?
                    <CardContent>
                      {this.props.rent_contract_etc_editor_disp ? <ContractEtcEdit /> : <ContractEtc />}

                    </CardContent>
                    : ""}

                  {(this.props.rent_contract_details.counting === 1 || this.props.rent_contract_details.counting === 2) && (this.props.rent_contract_details.division === 3 || this.props.rent_contract_details.division === 6) ?
                    <CardContent>
                      {this.props.rent_contract_ippan_editor_disp ? <ContractIppanEdit /> : <ContractIppan />}

                    </CardContent>
                    : ""}
                </Card>
                {(this.props.rent_contract_details.counting === 1 || this.props.rent_contract_details.counting === 2) ? <Card style={{ margin: 5 }}>
                  <CardContent>
                    <Car />
                  </CardContent>
                </Card> : ""}

                <Card style={{ margin: 5 }}>
                  <CardContent>
                    <Family rent_contract_id={this.state.rent_contract_id} />
                  </CardContent>
                </Card>

                <Card style={{ margin: 5 }}>
                  <CardContent>
                    <ContractAttention />
                  </CardContent>
                </Card>


                {/* {(this.props.rent_contract_details.counting === 1 || this.props.rent_contract_details.counting === 2) ?  */}
                <Card style={{ margin: 5 }}>
                  <CardContent>
                    {this.props.rent_contract_special_editor_disp ? <ContractSpecialEdit /> : <ContractSpecialDisp />}


                  </CardContent>
                </Card>
                {/* : ""} */}
              </Col>
              <Col lg="6" className="item-outer-outer" style={{ paddingLeft: 0 }}>


                {this.props.rent_contract_details_new.contractor_name1 ||
                  this.props.rent_contract_details_new.cosigner1_name1 ||
                  this.props.rent_contract_details_new.cosigner2_name1 ||
                  this.props.rent_contract_details_new.renter1_name1 ||
                  this.props.rent_contract_details_new.renter2_name1 ||
                  this.props.rent_contract_details_new.renter3_name1 ||
                  this.props.rent_contract_details_new.renter4_name1 ||
                  this.props.rent_contract_details_new.renter5_name1 ||
                  this.props.rent_contract_details_new.renter6_name1 ||
                  this.props.rent_contract_details_new.car_name1 ||
                  this.props.rent_contract_details_new.car_name2 ||
                  this.props.rent_contract_details_new.car_name3 ||
                  this.props.rent_contract_details_new.dog ||
                  this.props.rent_contract_details_new.cat ||
                  this.props.rent_contract_details_new.contractor_remarks ? <Card style={{ margin: 5 }}>

                  <CardContent>


                    < ContractCosignerDisp rent_contract_id={this.state.rent_contract_id} rent_response_id={this.props.responsedetail.id} />
                    {/* <CustomerEdit /> */}
                  </CardContent>

                </Card> : Object.keys(this.props.rent_contract_details).includes('contract_code') && this.props.rent_contract_details.contract_code ?
                <Card style={{ margin: 5 }}>
                    <CardContent>
                        <ContractApplication/>
                    </CardContent>
                </Card>
                :""}

                <Dialog open={this.props.file_viewer_dialog_disp} maxWidth="xl" fullWidth={true} onClose={(e) => { this.handleFileDispClose() }}>
                  <DialogTitle style={{ backgroundColor: this.props.contracts_doc_extension.indexOf("pdf") != -1 ? "#525659" : "" }}
                  >
                    <IconButton
                      aria-label="close"
                      style={{ position: 'absolute', right: 10, top: 1, }}
                      onClick={() => this.handleFileDispClose()}
                      size="large">
                      <CloseIcon style={{ color: this.props.contracts_doc_extension.indexOf("pdf") != -1 ? "white" : "" }} />
                    </IconButton>
                  </DialogTitle>

                  <div style={{ backgroundColor: this.props.contracts_doc_extension.indexOf("pdf") != -1 ? "#525659" : "" }}>
                    {/* <PdfReadComponent size={this.state.value}/>  */}
                    <div style={{ overflow: 'auto', height: window.innerHeight - 165, textAlign: "center" }}>
                      {this.props.contracts_rent_application_doc_uri ? <PdfDisplay scale={this.state.value} rotate={this.state.rotate} page={this.state.page} numPages={this.numPages} /> : ""}
                    </div>


                    {this.props.contracts_doc_extension.indexOf("pdf") != -1 ? <div style={{ textAlign: "center", padding: 10, backgroundColor: "white" }}>

                      {/* <InputRange
                  maxValue={20}
                  minValue={0}
                  draggableTrack={false}
                  value={this.state.value}
                  allowSameValues={false}
                  onChange={value => this.setState({ value })}

                /> */}
                      <IconButton
                        aria-label="left"
                        disabled={this.state.page <= 1}
                        onClick={() => this.handleButtonClick(this.state.page - 1)}
                        size="large">
                        <ChevronLeftIcon />
                      </IconButton>
                      {this.state.page || 1} / {this.state.numPages || '-'}
                      <IconButton
                        aria-label="left"
                        disabled={this.state.page >= this.state.numPages || !this.state.numPages}
                        onClick={() => this.handleButtonClick(this.state.page + 1)}
                        size="large">
                        <ChevronRightIcon />
                      </IconButton>

                      <IconButton
                        aria-label="left"
                        onClick={() => this.handleRotateClick(this.state.rotate - 90)}
                        size="large">
                        <RotateLeftIcon />
                      </IconButton>
                      <IconButton
                        aria-label="left"
                        onClick={() => this.handleRotateClick(this.state.rotate + 90)}
                        size="large">
                        <RotateRightIcon />
                      </IconButton>


                      <IconButton
                        disabled={!(this.state.value > 75)}
                        onClick={(e) => this.handleZoom(1)}
                        style={{ marginLeft: 15 }}
                        size="large">
                        <RemoveCircleIcon />
                      </IconButton>
                      <FormControl variant="outlined" style={{ marginTop: 5 }}>
                        <InputLabel id="demo-simple-select-outlined-label">ズーム</InputLabel>
                        <Select
                          style={{ width: 90, }}
                          value={this.state.value}
                          onChange={(e) => this.setState({ value: e.target.value })}
                          label="ズーム"
                          margin='dense'
                        >
                          {zoomlist.map((value, key) => <MenuItem key={key} value={value.value}>{value.label}</MenuItem>)}

                        </Select>
                      </FormControl>
                      <IconButton
                        disabled={!(this.state.value < 400)}
                        onClick={(e) => this.handleZoom(2)}
                        size="large">
                        <AddCircleIcon />
                      </IconButton>
                    </div> : ""}
                  </div>
                </Dialog>


                <Card style={{ margin: 5 }}>
                  <CardContent>
                    <Col lg="12" className="item-outer" style={{ padding: 0 }}>
                      {this.props.flie_loading ? <Loading /> : <FilesComponent />}
                    </Col>
                  </CardContent>
                </Card >









                <Card style={{ margin: 5 }}>
                  <CardContent>


                    {this.props.customer_contacts_loading ? <Loading /> : <ContactsComponent />}
                    {/* </div>  */}
                  </CardContent>
                </Card >



                <Card style={{ margin: 5 }}>
                  <CardContent>
                    {this.props.response_mail_loading ? <Loading /> : <MailResponseComponent />}
                  </CardContent>
                </Card >





                {this.props.line_open_flg ? "" : this.props.line_users.length > 0 ? this.props.line_users.map(function (value) {
                  return <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }} key={value.line_user_id}>
                    <LineDispByUser line_user={value} key={value.line_user_id} />

                    {/* {this.props.line_open_flg ? "" : this.props.lines.length > 0 ? <LineComponent rent_response_id={this.props.responseid} /> : ""} */}
                    {/* //timelineの長さをとるためlinesの長さが必要　this.props.linesがとれる前に描画されると一番下にスクロールできない */}
                  </Col>
                }, this) : <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}>
                  <Card className={this.props.classes.root}>
                    <CardContent><LineSign />  </CardContent>
                  </Card >  </Col>}


                <Card style={{ margin: 5 }}>
                  <CardContent>
                    <Col lg="12" className="item-outer" >
                      <ContractDocDl />
                    </Col>
                  </CardContent>
                </Card >

                {this.props.rent_contract_details.parking_application_id !== null ?
                  <Card style={{ margin: 5 }}>
                    <CardContent>
                      <Col lg="12" className="item-outer" >
                        <ContractReSend />
                      </Col>
                    </CardContent>
                  </Card>
                  : ''
                }
              </Col>

            </Row>



            < Modal isOpen={this.props.rent_contract_editor_disp} size="xxl" fade={false} >
              <Col>
                <ModalHeader toggle={() => this.close_check_rent_contract_editor_disp({ editor: "rent_contract_editor_disp", disp: false })}>契約情報作成</ModalHeader>


                {this.props.check_rent_customer_info ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "100%", height: 'calc(100% - 55px)', marginLeft: -15 }}>


                  {this.props.rent_contract_details.contract_date}


                  <Card style={{ margin: "auto", marginTop: 50, width: "90%" }}>
                    <Col>
                      {this.props.rent_m_corporate_editor ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "110%", height: "110%", marginLeft: -40, marginTop: -15 }}>

                        <Card style={{ margin: "auto", marginTop: 50, width: "400px", padding: "20px" }}>

                          <CardHeader

                            action={
                              <IconButton
                                aria-label="settings"
                                onClick={() => this.props.change_rent_m_corporate_editor(false)}
                                size="large">
                                <CancelIcon />
                              </IconButton>
                            }
                            title="企業登録"
                            subheader="必ず既存の企業名を検索して二重登録にならないようにしてください。"
                          />
                          <CardContent>
                            <Grid container spacing={0}>
                              <Grid xs={12} >
                                <TextField
                                  id="outlined-basic"
                                  //   className={classes.textField}
                                  label="企業名"
                                  margin="normal"
                                  variant="outlined"
                                  onChange={this.handleChange}
                                  name="new_corp_name"
                                  // value={this.props.rent_customer_editor_froms.name1}
                                  style={{ float: "left", width: "100%" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid xs={12} style={{ padding: 5 }}>
                                <TextField
                                  id="outlined-basic"
                                  //   className={classes.textField}
                                  label="企業名カナ"
                                  margin="normal"
                                  variant="filled"
                                  onChange={this.handleChange}
                                  name="new_corp_kana"
                                  // value={this.props.rent_customer_editor_froms.name1}
                                  style={{ float: "left", width: "100%" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions >
                            <div style={{ padding: 20, width: "100%", textAlign: "center" }}>
                              <Button variant="contained" color="primary" style={{ margin: 5 }} onClick={this.props.send_line_stock_rooms} disabled={this.props.line_send_list_loading}>登録する</Button>
                              <Button variant="contained" color="secondary" style={{ margin: 5 }} onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_editor_disp", disp: false })}>キャンセル</Button>
                            </div>
                          </CardActions>


                        </Card>

                      </div> : ""
                      }
                      <CardHeader

                        // action={
                        //   <IconButton aria-label="settings">
                        //     <CancelIcon />
                        //   </IconButton>
                        // }
                        title="必須情報登録"
                      // subheader="必須。"
                      />

                      <CardContent>

                        <ContractReqEdit />

                      </CardContent>
                      <CardActions >
                        <div style={{ padding: 20, width: "100%", textAlign: "center" }}>
                          <Button variant="contained" color="primary" style={{ margin: 5 }} onClick={this.props.send_line_stock_rooms} disabled={this.props.line_send_list_loading}>登録する</Button>
                        </div>
                      </CardActions>

                    </Col>
                  </Card>

                </div> : ""
                }


                <ModalBody>
                  <Row>
                    <Col lg="5">
                      <Card style={{ paddingRight: 0 }}>
                        <CardContent>
                          <Col lg="12">
                            {/* <EstimatesInfoEditComponent /> */}
                            <ContractEdit change_refresh={this.change_refresh} refresh={this.state.refresh} />

                          </Col>
                        </CardContent>
                      </Card >
                      <Card style={{ paddingRight: 0, marginTop: 10 }}>
                        <CardContent>
                          <Col lg="12">
                            <ContractEtcEdit />
                          </Col>
                        </CardContent>
                      </Card >
                    </Col>
                    <Col lg="7">
                      <Card >
                        <CardContent>
                          {/* <Col lg="12" className="item-outer" > */}
                          <ContractMoneyMonth change_refresh={this.change_refresh} refresh={this.state.refresh} />
                          <Backdrop className={this.props.classes.backdrop} open={this.props.contracts_backdrop}>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                          {/* </Col> */}
                        </CardContent>
                      </Card >

                    </Col>

                  </Row>
                </ModalBody>

                <ModalFooter>


                  {this.props.contract_check_message ? <Alert severity="error">

                    <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.contract_check_message.length ? this.props.contract_check_message.replace(/\r?\n/g, '<br/>') : '' }}></span>


                  </Alert> : ""}

                  {work_unselected.length ?
                    <Alert severity="error">
                      {work_unselected.map(function (value) {
                        return <div style={{ textAlign: "left" }} >{value}</div>
                      })}
                    </Alert>
                    : <Button variant="contained" onClick={this.check_update_rent_contracts} color="primary" style={{ float: "right", margin: 5 }}>
                      保存
                    </Button>
                  }
                  <Button variant="contained" onClick={() => this.close_check_rent_contract_editor_disp({ editor: "rent_contract_editor_disp", disp: false })} color="secondary" style={{ float: "right", margin: 5 }}>
                    閉じる
                  </Button>

                </ModalFooter>
              </Col>

            </Modal >





            < Modal isOpen={this.props.rent_contract_car_editor_disp} size="xxl" fade={false} >
              <Col>
                <ModalHeader toggle={() => this.props.change_rent_contract_car_editor_disp(false)}> 駐車場登録</ModalHeader>

                <ModalBody>


                  <Card style={{ margin: 5 }}>
                    <CardContent>
                      <Col lg="12">
                        {/* <EstimatesInfoEditComponent /> */}
                        <CarEdit />

                      </Col>
                    </CardContent>
                  </Card >


                </ModalBody>

                <ModalFooter>
                  <Button variant="contained" onClick={this.check_update_cars} color="primary" style={{ float: "right", margin: 5 }}>
                    保存
                  </Button>
                  <Button variant="contained" onClick={() => this.props.change_rent_contract_car_editor_disp(false)} color="secondary" style={{ float: "right", margin: 5 }}>
                    閉じる
                  </Button>

                </ModalFooter>
              </Col>

            </Modal >


            <Modal isOpen={this.props.rent_emergency_editor_disp} size="xxl" fade={false} >
              <Col>
                <ModalHeader toggle={() => this.props.check_rent_emergency_editor_disp({ editor: "rent_emergency_editor_disp", disp: false })}>緊急連絡先登録</ModalHeader>
                <ModalBody>
                  <Row>
                    <Col lg="12">
                      {/* <Card style={{ paddingRight: 0 }}>
                    <CardContent>
                      <Col lg="12"> */}
                      <EmergencyContactEdit />
                      {/* </Col>
                    </CardContent>
                  </Card > */}
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button variant="contained" onClick={this.check_update_rent_contracts} color="primary" style={{ float: "right", margin: 5 }}>
                    保存
                  </Button>
                  <Button variant="contained" onClick={() => this.props.change_rent_emergency_editor_disp(false)} color="secondary" style={{ float: "right", margin: 5 }}>
                    閉じる
                  </Button>
                </ModalFooter>
              </Col>
            </Modal >
            <ContractSheetModal/>

            <Backdrop className={this.props.classes.backdrop} open={this.props.contracts_backdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div >

          : <ContractCreate />}

      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {
    customer_contacts_calendar: state.customerContactsReducer.customer_contacts_calendar,
    estimate_loading: state.estimates.estimate_loading,
    customerdetail: state.customersReducer.customerdetail,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    contract_detail_edit_disp: state.estimates.contract_detail_edit_disp,
    estimate_edit_disp: state.estimates.estimate_edit_disp,
    contracts_rent_application_doc_uri: state.contracts.contracts_rent_application_doc_uri,
    responseid: state.responsesReducer.responseid,
    responsedetail: state.responsesReducer.responsedetail,
    line_user_details: state.line.line_user_details,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_editor_disp: state.contracts.rent_contract_editor_disp,
    rent_emergency_editor_disp: state.contracts.rent_emergency_editor_disp,
    rent_m_corporate_editor: state.contracts.rent_m_corporate_editor,
    rent_contract_workflows: state.contracts.rent_contract_workflows,

    rent_m_reasons: state.contracts.rent_m_reasons,
    rent_m_schools: state.contracts.rent_m_schools,
    check_rent_customer_info: state.contracts.check_rent_customer_info,
    tm_kanri_corp_list: state.contracts.tm_kanri_corp_list,

    m_penalties: state.contracts.m_penalties,
    rent_m_contract_types: state.contracts.rent_m_contract_types,
    rent_contract_etc_editor_disp: state.contracts.rent_contract_etc_editor_disp,
    rent_contract_special_editor_disp: state.contracts.rent_contract_special_editor_disp,
    rent_contract_ippan_editor_disp: state.contracts.rent_contract_ippan_editor_disp,
    rent_contract_car_editor_disp: state.contracts.rent_contract_car_editor_disp,
    response_mail_loading: state.mailReducer.response_mail_loading,
    customer_contacts_loading: state.customerContactsReducer.response_mail_loading,
    line_loading: state.line.line_loading,
    flie_loading: state.files.flie_loading,
    customerloding: state.customersReducer.customerloding,
    customeredit: state.customersReducer.customeredit,
    contracts_backdrop: state.contracts.contracts_backdrop,
    line_open_flg: state.line.line_open_flg,
    lines: state.line.lines,
    contracts_doc_extension: state.contracts.contracts_doc_extension,
    line_users: state.line.line_users,
    response_Tabs: state.responsesReducer.response_tabs,
    file_viewer_dialog_disp: state.contracts.file_viewer_dialog_disp,
    contract_check_message: state.contracts.contract_check_message,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    send_complete: state.contracts.send_complete,
    user_details: state.masterReducer.user_details,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_estimates(state) {
      dispatch(Estimates.get_estimates(state))
    },
    change_contracts_rent_application_doc_uri(state) {
      dispatch(Contracts.change_contracts_rent_application_doc_uri(state))
    },

    get_rent_contract_details(state) {
      dispatch(Contracts.get_rent_contract_details(state))
    },
    get_response_mails(state) {
      dispatch(Mail.get_response_mails(state))
    },
    change_rent_contract_editor_disp(state) {
      dispatch(Contracts.change_rent_contract_editor_disp(state))
    },
    change_rent_emergency_editor_disp(state) {
      dispatch(Contracts.change_rent_emergency_editor_disp(state))
    },
    change_rent_m_corporate_editor(state) {
      dispatch(Contracts.change_rent_m_corporate_editor(state))
    },
    get_rent_m_schools(state) {
      dispatch(Contracts.get_rent_m_schools(state))
    },
    get_rent_m_reasons(state) {
      dispatch(Contracts.get_rent_m_reasons(state))
    },
    get_rent_m_agents(state) {
      dispatch(Contracts.get_rent_m_agents(state))
    },
    get_rent_m_relations(state) {
      dispatch(Contracts.get_rent_m_relations(state))
    },
    get_rent_m_job_categorys(state) {
      dispatch(Contracts.get_rent_m_job_categorys(state))
    },
    get_tm_kanri_corp_list(state) {
      dispatch(Contracts.get_tm_kanri_corp_list(state))
    },
    get_m_penalties(state) {
      dispatch(Contracts.get_m_penalties(state))
    },
    get_rent_m_contract_types(state) {
      dispatch(Contracts.get_rent_m_contract_types(state))
    },
    get_rent_m_traders(state) {
      dispatch(Contracts.get_rent_m_traders(state))
    },
    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    check_rent_emergency_editor_disp(state) {
      dispatch(Contracts.check_rent_emergency_editor_disp(state))
    },
    update_rent_contracts(state) {
      dispatch(Contracts.update_rent_contracts(state))
    },
    change_rent_contract_car_editor_disp(state) {
      dispatch(Contracts.change_rent_contract_car_editor_disp(state))
    },
    get_rent_m_money_templates(state) {
      dispatch(Contracts.get_rent_m_money_templates(state))
    },
    get_rent_contract_families(state) {
      dispatch(Contracts.get_rent_contract_families(state))
    },
    update_cars(state) {
      dispatch(Contracts.update_cars(state))
    },
    get_rent_contract_workflows(state) {
      dispatch(Contracts.get_rent_contract_workflows(state))
    },
    change_response_tabs(state) {
      dispatch(Responses.change_response_tabs(state))
    },
    change_file_viewer_dialog_disp(state) {
      dispatch(Contracts.change_file_viewer_dialog_disp(state))
    },
    change_send_complete(state) {
      dispatch(Contracts.change_send_complete(state))
    },
    change_file_tab(state) {
      dispatch(Files.change_file_tab(state))
  },
  change_mail_template_category_default(state) {
    dispatch(MailTemplate.change_mail_template_category_default(state))
},
    // customerNewDispSet(state) {
    //   dispatch(Customers.customerNewDispSet(state))
    // },
    // change_customeredit(state) {
    //   dispatch(Customers.change_customeredit(state))
    // },



    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
  };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ContractsComponent);
