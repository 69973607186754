const authentication = require('../../../react-azure-adb2c2').default;


export const list = (values: any) => {
  // const url = `http://localhost:5000/v1/rent/m/corporates/listall`;
  const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/m/corporates/listall`;

    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(values))
    // alert(JSON.stringify(values))
    return fetch(url, {
      method: 'post',
      body: JSON.stringify({}),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(res => res.json())
      .then(payload => ({ payload }))
      .catch(error => ({ error }));
  
  };
