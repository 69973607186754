
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as tm_tatemono_api from '../Api/tm_tatemono_api';
import * as tm_room_api from '../Api/tm_room_api';

import * as rent_visits_api from '../Api/rent_visits_api';


import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as rent_customer_contacts_api from '../Api/rent_customer_contacts_api';
import * as RentResponsesEndApi from '../Api/RentResponsesEndApi';

import * as Responses from '../../ResponseDetail/Saga/Responses';

import * as AutoSend from '../../AutoSend/Saga/AutoSend';

import * as AutoSendsTemplatesApi from '../Api/AutoSendsTemplatesApi';

import * as RentCustomerContactsAutoSendsApi from '../Api/RentCustomerContactsAutoSendsApi';
import { AlertTitle } from '@mui/material';


import * as Calendar from '../../Calendar/Saga/Calendar';
import * as Task from '../../Task/Saga/Task';

export interface ContactsState {
    customer_contacts: any;
    customer_contacts_calendar: any;
    contacts_loading: number,
    customer_contacts_loading: boolean,
    auto_send_reserve_list: any;
    auto_send_modal: boolean;
    auto_sends_templates: any;
    visit_edit: boolean;
    customer_contacts_tm_tatemono_list: any;
    customer_contacts_tm_tatemono_list_loading: boolean;
    customer_contacts_tm_room_list: any;
    visit_editor_forms: any;
    rent_visits: any;
    rent_visits_edit: any;
    rent_customer_contacts: any;

    rent_customer_contacts_calendar_forms: any;
    rent_customer_contacts_forms_calendar_modal: boolean;
    rent_customer_contacts_forms: any;
    rent_customer_contacts_forms_modal: boolean;
    rent_customer_contacts_end_responses_form: any;
    customer_contacts_response_end: boolean;
    customer_contacts_modal: boolean;
    customer_contacts_timeline: boolean;

    customer_contacts_deleting: boolean;
    customer_contacts_saving: boolean;
    customer_contacts_visit_deleting: boolean;

    customer_contacts_calendar_list_edit: any;
    
    auto_mail_template_details: any;
    auto_mail_template_details_disp: boolean;

    auto_mail_template_select_disp: boolean;

}
var moment = require('moment-timezone');
const initialState: ContactsState = {

    customer_contacts: [],
    contacts_loading: 0,
    customer_contacts_loading: false,
    customer_contacts_calendar: [],
    auto_send_reserve_list: [],
    auto_send_modal: false,
    auto_sends_templates: [],
    visit_edit: false,
    customer_contacts_tm_tatemono_list: [],
    customer_contacts_tm_tatemono_list_loading: false,
    customer_contacts_tm_room_list: [],
    visit_editor_forms: {
        rent_customer_contact_id: 0,
        tatemono_id: 0,
        tatemono_name: '',
        room_id: 0,
        room_name: '',
        remarks: '',
        rent_response_id: 0,
        date: null,
        user_id: 0

    },
    rent_visits: [],
    rent_visits_edit: [],
    rent_customer_contacts: {},
    rent_customer_contacts_calendar_forms: {
        date: moment().format('YYYY/MM/DD'),
        rent_response_id: 0,
        section_id: 0,
        user_id: 0,
        remarks: "",
        guide_memo: "",
        intend_time: 0,
        intend_time2: 0,
        going_time: 0,
        going_user_id: 0,
        client_user_id: 0,
        coming_detail_type: 0,
        completed_at: '',
        is_application: 0,
        rent_m_going_reason_id: 0,
        going_section: -1,
        online: 0,
        no_coming: 0,
        is_prepare: 0,
        way: 0,
        agent_name: "",
        agent_etc: "",
    },
    rent_customer_contacts_forms_calendar_modal: false,
    rent_customer_contacts_forms: {
        date: moment().format('YYYY/MM/DD'),
        rent_response_id: 0,
        section_id: 0,
        user_id: 0,
        remarks: '',
        guide_memo: '',
        intend_time: 0,
        intend_time2: 0,
        going_time: 0,
        going_user_id: 0,
        client_user_id: 0,
        coming_detail_type: 0,
        completed_at: '',
        is_application: 0,
        rent_m_going_reason_id: 0,
        going_section: -1,
        online: 0,
        no_coming: 0,
        is_prepare: 0,
        way: 0,
        agent_name: "",
        agent_etc: "",
    },
    rent_customer_contacts_forms_modal: false,
    rent_customer_contacts_end_responses_form: {
        section_id: 0,
        rent_response_id: 0,
        end_response_user_id: 0,
        end_response_date: 0,
        end_response_reason: 0,
        end_response_remarks: 0,
    },
    customer_contacts_response_end: false,
    customer_contacts_modal: false,
    customer_contacts_timeline: false,
    customer_contacts_deleting: false,
    customer_contacts_saving: false,
    customer_contacts_visit_deleting: false,
    // rentAgents: [],
    // rentMReasons: [],

    customer_contacts_calendar_list_edit: [],

    auto_mail_template_details: {},
    auto_mail_template_details_disp: false,
    auto_mail_template_select_disp: false,
}



///action 
const actionCreator = actionCreatorFactory();

export const get_rent_customer_contacts = actionCreator<number>('GET_RENT_CUSTOMER_CONTACTS');
export const get_rent_customer_contacts_success = actionCreator<any>("GET_RENT_CUSTOMER_CONTACTS_SUCCESS");
export const get_rent_customer_contacts_failure = actionCreator("GET_RENT_CUSTOMER_CONTACTS_FAILURE");
export const get_rent_customer_contacts_calendar_success = actionCreator("GET_RENT_CUSTOMER_CONTACTS_CALENDAR_SUCCESS");
export const change_customer_contacts_loading = actionCreator<boolean>("CHANGE_CUSTOMER_CONTACTS_LOADING");


export const change_rent_customer_contacts = actionCreator<any>('CHANGE_RENT_CUSTOMER_CONTACTS');



export const add_rent_customer_contacts = actionCreator<any>('ADD_RENT_CUSTOMER_CONTACTS');
export const add_rent_customer_contacts_success = actionCreator("ADD_RENT_CUSTOMER_CONTACTS_SUCCESS");
export const add_rent_customer_contacts_failure = actionCreator("ADD_RENT_CUSTOMER_CONTACTS_FAILURE");

export const delete_rent_customer_contacts = actionCreator<any>('DELETE_RENT_CUSTOMER_CONTACTS');

export const update_rent_customer_contacts = actionCreator<any>('UPDATE_RENT_CUSTOMER_CONTACTS');




export const change_rent_responses_end = actionCreator('CHANGE_RENT_RESPONSES_END');
export const change_rent_responses_end_success = actionCreator("CHANGE_RENT_RESPONSES_END_SUCCESS");
export const change_rent_responses_end_failure = actionCreator("CHANGE_RENT_RESPONSES_END_FAILURE");


export const change_rent_responses_end_cancel = actionCreator('CHANGE_RENT_RESPONSES_END_CANCEL');



export const add_auto_send_modal = actionCreator<any>('ADD_AUTO_SEND_MODAL');

export const get_auto_send_reserve_list = actionCreator<any>('GET_AUTO_SEND_RESEREVE_LIST');
export const get_auto_send_reserve_list_success = actionCreator<any>("GET_AUTO_SEND_RESEREVE_LIST_SUCCESS");


export const get_auto_sends_templates = actionCreator<any>('GET_AUTO_SENDS_TEMPLATES');
export const get_auto_sends_templates_success = actionCreator<any>("GET_AUTO_SENDS_TEMPLATES_SUCCESS");

export const add_auto_send = actionCreator<any>('ADD_AUTO_SEND');

export const change_visit_edit = actionCreator<any>('CHANGE_VISIT_EDIT');
export const set_visit_edit = actionCreator('SET_VISIT_EDIT');
export const change_visit_editor_forms = actionCreator<any>('CHANGE_VISIT_EDIT_FORMS');

export const get_customer_contacts_tm_tatemono_list = actionCreator<any>('GET_CUSTOMER_CONTACTS_TM_TATEMONO_LIST');
export const change_customer_contacts_tm_tatemono_list = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_TM_TATEMONO_LIST');


export const change_customer_contacts_tm_tatemono_list_loading = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_TM_TATEMONO_LIST_LOADING');


export const get_customer_contacts_tm_room_list = actionCreator<any>('GET_CUSTOMER_CONTACTS_TM_ROOM_LIST');
export const change_customer_contacts_tm_room_list = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_TM_ROOM_LIST');


export const get_rent_visits = actionCreator<any>('GET_RENT_VISITS');
export const change_rent_visits = actionCreator<any>('CHANGE_RENT_VISITS');
export const update_rent_visits = actionCreator<any>('UPDATE_RENT_VISITS');
export const delete_rent_visits = actionCreator<any>('DELETE_RENT_VISITS');
export const update_rent_visits_sort = actionCreator<any>('UPDATE_RENT_VISITS_SORT');
export const update_rent_visits_stock = actionCreator<any>('UPDATE_RENT_VISITS_STOCK');

export const get_rent_visits_edit = actionCreator<any>('GET_RENT_VISITS_EDIT');
export const change_rent_visits_edit = actionCreator<any>('CHANGE_RENT_VISITS_EDIT');
export const update_rent_visits_edit = actionCreator<any>('UPDATE_RENT_VISITS_EDIT');
export const delete_rent_visits_edit = actionCreator<any>('DELETE_RENT_VISITS_EDIT');
export const update_rent_visits_edit_stock = actionCreator<any>('UPDATE_RENT_VISITS_EDIT_STOCK');
export const update_rent_visits_edit_sort = actionCreator<any>('UPDATE_RENT_VISITS_EDIT_SORT');



export const change_rent_customer_contacts_calendar_forms = actionCreator<any>('CHANGE_RENT_CUSTOMER_CONTACTS_CALENDAR_FORMS');
export const change_rent_customer_contacts_calendar_forms_modal = actionCreator<any>('CHANGE_RENT_CUSTOMER_CONTACTS_CALENDAR_FORMS_MODAL');

export const change_rent_customer_contacts_forms = actionCreator<any>('CHANGE_RENT_CUSTOMER_CONTACTS_FORMS');
export const change_rent_customer_contacts_forms_modal = actionCreator<any>('CHANGE_RENT_CUSTOMER_CONTACTS_FORMS_MODAL');


export const change_rent_customer_contacts_end_responses_form = actionCreator<any>('CHANGE_RENT_CUSTOMER_CONTACTS_END_RESPONSES_FORM');


export const change_customer_contacts_response_end = actionCreator<any>('CHANGE_RENT_CUSTOMER_CONTACTS_RESPONSE_END');

export const change_customer_contacts_modal = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_MODAL');


export const change_customer_contacts_timeline = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_TIMELINE');

export const change_customer_contacts_deleting = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_DELETING');
export const change_customer_contacts_saving = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_SAVING');
export const change_customer_contacts_visit_deleting = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_VISIT_DELETING');

export const change_customer_contacts_calendar_list_edit = actionCreator<any>('CHANGE_CUSTOMER_CONTACTS_CALENDAR_LIST_EDIT');

export const check_customer_contacts_raiten = actionCreator<any>('CHECK_CUSTOMER_CONTACTS_RAITEN');

export const insert_auto_sends_templates_all = actionCreator<any>('INSERT_AUTO_SENDS_TEMPLATES_ALL');

export const get_auto_mail_template_details = actionCreator<any>('GET_AUTO_MAIL_TEMPLATE_DETAILS');
export const change_auto_mail_template_details = actionCreator<any>('CHANGE_AUTO_MAIL_TEMPLATE_DETAILS');
export const change_auto_mail_template_details_disp = actionCreator<any>('CHANGE_AUTO_MAIL_TEMPLATE_DETAILS_DISP');
export const change_auto_mail_template_select_disp = actionCreator<any>('CHANGE_AUTO_MAIL_TEMPLATE_SELECT_DISP');

// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const customerContactsReducer = reducerWithInitialState(initialState)


    .case(get_rent_customer_contacts_calendar_success, (state, payload) => ({ ...state, customer_contacts_calendar: payload }))
    .case(get_rent_customer_contacts_success, (state, payload) => ({ ...state, customer_contacts: payload }))


    .case(change_rent_customer_contacts, (state, payload) => ({ ...state, rent_customer_contacts: payload }))

    .case(get_auto_send_reserve_list_success, (state, payload) => ({ ...state, auto_send_reserve_list: payload }))
    .case(add_auto_send_modal, (state, payload) => ({ ...state, auto_send_modal: payload }))
    .case(get_auto_sends_templates_success, (state, payload) => ({ ...state, auto_sends_templates: payload }))
    .case(change_visit_edit, (state, payload) => ({ ...state, visit_edit: payload }))

    .case(change_customer_contacts_tm_tatemono_list, (state, payload) => ({ ...state, customer_contacts_tm_tatemono_list: payload }))
    .case(change_customer_contacts_tm_tatemono_list_loading, (state, payload) => ({ ...state, customer_contacts_tm_tatemono_list_loading: payload }))

    .case(change_visit_editor_forms, (state, payload) => ({ ...state, visit_editor_forms: payload }))

    .case(change_customer_contacts_tm_room_list, (state, payload) => ({ ...state, customer_contacts_tm_room_list: payload }))

    .case(change_rent_visits, (state, payload) => ({ ...state, rent_visits: payload }))
    .case(change_rent_visits_edit, (state, payload) => ({ ...state, rent_visits_edit: payload }))
    .case(change_customer_contacts_loading, (state, payload) => ({ ...state, customer_contacts_loading: payload }))

    .case(change_rent_customer_contacts_calendar_forms, (state, payload) => ({ ...state, rent_customer_contacts_calendar_forms: payload }))
    .case(change_rent_customer_contacts_calendar_forms_modal, (state, payload) => ({ ...state, rent_customer_contacts_calendar_forms_modal: payload }))

    .case(change_rent_customer_contacts_forms, (state, payload) => ({ ...state, rent_customer_contacts_forms: payload }))
    .case(change_rent_customer_contacts_forms_modal, (state, payload) => ({ ...state, rent_customer_contacts_forms_modal: payload }))

    .case(change_rent_customer_contacts_end_responses_form, (state, payload) => ({ ...state, rent_customer_contacts_end_responses_form: payload }))

    .case(change_customer_contacts_response_end, (state, payload) => ({ ...state, customer_contacts_response_end: payload }))

    .case(change_customer_contacts_modal, (state, payload) => ({ ...state, customer_contacts_modal: payload }))

    .case(change_customer_contacts_timeline, (state, payload) => ({ ...state, customer_contacts_timeline: payload }))

    .case(change_customer_contacts_deleting, (state, payload) => ({ ...state, customer_contacts_deleting: payload }))
    .case(change_customer_contacts_saving, (state, payload) => ({ ...state, customer_contacts_saving: payload }))
    .case(change_customer_contacts_visit_deleting, (state, payload) => ({ ...state, customer_contacts_visit_deleting: payload }))

    .case(change_customer_contacts_calendar_list_edit, (state, payload) => ({ ...state, customer_contacts_calendar_list_edit: payload }))

    .case(change_auto_mail_template_details, (state, payload) => ({ ...state, auto_mail_template_details: payload }))
    .case(change_auto_mail_template_details_disp, (state, payload) => ({ ...state, auto_mail_template_details_disp: payload }))
    .case(change_auto_mail_template_select_disp, (state, payload) => ({ ...state, auto_mail_template_select_disp: payload }))




//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(add_rent_customer_contacts, (state, payload) => ({ ...state, users: payload }));
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const customerContactsSaga = [takeEvery('GET_RENT_CUSTOMER_CONTACTS', handleRequestContacts),
takeEvery('ADD_RENT_CUSTOMER_CONTACTS', handle_add_rent_customer_contacts_Request),
takeEvery('UPDATE_RENT_CUSTOMER_CONTACTS', handle_update_rent_customer_contacts_Request),
takeEvery('DELETE_RENT_CUSTOMER_CONTACTS', handle_delete_rent_customer_contacts_Request),
takeEvery('CHANGE_RENT_RESPONSES_END', handle_change_rent_responses_end),

takeEvery('CHANGE_RENT_RESPONSES_END_CANCEL', handle_change_rent_responses_end_cancel),


takeEvery('GET_AUTO_SEND_RESEREVE_LIST', handle_get_auto_send_reserve_list),
takeEvery('GET_AUTO_SENDS_TEMPLATES', handle_get_auto_sends_templates),
takeEvery('ADD_AUTO_SEND', handle_add_auto_send),

takeEvery('GET_CUSTOMER_CONTACTS_TM_TATEMONO_LIST', handle_get_customer_contacts_tm_tatemono_list),

takeEvery('GET_CUSTOMER_CONTACTS_TM_ROOM_LIST', handle_get_customer_contacts_tm_room_list),

takeEvery('GET_RENT_VISITS', handle_get_rent_visits),
takeEvery('UPDATE_RENT_VISITS', handle_update_rent_visits),
takeEvery('DELETE_RENT_VISITS', handle_delete_rent_visits),
takeEvery('UPDATE_RENT_VISITS_SORT', handle_update_rent_visits_sort),

takeEvery('GET_RENT_VISITS_EDIT', handle_get_rent_visits_edit),
takeEvery('UPDATE_RENT_VISITS_EDIT', handle_update_rent_visits_edit),
takeEvery('DELETE_RENT_VISITS_EDIT', handle_delete_rent_visits_edit),
takeEvery('UPDATE_RENT_VISITS_EDIT_SORT', handle_update_rent_visits_edit_sort),
takeEvery('UPDATE_RENT_VISITS_EDIT_STOCK', handle_update_rent_visits_edit_stock),

takeEvery('UPDATE_RENT_VISITS_STOCK', handle_update_rent_visits_stock),

takeEvery('SET_VISIT_EDIT', handle_set_visit_edit),

takeEvery('CHECK_CUSTOMER_CONTACTS_RAITEN', handle_check_customer_contacts_raiten),

takeEvery('INSERT_AUTO_SENDS_TEMPLATES_ALL', handle_insert_auto_sends_templates_all),

takeEvery('GET_AUTO_MAIL_TEMPLATE_DETAILS', handle_get_auto_mail_template_details),





    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_check_customer_contacts_raiten(action: any):any {

    const state = yield select();

    let customer_contacts = state.customerContactsReducer.customer_contacts
    let responsedetail = state.responsesReducer.responsedetail

    // console.log("aaaaaaaaaaaaaaa")
    // console.log(state.responsesReducer.responsedetail)

    if(responsedetail && customer_contacts && responsedetail.rent_m_way_id === 5 && customer_contacts.filter((a:any)=>a.way === 3).length == 0){

            let rent_customer_contacts_calendar_forms = {
                id: 0,
                user_id: responsedetail.coming_user_id,
                rent_response_id: responsedetail.id,
                section_id: responsedetail.section_id,
                way: "3",
                returning: 0,
                going: null,
                remarks: "",
                guide_memo: "",
                date: responsedetail.date,
                intend: 0,
                intend_time: 0,
                intend_time2: 0,
                going_time: 0,
                going_user_id: 0,
                client_user_id: responsedetail.coming_user_id,
                coming_detail_type: 0,
                completed_at: moment().format("YYYY/MM/DD"),
                is_application: 0,
                rent_m_going_reason_id: 0,
                going_section: -1,
                online: 0,
                no_coming: 0,
                is_prepare: 0,
                agent_name: "",
                agent_etc: "",
                start : null,
                end : null,
            }
            yield put(change_rent_customer_contacts_calendar_forms(rent_customer_contacts_calendar_forms))
            yield put(change_customer_contacts_modal(true));
    }
    
}

function* handleRequestContacts(action: any):any {

    const state = yield select();

    yield put(change_customer_contacts_loading(true));
    const { payload, error } = yield call(rent_customer_contacts_api.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)


        yield put(get_rent_customer_contacts_success(payload));

        const calendar = payload.map((item: any, i: number) => ({
            id: item.id,
            intend: item.intend,
            title: (item.way == 1 ? "電話" : item.way == 2 ? "メール" : "来店") + (item.intend === 1 ? "予定" : ""),
            allDay: true,
            start: item.date,
            end: item.date,
            contents: item
        }))
        yield put(get_rent_customer_contacts_calendar_success(calendar));
        yield put(change_customer_contacts_loading(false));

        yield put(check_customer_contacts_raiten(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_add_rent_customer_contacts_Request(action: any): any {

    // action.payload == 2 rent_customer_contacts_calendar_forms　カレンダー付のダイアログから
    // alert(action.payload)

    const state = yield select();

    let rent_customer_contacts_forms = action.payload == 2 ? state.customerContactsReducer.rent_customer_contacts_calendar_forms : state.customerContactsReducer.rent_customer_contacts_forms
    let rent_visits = action.payload == 2 ? state.customerContactsReducer.rent_visits_edit : state.customerContactsReducer.rent_visits
    let rent_response_id = state.customerContactsReducer.customer_contacts_modal && state.calendar.calendar_list_edit_dialog_disp ? 
    state.customerContactsReducer.rent_customer_contacts_calendar_forms.rent_response_id : 
    rent_customer_contacts_forms.rent_response_id

    // const { payload, error } = yield call(rent_customer_contacts_api.updatetime, { rent_customer_contacts: rent_customer_contacts_forms, rent_visits: rent_visits });
    // これより下のコメントのける。

    yield put(change_customer_contacts_saving(true));


    const { payload, error } = yield call(rent_customer_contacts_api.update, { rent_customer_contacts: rent_customer_contacts_forms, rent_visits: rent_visits, rent_response_id: rent_response_id});
    // 
    // alert()
    if (payload && !error) {

        yield put(get_rent_customer_contacts_success(payload));


        const calendar = payload.map((item: any, i: number) => ({
            id: item.id,
            intend: item.intend,
            title: (item.way == 1 ? "電話" : item.way == 2 ? "メール" : "来店") + (item.intend === 1 ? "予定" : ""),
            allDay: true,
            start: item.date,
            end: item.date,
            contents: item
        }))
        yield put(get_rent_customer_contacts_calendar_success(calendar));


        yield put(change_rent_customer_contacts_forms_modal(false));
        // yield put(change_rent_customer_contacts_calendar_forms_modal(false));


        let calendar_list_edit = state.calendar.calendar_list_edit
        let rent_customer_contacts_calendar_forms = state.customerContactsReducer.rent_customer_contacts_calendar_forms

        calendar_list_edit = calendar_list_edit.map(function (value: any) {

            if (value.type === 1 && value.id === rent_customer_contacts_forms.id) {

                if(rent_customer_contacts_forms.start == null){
                    value.start_edit = rent_customer_contacts_forms.start
                    value.end_edit = rent_customer_contacts_forms.end
          
                    value.start = new Date(moment(rent_customer_contacts_forms.date))
                    value.end = new Date(moment(rent_customer_contacts_forms.date))
                    
                    
                }else{
                    value.start = new Date(moment(rent_customer_contacts_forms.start))
                    value.end = new Date(moment(rent_customer_contacts_forms.end))
                    if(!(value.start < value.end)){
                      value.end = new Date(moment(value.start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
                    }
                    value.start_edit = value.start
                    value.end_edit = value.end

                    if (moment(value.start).tz("Asia/Tokyo").hour() < 9 || moment(value.start).tz("Asia/Tokyo").hour() > 18) {
                        value.start = new Date(moment(value.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
                        value.end = new Date(moment(value.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
                    }else if(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes') <= 30){
                        value.end = new Date(moment(value.end).tz("Asia/Tokyo").add(30-(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
                      }
                }
                // alert(value.start)
                


                value.date = moment(rent_customer_contacts_forms.date).format("YYYY/MM/DD")

                value.going = rent_customer_contacts_forms.going
                value.intend = rent_customer_contacts_forms.intend
                value.intend_time = rent_customer_contacts_forms.intend_time
                value.intend_time2 = rent_customer_contacts_forms.intend_time2
                value.going_time = rent_customer_contacts_forms.going_time
                value.going_user_id = rent_customer_contacts_forms.going_user_id
                value.client_user_id = rent_customer_contacts_forms.client_user_id
                value.coming_detail_type = rent_customer_contacts_forms.coming_detail_type
                value.completed_at = rent_customer_contacts_forms.completed_at
                value.is_application = rent_customer_contacts_forms.is_application
                value.rent_m_going_reason_id = rent_customer_contacts_forms.rent_m_going_reason_id
                value.going_section = rent_customer_contacts_forms.going_section
                value.online = rent_customer_contacts_forms.online
                value.no_coming = rent_customer_contacts_forms.no_coming
                value.is_prepare = rent_customer_contacts_forms.is_prepare
                // // value.mail_all_receive_id = payload.mail_all_receive_id
                // // value.mail_template_id = payload.mail_template_id
                value.remarks = rent_customer_contacts_forms.remarks
                value.guide_memo = rent_customer_contacts_forms.guide_memo
                value.rent_m_response_status_id = rent_customer_contacts_forms.rent_m_response_status_id
                // value.rent_response_id = payload.rent_response_id
                // value.returning = payload.returning

                // value.title = payload.title
                // value.type = 1
                value.user_id = rent_customer_contacts_forms.user_id
                // // value.user_nickname = payload.user_nickname
                value.way = rent_customer_contacts_forms.way
                value.before_passing = rent_customer_contacts_forms.before_passing

            }
            return value
        })

        yield put(Calendar.change_calendar_list_edit(calendar_list_edit));
        


        let calendar_list = state.calendar.calendar_list

        calendar_list = calendar_list.map(function (value: any) {

            if (value.type === 1 && value.id === rent_customer_contacts_forms.id) {

                if(rent_customer_contacts_forms.start == null){
                    value.start_edit = rent_customer_contacts_forms.start
                    value.end_edit = rent_customer_contacts_forms.end
          
                    value.start = new Date(moment(rent_customer_contacts_forms.date))
                    value.end = new Date(moment(rent_customer_contacts_forms.date))
                    
                    
                }else{
                    value.start = new Date(moment(rent_customer_contacts_forms.start))
                    value.end = new Date(moment(rent_customer_contacts_forms.end))
                    if(!(value.start < value.end)){
                      value.end = new Date(moment(value.start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
                    }
                    value.start_edit = value.start
                    value.end_edit = value.end

                    if (moment(value.start).tz("Asia/Tokyo").hour() < 9 || moment(value.start).tz("Asia/Tokyo").hour() > 18) {
                        value.start = new Date(moment(value.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
                        value.end = new Date(moment(value.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
                    }else if(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes') <= 30){
                        value.end = new Date(moment(value.end).tz("Asia/Tokyo").add(30-(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
                      }
                }
                // alert(value.start)
                


                value.date = moment(rent_customer_contacts_forms.date).format("YYYY/MM/DD")

                value.going = rent_customer_contacts_forms.going
                value.intend = rent_customer_contacts_forms.intend
                value.intend_time = rent_customer_contacts_forms.intend_time
                value.intend_time2 = rent_customer_contacts_forms.intend_time2
                value.going_time = rent_customer_contacts_forms.going_time
                value.going_user_id = rent_customer_contacts_forms.going_user_id
                value.client_user_id = rent_customer_contacts_forms.client_user_id
                value.coming_detail_type = rent_customer_contacts_forms.coming_detail_type
                value.completed_at = rent_customer_contacts_forms.completed_at
                value.is_application = rent_customer_contacts_forms.is_application
                value.rent_m_going_reason_id = rent_customer_contacts_forms.rent_m_going_reason_id
                value.going_section = rent_customer_contacts_forms.going_section
                value.online = rent_customer_contacts_forms.online
                value.no_coming = rent_customer_contacts_forms.no_coming
                value.is_prepare = rent_customer_contacts_forms.is_prepare
                // // value.mail_all_receive_id = payload.mail_all_receive_id
                // // value.mail_template_id = payload.mail_template_id
                value.remarks = rent_customer_contacts_forms.remarks
                value.guide_memo = rent_customer_contacts_forms.guide_memo
                value.rent_m_response_status_id = rent_customer_contacts_forms.rent_m_response_status_id
                // value.rent_response_id = payload.rent_response_id
                // value.returning = payload.returning

                // value.title = payload.title
                // value.type = 1
                value.user_id = rent_customer_contacts_forms.user_id
                // // value.user_nickname = payload.user_nickname
                value.way = rent_customer_contacts_forms.way
                value.before_passing = rent_customer_contacts_forms.before_passing

            }
            return value
        })

        yield put(Calendar.change_calendar_list(calendar_list));


        if(state.customerContactsReducer.customer_contacts_modal && state.calendar.calendar_list_edit_dialog_disp){
            
        }else{
            yield put(change_customer_contacts_modal(false));//通常ダイアログ
        }
        yield put(Calendar.change_calendar_list_edit_dialog_disp(false))// 通常ダイアログ
            
            
        yield put(change_customer_contacts_saving(false));




        // ダシュボードのタスク一覧の更新
        let task_rent_responses_list = state.task.task_rent_responses_list

        task_rent_responses_list = task_rent_responses_list.map(function (value: any) {

            if (value.type === 1 && value.id === rent_customer_contacts_forms.id) {

                if(rent_customer_contacts_forms.start == null){
                    value.start = rent_customer_contacts_forms.date
                    value.end = rent_customer_contacts_forms.date
                }else{
//"2023-03-28T12:00:00.000Z"
                    value.start = moment.tz(moment(rent_customer_contacts_forms.start).format('YYYY/MM/DD HH:mm:ss'), 'Asia/Tokyo').format()
                    // value.start = new Date(moment(rent_customer_contacts_forms.start))
                    value.end = moment.tz(moment(rent_customer_contacts_forms.end).format('YYYY/MM/DD HH:mm:ss'), 'Asia/Tokyo').format()
                }


                value.date = moment.tz(moment(rent_customer_contacts_forms.date).format('YYYY/MM/DD HH:mm:ss'), 'Asia/Tokyo').format()

                value.going = rent_customer_contacts_forms.going
                value.intend = rent_customer_contacts_forms.intend
                value.intend_time = rent_customer_contacts_forms.intend_time
                value.intend_time2 = rent_customer_contacts_forms.intend_time2
                value.going_time = rent_customer_contacts_forms.going_time
                value.going_user_id = rent_customer_contacts_forms.going_user_id
                value.client_user_id = rent_customer_contacts_forms.client_user_id
                value.coming_detail_type = rent_customer_contacts_forms.coming_detail_type
                value.completed_at = rent_customer_contacts_forms.completed_at
                value.is_application = rent_customer_contacts_forms.is_application
                value.rent_m_going_reason_id = rent_customer_contacts_forms.rent_m_going_reason_id
                value.going_section = rent_customer_contacts_forms.going_section
                value.online = rent_customer_contacts_forms.online
                value.no_coming = rent_customer_contacts_forms.no_coming
                value.is_prepare = rent_customer_contacts_forms.is_prepare
                value.remarks = rent_customer_contacts_forms.remarks
                value.guide_memo = rent_customer_contacts_forms.guide_memo
                value.rent_m_response_status_id = rent_customer_contacts_forms.rent_m_response_status_id
                value.user_id = rent_customer_contacts_forms.user_id
                value.way = rent_customer_contacts_forms.way
                value.before_passing = rent_customer_contacts_forms.before_passing

            }
            return value
        })

        yield put(Task.change_task_rent_responses_list(task_rent_responses_list))
        yield put(Task.search_task_rent_responses_list(task_rent_responses_list))
        yield put(Task.change_rent_tasks_modal(false))// ダッシュボードのダイアログ



        // 成功時アクション呼び出し
        // console.log(payload)
        // yield put(add_rent_customer_contacts_success(payload));
        // get_rent_customer_contacts(1);
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_update_rent_customer_contacts_Request(action: any): any {
    const state = yield select();
    let rent_customer_contacts_forms = state.customerContactsReducer.rent_customer_contacts_forms
    console.log(rent_customer_contacts_forms)

    let rent_visits = state.customerContactsReducer.rent_visits
    const { payload, error } = yield call(rent_customer_contacts_api.update, { rent_customer_contacts: rent_customer_contacts_forms, rent_visits: rent_visits });


    if (payload && !error) {
        console.log(payload)
        yield put(get_rent_customer_contacts_success(payload));

        const calendar = payload.map((item: any, i: number) => ({
            id: item.id,
            intend: item.intend,
            title: (item.way == 1 ? "電話" : item.way == 2 ? "メール" : "来店") + (item.intend === 1 ? "予定" : ""),
            allDay: true,
            start: item.date,
            end: item.date,
            contents: item
        }))
        yield put(get_rent_customer_contacts_calendar_success(calendar));
        // 成功時アクション呼び出し
        yield put(change_rent_customer_contacts_forms_modal(false));



        let calendar_list = state.calendar.calendar_list

        calendar_list = calendar_list.map(function (value: any) {

            if (value.type === 1 && value.id === rent_customer_contacts_forms.id) {
                // alert(rent_customer_contacts_forms.start)
                value.date = rent_customer_contacts_forms.date
                value.end = rent_customer_contacts_forms.end
                value.going = rent_customer_contacts_forms.going
                value.intend = rent_customer_contacts_forms.intend
                value.intend_time = rent_customer_contacts_forms.intend_time
                value.intend_time2 = rent_customer_contacts_forms.intend_time2
                value.going_time = rent_customer_contacts_forms.going_time
                value.going_user_id = rent_customer_contacts_forms.going_user_id
                value.client_user_id = rent_customer_contacts_forms.client_user_id
                value.coming_detail_type = rent_customer_contacts_forms.coming_detail_type
                value.completed_at = rent_customer_contacts_forms.completed_at
                value.is_application = rent_customer_contacts_forms.is_application
                value.rent_m_going_reason_id = rent_customer_contacts_forms.rent_m_going_reason_id
                value.going_section = rent_customer_contacts_forms.going_section
                value.online = rent_customer_contacts_forms.online
                value.no_coming = rent_customer_contacts_forms.no_coming
                value.is_prepare = rent_customer_contacts_forms.is_prepare
                value.mail_all_receive_id = rent_customer_contacts_forms.mail_all_receive_id
                value.mail_template_id = rent_customer_contacts_forms.mail_template_id
                value.remarks = rent_customer_contacts_forms.remarks
                value.guide_memo = rent_customer_contacts_forms.guide_memo
                value.rent_m_response_status_id = rent_customer_contacts_forms.rent_m_response_status_id
                value.rent_response_id = rent_customer_contacts_forms.rent_response_id
                value.returning = rent_customer_contacts_forms.returning
                value.start = rent_customer_contacts_forms.start
                value.title = rent_customer_contacts_forms.title
                value.type = rent_customer_contacts_forms.type
                value.user_id = rent_customer_contacts_forms.user_id
                value.user_nickname = rent_customer_contacts_forms.user_nickname
                value.way = rent_customer_contacts_forms.way
                value.before_passing = rent_customer_contacts_forms.before_passing

            }
            return value
        })

        yield put(Calendar.change_calendar_list(calendar_list));
        yield put(change_customer_contacts_modal(false));


        // get_rent_customer_contacts(1);
    } else {
        //    // 失敗時アクション呼び出し

    }
}


function* handle_delete_rent_customer_contacts_Request(action: any): any {
// alert("削除処理")
    const state = yield select();
    // let rent_customer_contacts_forms = state.customerContactsReducer.rent_customer_contacts_forms

    // let rent_customer_contacts_forms = action.payload == 2 ? state.customerContactsReducer.rent_customer_contacts_calendar_forms : state.customerContactsReducer.rent_customer_contacts_forms
    

    yield put(change_customer_contacts_deleting(true));

    // alert()
    const { payload, error } = yield call(rent_customer_contacts_api.contact_delete, action.payload);


    if (payload && !error) {
        console.log(payload)

        // alert(JSON.stringify(payload))
        // let calendar_lists = state.calendar.calendar_list
        // // yield put(get_rent_customer_contacts_success(payload));
        // const calendar = calendar_lists.map((item: any, i: number) => ({
        //     id: item.id,
        //     intend: item.intend,
        //     title: (item.way == 1 ? "電話" : item.way == 2 ? "メール" : "来店") + (item.intend === 1 ? "予定" : ""),
        //     allDay: true,
        //     start: item.date,
        //     end: item.date,
        //     contents: item
        // }))
        // yield put(get_rent_customer_contacts_calendar_success(calendar));


        let customer_contacts = state.customerContactsReducer.customer_contacts

        customer_contacts = customer_contacts.filter((value: any) => {
            return !(value.id === action.payload);
        });



        let calendar_list = state.calendar.calendar_list

        calendar_list = calendar_list.filter((value: any) => {
            return !(value.type === 1 && value.id === action.payload);
        });


        let calendar_list_edit = state.calendar.calendar_list_edit
        let rent_customer_contacts_calendar_forms = state.customerContactsReducer.rent_customer_contacts_calendar_forms

        calendar_list_edit = calendar_list_edit.filter((value: any) => {
            return !(value.type === 1 && value.id === action.payload);
        });
        yield put(Calendar.change_calendar_list_edit(calendar_list_edit));


        
        // カレンダー付からダイアログ開いたとき
        if(state.customerContactsReducer.customer_contacts_modal && state.calendar.calendar_list_edit_dialog_disp){
        }else{
            yield put(change_customer_contacts_modal(false));//通常ダイアログ
        }
        yield put(Calendar.change_calendar_list_edit_dialog_disp(false))// 通常ダイアログ

        // change_customer_contacts_modal

        // yield put(get_rent_customer_contacts_success(payload));
        yield put(get_rent_customer_contacts_success(customer_contacts));
        yield put(Calendar.change_calendar_list(calendar_list));
        yield put(change_rent_customer_contacts_forms_modal(false));
        // yield put(change_customer_contacts_modal(false));
        yield put(change_customer_contacts_deleting(false));

    } else {
        //    // 失敗時アクション呼び出し

    }
}

function* handle_change_rent_responses_end(action: any): any {
    const state = yield select();
    let rent_customer_contacts_end_responses_form = state.customerContactsReducer.rent_customer_contacts_end_responses_form



    const { payload, error } = yield call(RentResponsesEndApi.update, rent_customer_contacts_end_responses_form);


    if (payload && !error) {

        let responsedetail = state.responsesReducer.responsedetail

        responsedetail.end_response_date = rent_customer_contacts_end_responses_form.end_response_date
        responsedetail.end_response_reason = rent_customer_contacts_end_responses_form.end_response_reason
        responsedetail.end_response_user_id = rent_customer_contacts_end_responses_form.end_response_user_id
        responsedetail.end_response_remarks = rent_customer_contacts_end_responses_form.end_response_remarks

        yield put(Responses.change_responsedetail(responsedetail));
        yield put(change_customer_contacts_response_end(true));
    } else {
        //    // 失敗時アクション呼び出し

    }
}



function* handle_change_rent_responses_end_cancel(action: any): any {
    const state = yield select();
    let rent_customer_contacts_end_responses_form = state.customerContactsReducer.rent_customer_contacts_end_responses_form
    rent_customer_contacts_end_responses_form.rent_response_id = state.responsesReducer.responsedetail.id
    rent_customer_contacts_end_responses_form.end_response_date = null
    const { payload, error } = yield call(RentResponsesEndApi.update, rent_customer_contacts_end_responses_form);


    if (payload && !error) {
        let responsedetail = state.responsesReducer.responsedetail

        responsedetail.end_response_date = null
        responsedetail.end_response_reason = 0
        responsedetail.end_response_user_id = 0
        responsedetail.end_response_remarks = ""
        // alert(rent_customer_contacts_end_responses_form.rent_response_id)
        // console.log(payload)
        // payload.rent_response_id = payload.id
        // yield put(change_rent_responses_end_success(payload));
        yield put(Responses.change_responsedetail(responsedetail));

        // alert(rent_customer_contacts_end_responses_form.rent_response_id)
        // console.log(payload)
        // payload.rent_response_id = payload.id
        yield put(change_customer_contacts_response_end(false));

        // yield put(Responses.responsedetailData(rent_customer_contacts_end_responses_form.rent_response_id));

    } else {
        //    // 失敗時アクション呼び出し

    }
}







function* handle_get_auto_send_reserve_list(action: any) {
    yield put(get_auto_send_reserve_list_success({}));
    // yield put(change_auto_sends_loading(true));
    const { payload, error } = yield call(RentCustomerContactsAutoSendsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(get_auto_send_reserve_list_success(payload));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_get_auto_sends_templates(action: any) {
    // yield put(get_auto_send_reserve_list_success({}));
    // yield put(change_auto_sends_loading(true));
    const { payload, error } = yield call(AutoSendsTemplatesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(get_auto_sends_templates_success(payload));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_auto_mail_template_details(action: any) {
    // yield put(get_auto_send_reserve_list_success({}));
    // yield put(change_auto_sends_loading(true));

    const { payload, error } = yield call(AutoSendsTemplatesApi.details, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_auto_mail_template_details_disp(true));
        yield put(change_auto_mail_template_details(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_add_auto_send(action: any) {
    yield put(get_auto_send_reserve_list_success([]));
    // yield put(get_auto_send_reserve_list_success({}));
    // yield put(change_auto_sends_loading(true));
    const { payload, error } = yield call(RentCustomerContactsAutoSendsApi.insert, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し      yield put(get_auto_send_reserve_list_success(payload));

        // yield put(get_auto_send_reserve_list_success(payload.filter((a: any) => a.mail_template_id > 0)));
        // yield put(get_rent_customer_contacts(action.payload.rent_response_id));
        yield put(get_rent_customer_contacts_success(payload));

        const calendar = payload.map((item: any, i: number) => ({
            id: item.id,
            intend: item.intend,
            title: (item.way == 1 ? "電話" : item.way == 2 ? "メール" : "来店") + (item.intend === 1 ? "予定" : ""),
            allDay: true,
            start: item.date,
            end: item.date,
            contents: item
        }))
        yield put(get_rent_customer_contacts_calendar_success(calendar));
        yield put(change_customer_contacts_loading(false));
        

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}





function* handle_get_customer_contacts_tm_tatemono_list(action: any): any {
    yield put(change_customer_contacts_tm_tatemono_list_loading(true));

    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(tm_tatemono_api.searchname, { tatemono_name: action.payload });


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {

        // console.log(payload)
        // alert(JSON.stringify(payload))
        // alert(JSON.stringify(payload))
        let list = payload.map(function (value: any) {
            value.tatemono_name = hankana2Zenkana(han2zen(value.tatemono_name))
            value.tatemono_kana = hankana2Zenkana(han2zen(kanaToHira(value.tatemono_kana))) + "/" + hankana2Zenkana(han2zen(hiraToKana(value.tatemono_kana)))
            return value
        })
        yield put(change_customer_contacts_tm_tatemono_list(list));

        yield put(change_customer_contacts_tm_tatemono_list_loading(false));



    } else {
        alert(error)
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


    function kanaToHira(str: string) {
        return String(str).replace(/[\u30a1-\u30f6]/g, function (match) {
            var chr = match.charCodeAt(0) - 0x60;
            return String.fromCharCode(chr);
        });
    }
    function hiraToKana(str: string) {
        return str.replace(/[\u3041-\u3096]/g, function (match) {
            var chr = match.charCodeAt(0) + 0x60;
            return String.fromCharCode(chr);
        });
    }

    function han2zen(str: string) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    }
    function hankana2Zenkana(str: string) {
        var kanaMap: any = {
            'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
            'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
            'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
            'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
            'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
            'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
            'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
            'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
            'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
            'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
            'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
            'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
            'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
            'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
            'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
            'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
            'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
            'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
            '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
        };

        var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
        if (str) {
            return str
                .replace(reg, function (match) {
                    return kanaMap[match];
                })
                .replace(/ﾞ/g, '゛')
                .replace(/ﾟ/g, '゜');
        } else {
            return str
        }
    };
}



function* handle_get_customer_contacts_tm_room_list(action: any) {

    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(tm_room_api.list, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)
        // alert(JSON.stringify(payload))

        yield put(change_customer_contacts_tm_room_list(payload));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}



function* handle_get_rent_visits(action: any) {
    // alert(action.payload)
    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(rent_visits_api.list, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // alert(action.payload)
        // console.log(payload)
        // alert(JSON.stringify(payload))

        yield put(change_rent_visits(payload));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}

function* handle_get_rent_visits_edit(action: any) {
    // alert(action.payload)
    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(rent_visits_api.list, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // alert(action.payload)
        // console.log(payload)
        // alert(JSON.stringify(payload))

        yield put(change_rent_visits_edit(payload));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}


function* handle_update_rent_visits(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let visit_editor_forms = state.customerContactsReducer.visit_editor_forms

    if(action.payload && action.payload.tatemono_id){
        visit_editor_forms = action.payload
    }

    if (visit_editor_forms.rent_customer_contact_id) {

        const { payload, error } = yield call(rent_visits_api.update, visit_editor_forms);


        // サーバーからのレスポンスデータによる分岐処理
        //if (ログイン成功か失敗かの条件) {
        // 成功時アクション呼び出し

        if (payload && !error) {
            // console.log(payload)
            // alert(JSON.stringify(payload))


            // alert(visit_editor_forms.rent_customer_contact_id)

            yield put(change_visit_edit(false));
            yield put(change_rent_visits({}));
            yield put(change_rent_visits(payload));

        } else {
            // 失敗時アクション呼び出し
            // yield put(loginFailure(payload));
        }
    } else {

        let rent_visits = state.customerContactsReducer.rent_visits
        console.log(rent_visits)
        // console.log(rent_visits.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id))
        // alert(rent_visits.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id).length)
        if (rent_visits.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id).length) {
            // alert("dddsss")
            // alert(visit_editor_forms.date)
            let rent_visits_set = rent_visits.map(function (value: any) {
                value.remarks = visit_editor_forms.remarks

                return value
            })

            yield put(change_rent_visits({}));
            yield put(change_rent_visits(rent_visits_set));


        } else {
            let customer_contacts = state.customerContactsReducer.customer_contacts

            // alert("ddd")
            yield put(change_rent_visits({}));
            let rent_visits_set: any = {}
            rent_visits_set.rent_customer_contact_id = customer_contacts.id
            rent_visits_set.tatemono_id = visit_editor_forms.tatemono_id
            rent_visits_set.tatemono_name = visit_editor_forms.tatemono_name
            rent_visits_set.room_id = visit_editor_forms.room_id
            rent_visits_set.room_name = visit_editor_forms.room_name
            rent_visits_set.remarks = visit_editor_forms.remarks
            rent_visits_set.user_id = visit_editor_forms.user_id
            rent_visits_set.date = visit_editor_forms.date
            rent_visits_set.rent_response_id = visit_editor_forms.rent_response_id
            yield put(change_rent_visits(rent_visits.concat(rent_visits_set)));
        }
        yield put(change_visit_edit(false));

    }

}

function* handle_update_rent_visits_edit(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let visit_editor_forms = state.customerContactsReducer.visit_editor_forms
    if(action.payload && action.payload.tatemono_id){
        visit_editor_forms = action.payload
    }

    if (visit_editor_forms.rent_customer_contact_id) {

        const { payload, error } = yield call(rent_visits_api.update, visit_editor_forms);


        // サーバーからのレスポンスデータによる分岐処理
        //if (ログイン成功か失敗かの条件) {
        // 成功時アクション呼び出し

        if (payload && !error) {
            // console.log(payload)
            // alert(JSON.stringify(payload))


            // alert(visit_editor_forms.rent_customer_contact_id)

            yield put(change_visit_edit(false));
            yield put(change_rent_visits_edit({}));
            yield put(change_rent_visits_edit(payload));

        } else {
            // 失敗時アクション呼び出し
            // yield put(loginFailure(payload));
        }
    } else {

        let rent_visits_edit = state.customerContactsReducer.rent_visits_edit
        console.log(rent_visits_edit)
        // console.log(rent_visits.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id))
        // alert(rent_visits.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id).length)
        // let len = rent_visits_edit.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id).length
        // if (rent_visits_edit.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id).length) {
        let no = rent_visits_edit.length > 0 ? Math.max.apply(Math, rent_visits_edit.map(function(o:any) { return o.no; })) : 0
        // alert(no)
        // alert(visit_editor_forms.no)
        if (rent_visits_edit.filter((a: any) => a.no === visit_editor_forms.no).length > 0) {
            let rent_visits_set = rent_visits_edit.map(function (value: any) {
                if(value.no == visit_editor_forms.no){
                    value.tatemono_id = visit_editor_forms.tatemono_id
                    value.room_id = visit_editor_forms.room_id
                    value.tatemono_name = visit_editor_forms.tatemono_name
                    value.room_name = visit_editor_forms.room_name
                    value.remarks = visit_editor_forms.remarks
                }

                return value
            })

            yield put(change_rent_visits_edit({}));
            yield put(change_rent_visits_edit(rent_visits_set));


        } else {
            let customer_contacts = state.customerContactsReducer.customer_contacts

            // alert("ddd")
            yield put(change_rent_visits_edit({}));
            let rent_visits_set: any = {}
            rent_visits_set.rent_customer_contact_id = customer_contacts.id
            rent_visits_set.tatemono_id = visit_editor_forms.tatemono_id
            rent_visits_set.tatemono_name = visit_editor_forms.tatemono_name
            rent_visits_set.room_id = visit_editor_forms.room_id
            rent_visits_set.room_name = visit_editor_forms.room_name
            rent_visits_set.remarks = visit_editor_forms.remarks
            rent_visits_set.user_id = visit_editor_forms.user_id
            rent_visits_set.date = visit_editor_forms.date
            rent_visits_set.rent_response_id = visit_editor_forms.rent_response_id
            rent_visits_set.no = no + 1
            yield put(change_rent_visits_edit(rent_visits_edit.concat(rent_visits_set)));
        }
        yield put(change_visit_edit(false));

    }

}

function* handle_update_rent_visits_sort(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let rent_visits = state.customerContactsReducer.rent_visits



        const { payload, error } = yield call(rent_visits_api.update_sort, action.payload);


        // サーバーからのレスポンスデータによる分岐処理
        //if (ログイン成功か失敗かの条件) {
        // 成功時アクション呼び出し

        if (payload && !error) {

            yield put(change_rent_visits(payload));

        } else {
            // 失敗時アクション呼び出し
            // yield put(loginFailure(payload));
        }

}

function* handle_update_rent_visits_edit_sort(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let rent_visits = state.customerContactsReducer.rent_visits_edit

        const { payload, error } = yield call(rent_visits_api.update_sort, action.payload);


        // サーバーからのレスポンスデータによる分岐処理
        //if (ログイン成功か失敗かの条件) {
        // 成功時アクション呼び出し

        if (payload && !error) {

            yield put(change_rent_visits_edit(payload));

        } else {
            // 失敗時アクション呼び出し
            // yield put(loginFailure(payload));
        }

}

function* handle_update_rent_visits_stock(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let stock_rooms = state.StockRooms.stock_rooms
    let rent_customer_contacts_forms = state.customerContactsReducer.rent_customer_contacts_forms


    const { payload, error } = yield call(rent_visits_api.update_stock, {rent_customer_contacts : rent_customer_contacts_forms, stock_rooms: stock_rooms });


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)
        // alert(JSON.stringify(payload))


        // alert(visit_editor_forms.rent_customer_contact_id)

        yield put(change_visit_edit(false));
        yield put(change_rent_visits({}));
        yield put(change_rent_visits(payload));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }
    
}

function* handle_update_rent_visits_edit_stock(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let stock_rooms = state.StockRooms.stock_rooms
    let rent_customer_contacts_forms = state.customerContactsReducer.rent_customer_contacts_calendar_forms


    const { payload, error } = yield call(rent_visits_api.update_stock, {rent_customer_contacts : rent_customer_contacts_forms, stock_rooms: stock_rooms });


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)
        // alert(JSON.stringify(payload))


        // alert(visit_editor_forms.rent_customer_contact_id)

        yield put(change_visit_edit(false));
        yield put(change_rent_visits_edit({}));
        yield put(change_rent_visits_edit(payload));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }
    

}




function* handle_set_visit_edit(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し
    // alert(action.payload.disp)

    if (action.payload.rent_visit_id) {

        const { payload, error } = yield call(tm_room_api.list, action.payload.tatemono_id);


        if (payload && !error) {

            let responsedetail = state.responsesReducer.responsedetail
            let visit_editor_forms = state.customerContactsReducer.visit_editor_forms
            yield put(change_visit_editor_forms({}));
            if (action.payload.disp) {
                visit_editor_forms.rent_customer_contact_id = action.payload.id
                visit_editor_forms.tatemono_id = action.payload.tatemono_id
                visit_editor_forms.tatemono_name = action.payload.tatemono_name
                visit_editor_forms.room_id = action.payload.room_id
                visit_editor_forms.room_name = action.payload.room_name
                visit_editor_forms.remarks = action.payload.remarks
                visit_editor_forms.user_id = action.payload.user_id
                visit_editor_forms.date = action.payload.date
                visit_editor_forms.rent_response_id = responsedetail.id
                visit_editor_forms.rent_visit_id = action.payload.rent_visit_id
                visit_editor_forms.review = action.payload.review
                yield put(change_customer_contacts_tm_room_list(payload));
                yield put(change_visit_editor_forms(visit_editor_forms));
            } else {
                visit_editor_forms.id = 0
                visit_editor_forms.tatemono_id = 0
                visit_editor_forms.room_id = 0
                visit_editor_forms.remarks = ''
                visit_editor_forms.rent_response_id = 0
                visit_editor_forms.date = null
                visit_editor_forms.user_id = 0
                visit_editor_forms.rent_response_id = responsedetail.id
                visit_editor_forms.rent_visit_id = 0
                visit_editor_forms.review = 0
                yield put(change_visit_editor_forms(visit_editor_forms));
            }
            yield put(change_visit_edit(action.payload.disp));


        } else {
            // 失敗時アクション呼び出し
            // yield put(loginFailure(payload));
        }


    } else {

        let responsedetail = state.responsesReducer.responsedetail
        let visit_editor_forms = state.customerContactsReducer.visit_editor_forms
        yield put(change_visit_editor_forms({}));
        if (action.payload.disp) {
            visit_editor_forms.rent_customer_contact_id = action.payload.id
            // visit_editor_forms.tatemono_id = 0
            // visit_editor_forms.tatemono_name = ''
            // visit_editor_forms.room_id = 0
            // visit_editor_forms.room_name = ''
            visit_editor_forms.tatemono_id = action.payload.tatemono_id
            visit_editor_forms.tatemono_name = action.payload.tatemono_name
            visit_editor_forms.room_id = action.payload.room_id
            visit_editor_forms.room_name = action.payload.room_name
            visit_editor_forms.remarks = action.payload.remarks
            visit_editor_forms.user_id = action.payload.user_id
            visit_editor_forms.date = action.payload.date
            visit_editor_forms.rent_response_id = responsedetail.id
            visit_editor_forms.rent_visit_id = 0
            visit_editor_forms.no = action.payload.no
            visit_editor_forms.review = action.payload.review
            if(parseInt(action.payload.tatemono_id) > 0){
                yield put(get_customer_contacts_tm_room_list(parseInt(action.payload.tatemono_id)))
            }
            yield put(change_visit_editor_forms(visit_editor_forms));
        } else {
            visit_editor_forms.id = 0
            visit_editor_forms.tatemono_id = 0
            visit_editor_forms.room_id = 0
            visit_editor_forms.remarks = ''
            visit_editor_forms.rent_response_id = 0
            visit_editor_forms.date = null
            visit_editor_forms.user_id = 0
            visit_editor_forms.rent_response_id = responsedetail.id
            visit_editor_forms.rent_visit_id = 0
            visit_editor_forms.review = 0
            yield put(change_visit_editor_forms(visit_editor_forms));
        }
        yield put(change_visit_edit(action.payload.disp));
    }
}





function* handle_delete_rent_visits(action: any): any {
    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let visit_editor_forms = state.customerContactsReducer.visit_editor_forms
    // alert()
    // if (visit_editor_forms.rent_customer_contact_id) {
    yield put(change_customer_contacts_visit_deleting(true));
    const { payload, error } = yield call(rent_visits_api.delete_visit, visit_editor_forms);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)
        // alert(JSON.stringify(payload))




        yield put(change_visit_edit(false));
        yield put(change_rent_visits({}));
        yield put(change_rent_visits(payload));
        yield put(change_customer_contacts_visit_deleting(false));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }
    // } else {

    //     let rent_visits = state.customerContactsReducer.rent_visits
    //     console.log(rent_visits)
    //     // console.log(rent_visits.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id))
    //     // alert(rent_visits.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id).length)
    //     if (rent_visits.filter((a: any) => a.tatemono_id === visit_editor_forms.tatemono_id && a.room_id === visit_editor_forms.room_id).length) {
    //         // alert("dddsss")
    //         let rent_visits_set = rent_visits.map(function (value: any) {
    //             value.remarks = visit_editor_forms.remarks

    //             return value
    //         })

    //         yield put(change_rent_visits({}));
    //         yield put(change_rent_visits(rent_visits_set));


    //     } else {
    //         let customer_contacts = state.customerContactsReducer.customer_contacts

    //         // alert("ddd")
    //         yield put(change_rent_visits({}));
    //         let rent_visits_set: any = {}
    //         rent_visits_set.rent_customer_contact_id = customer_contacts.id
    //         rent_visits_set.tatemono_id = visit_editor_forms.tatemono_id
    //         rent_visits_set.tatemono_name = visit_editor_forms.tatemono_name
    //         rent_visits_set.room_id = visit_editor_forms.room_id
    //         rent_visits_set.room_name = visit_editor_forms.room_name
    //         rent_visits_set.remarks = visit_editor_forms.remarks
    //         rent_visits_set.user_id = visit_editor_forms.user_id
    //         rent_visits_set.date = visit_editor_forms.date
    //         rent_visits_set.rent_response_id = visit_editor_forms.rent_response_id
    //         yield put(change_rent_visits(rent_visits.concat(rent_visits_set)));
    //     }
    //     yield put(change_visit_edit(false));

    // }

}

function* handle_delete_rent_visits_edit(action: any): any {
    const state = yield select();　　// store.getState()と同じ

    // 成功時アクション呼び出し

    let visit_editor_forms = state.customerContactsReducer.visit_editor_forms
    let rent_visits_edit = state.customerContactsReducer.rent_visits_edit

    if (visit_editor_forms.rent_visit_id) {
        yield put(change_customer_contacts_visit_deleting(true));
        const { payload, error } = yield call(rent_visits_api.delete_visit, visit_editor_forms);

        if (payload && !error) {

            yield put(change_visit_edit(false));
            yield put(change_rent_visits_edit({}));
            yield put(change_rent_visits_edit(payload));
            yield put(change_customer_contacts_visit_deleting(false));

        } else {
            // 失敗時アクション呼び出し
            // yield put(loginFailure(payload));
        }

    }else{

        let rent_visits_set = rent_visits_edit.filter((a:any) => a.no != visit_editor_forms.no)

        yield put(change_rent_visits_edit({}));
        yield put(change_rent_visits_edit(rent_visits_set));
    }

}

function* handle_insert_auto_sends_templates_all(action: any):any {

    const state = yield select();

    let responsedetail = state.responsesReducer.responsedetail

    yield put(change_customer_contacts_loading(true));
    const { payload, error } = yield call(rent_customer_contacts_api.auto_send_all, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)


        yield put(get_rent_customer_contacts_success(payload));

        const calendar = payload.map((item: any, i: number) => ({
            id: item.id,
            intend: item.intend,
            title: (item.way == 1 ? "電話" : item.way == 2 ? "メール" : "来店") + (item.intend === 1 ? "予定" : ""),
            allDay: true,
            start: item.date,
            end: item.date,
            contents: item
        }))
        yield put(get_rent_customer_contacts_calendar_success(calendar));
        yield put(change_customer_contacts_loading(false));

        yield put(check_customer_contacts_raiten(payload));

        yield put(add_auto_send_modal(false));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


// function* handle_insert_auto_sends_templates_all(action: any):any {

//     const state = yield select();

//     let responsedetail = state.responsesReducer.responsedetail

//     yield put(change_customer_contacts_loading(true));
//     const { payload, error } = yield call(rent_customer_contacts_api.auto_send_all, responsedetail.id);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         // 成功時アクション呼び出し
//         console.log(payload)


//         yield put(get_rent_customer_contacts_success(payload));

//         const calendar = payload.map((item: any, i: number) => ({
//             id: item.id,
//             intend: item.intend,
//             title: (item.way == 1 ? "電話" : item.way == 2 ? "メール" : "来店") + (item.intend === 1 ? "予定" : ""),
//             allDay: true,
//             start: item.date,
//             end: item.date,
//             contents: item
//         }))
//         yield put(get_rent_customer_contacts_calendar_success(calendar));
//         yield put(change_customer_contacts_loading(false));

//         yield put(check_customer_contacts_raiten(payload));

//     } else {
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }


// function* handleUsersRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(UsersApi.UsersLIst, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getUsersSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentAgentsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentAgentsApi.RentAgentsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentAgentsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentMReasonsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentMReasonsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }