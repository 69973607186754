const authentication = require('../../../react-azure-adb2c2').default;

export const list_master = (values: any) => {

  // let url = `http://localhost:5000/v1/tm/owner/list`;
  let url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/tm/owner/list`;
  // alert(url)

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));

};

export const list_owner_search = (values: any) => {

  // let url = `http://localhost:5000/v1/tm/owner/search_ownerlist`;
  let url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/tm/owner/search_ownerlist`;
  // alert(url)

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));

};

export const list_bukken_search = (values: any) => {

  // let url = `http://localhost:5000/v1/tm/owner/search_ownerlistbukken`;
  let url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/tm/owner/search_ownerlistbukken`;
  // alert(url)

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));

};