
const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/visits/` + values;
    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/visits/` + values;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const update = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/visits/update`;
    // const url = `http://localhost:5000/v1/rent/visits/update`;
    // alert(JSON.stringify(values))
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const update_stock = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/visits/updatestock`;
    // const url = `http://localhost:5000/v1/rent/visits/updatestock`;
    // alert(JSON.stringify(values))
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const delete_visit = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/visits/delete`;
    // const url = `http://localhost:5000/v1/rent/visits/delete`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const update_sort = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/visits/updatesort`;
    // const url = `http://localhost:5000/v1/rent/visits/updatesort`;
    // alert(JSON.stringify(values))
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}