import React from 'react';

//import { Container } from 'reactstrap';
import Header from './HeaderComponent';
import LeftSide from './LeftSideComponent';
import ResponseToastComponent from './ResponseToastComponent';


import * as Help from '../Help/Saga/Help';
// import firebase from 'firebase/compat/app';

// import { initializeApp } from 'firebase/app';

// import firebaseConfig from '../../firebase-config';

// import { getFirestore, collection, query, where, getDocs, onSnapshot, orderBy } from "firebase/firestore";

import HelpComponent from '../Help/Index';

import * as Mail from '../Mail/Saga/Mail';
import * as Responses from '../ResponseDetail/Saga/Responses';
import moment from 'moment'
import '../../Css/icomoon/style.css';
// import './Css/drawer.min.css';
import './Css/Site.css';
import '../../Css/fontawesome/css/all.min.css';
import { connect } from 'react-redux';
import MailEditorComponent from "../Mail/MailEditorComponent";

import LineComponent from "../Line/LineComponent";

import StockRooms from "../StockRooms/Index";
import LineTemplate from "../ResponseList/ResponseLineSendWindowComponent";

import LimitComponent from "./LimitComponent";
import { Modal } from 'reactstrap';
import Draggable from 'react-draggable'; // The default
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import styled from 'styled-components'

import lineSound from './Sound/line.mp3';
import MypageSound from './Sound/mypage.mp3';
import MailSound from './Sound/mail.mp3';

// import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
import { useLocation } from "react-router-dom"
// import { withRouter } from 'react-router';
import { Dialog, Typography, IconButton, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
// const firebaseApp = initializeApp(firebaseConfig);
// const db = getFirestore(firebaseApp);




// position: 'absolute',

// type Props = {
//   open: boolean;
// }

// const HelpDialog: React.FC<Props> = withStyles(styles)((props) => {
//   // const { children, classes, onClose, ...other }: any = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}

//     </MuiDialogTitle>
//   );
// });


// const styles = (theme: any) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//     // width: 1000,

//   },
//   closeButton: {

//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],

//   },
// });
const Root = styled('div')(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  // width: 1000,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

interface HelpDialogProps {
  children?: React.ReactNode; // `children` をオプションに変更
  open: boolean;
  onClose: () => void;
  [key: string]: any; // その他のpropsを受け取るため
}


const HelpDialog: React.FC<HelpDialogProps> = (props) => {
  const { children, open, onClose, ...other } = props;

  return (
    <Dialog maxWidth="xl" onClose={onClose} open={open}>
      <DialogTitle {...other}>
        <Root>
          <Typography variant="h6" style={{ height: 20 }}>{children}</Typography>
          {onClose ? (
            <CloseButton
              aria-label="close"
              onClick={onClose}
              size="large">
              <CloseIcon />
            </CloseButton>
          ) : null}
        </Root>
      </DialogTitle>

      <div style={{ overflow: 'scroll', height: '700px', width: '700px', marginBottom: 15 }}>
        <HelpComponent />
      </div>
    </Dialog>
  );
}

// const HelpDialog = withStyles(styles)((props: any) => {
//   const { children, classes, open, onClose, ...other } = props;
//   return (
//     <Dialog maxWidth="xl" onClose={onClose} open={open}>
//       <MuiDialogTitle disableTypography className={classes.root} {...other}>
//         <Typography variant="h6" style={{ height: 20 }}>{children}</Typography>
//         {onClose ? (
//           <IconButton
//             style={{ position: 'absolute' }}
//             aria-label="close"
//             className={classes.closeButton}
//             onClick={onClose}
//             size="large">
//             <CloseIcon />
//           </IconButton>
//         ) : null}

//       </MuiDialogTitle>

//       <div style={{ overflow: 'scroll', height: '700px', width: '700px', marginBottom: 15 }}>
//         <HelpComponent />
//       </div>

//     </Dialog >
//   );
// });

interface IProps {
  children: any,
  user_details: any,
  shops: any,
  mail_new: number,
  mail_minimum: number,
  limitmodal: boolean,
  editor_height: number,
  editor_width: number,
  change_mail_minimum: any,
  draggable: number,
  line_open_flg: boolean,
  change_responseid: any,
  change_modalResponseDetail: any,
  stock_rooms_open: any,
  // firebaselogin: any,
  line_user_details: any,
  fire_mail: any,
  fire_line: any,
  fire_mypage: any,
  // open: boolean,
  change_manual_disp: any,
  manual_disp: boolean,
  line_template_open: any,
  pathname: string,
}

interface State {
  activeToasts: number,
  // state types
}
// width: ${(props: Props) => props.editor_width}px;
// height: ${(props: Props) => props.editor_height}px;
//  const MailRap  = styled.div`
// height:500px;
// width: ${(props: IProps) => props.editor_width}px;
// position:fixed;
// bottom: 0;
// right: 30px;
// z-index:1000;
// background:#FFFFFF;
// filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
// `;

let notify;
// var location = useLocation()


const Child = () => {
  const location = useLocation();


  return (
    <div>{location.pathname}</div>
  )
}

class LayoutComponent extends React.Component<IProps, State>  {

  constructor(props: Readonly<IProps>) {
    super(props)
    this.state = {

      activeToasts: 0,

    }




  }

  openResponse(id: number) {
    this.props.change_responseid(id)
    this.props.change_modalResponseDetail(true)
  }


  componentDidUpdate(prevProps: any) {
    
    // toast("Wow so easy !")
    if (prevProps.fire_mail !== this.props.fire_mail) {
      if (this.props.fire_mail.MailAccountId !== 8 && this.props.fire_mail.MailAccountId !== 9) {//テナントマンスリー省く
        // var sound = new Audio('chakushin.mp3');
        // const audioCtx = new window.AudioContext()
        // const oscillator = audioCtx.createOscillator()
        // oscillator.type = 'sine'
        // oscillator.frequency.setValueAtTime(1200, audioCtx.currentTime)
        // oscillator.frequency.setValueAtTime(800, audioCtx.currentTime + 0.1)
        // oscillator.connect(audioCtx.destination)
        // oscillator.start(audioCtx.currentTime)
        // oscillator.stop(audioCtx.currentTime + 0.2)


        if (this.props.fire_mail.RentContractId > 0) {

        } else {
          let shopname = "";
          if (this.props.fire_mail.MailAccountId === 1) {
            shopname = "番町店";
          }
          else if (this.props.fire_mail.MailAccountId === 2) {
            shopname = "レインボーロード店";
          }
          else if (this.props.fire_mail.MailAccountId === 3) {
            shopname = "高松駅前店";
          }
          else if (this.props.fire_mail.MailAccountId === 4) {
            shopname = "高松東店";
          }
          else if (this.props.fire_mail.MailAccountId === 5) {
            shopname = "中央公園店";
          }
          else if (this.props.fire_mail.MailAccountId === 6) {
            shopname = "サンフラワー店";
          }
          else if (this.props.fire_mail.MailAccountId === 11) {
            shopname = "中央通り店";
          } else if (this.props.fire_mail.MailAccountId === 12) {
            shopname = "WEB課";
          }

         


          let info = <div>{this.props.fire_mail.Subject}</div>
          if (this.props.fire_mail.Status === 4) {
            info = <div><div style={{ color: "#000" }}>{this.props.fire_mail.Subject == null ? "" : this.props.fire_mail.Subject.slice(0, 20)}</div><div>既読になりました</div><div style={{ fontWeight: "bold", color: "#000" }}>{this.props.fire_mail.FromName}様</div><div style={{ color: "#000" }}>{moment(this.props.fire_mail.Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div style={{ color: "#000" }}>{shopname} </div></div>
          } else if (this.props.fire_mail.Status === 1) {
            info = <div><div style={{ color: "#000" }}>{this.props.fire_mail.Subject == null ? "" : this.props.fire_mail.Subject.slice(0, 20)}</div><div style={{ fontWeight: "bold", color: "red" }}>メールを受信しました。</div><div style={{ fontWeight: "bold", color: "#000" }} >{this.props.fire_mail.FromName} 様<ResponseToastComponent RentResponseId={this.props.fire_mail.RentResponseId} /></div><div style={{ color: "#000" }}>{moment(this.props.fire_mail.Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div style={{ color: "#000" }}>{shopname} </div></div>

          } else {
            info = <div><div style={{ color: "#000" }}>{this.props.fire_mail.Subject == null ? "" : this.props.fire_mail.Subject.slice(0, 20)}</div><div style={{ fontWeight: "bold", color: "red" }}>反響メールを受信しました。</div><div style={{ fontWeight: "bold", color: "#000" }}>{this.props.fire_mail.FromName} 様</div><div style={{ color: "#000" }}>{this.props.fire_mail.RequestCustomerTel ? "≪TELあり≫　"+this.props.fire_mail.RequestCustomerTel : "≪TELなし≫"}</div><div style={{ color: "#000" }}>{moment(this.props.fire_mail.Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div style={{ color: "#000" }}>{shopname} </div></div>

          }
     
          const audio: HTMLAudioElement = new Audio(MailSound);
          audio.play();
        //   const audio = new Audio(lineSound);
        //  audio.play();
          toast(info, {
            autoClose: false,
            draggable: true,
            //etc you get the idea

            // 通知が表示されたタイミングでカウントを＋１
            onOpen: () => this.setState({ activeToasts: this.state.activeToasts + 1 }),
            // 通知が閉じられたタイミングでカウントを－１
            onClose: () => this.setState({ activeToasts: this.state.activeToasts > 0 ? this.state.activeToasts - 1 : 0 }),  

          })

        }


      }
    }



    if (prevProps.fire_line !== this.props.fire_line) {
      if (this.props.fire_line.replytoken !== "globalcenter") {
        const infoline = <div><div style={{ color: "#000" }}>{this.props.fire_line.message_type === "text" ? this.props.fire_line.message_text.slice(0, 20) : ""}</div><div style={{ fontWeight: "bold", color: "green" }}>LINEを受信しました。</div><div style={{ fontWeight: "bold", color: "#000" }}>{this.props.fire_line.name} 様{this.props.fire_line.rent_response_id ? <ResponseToastComponent RentResponseId={this.props.fire_line.rent_response_id} /> : ""}</div></div>
        console.log("LINE受信")


        // const audioCtx = new window.AudioContext()
        // const nodes = [
        //   audioCtx.createOscillator(),
        //   audioCtx.createOscillator()
        // ]
        // const hz = 1700
        // nodes.map(node => {
        //   node.type = 'sine'
        //   node.frequency.setValueAtTime(hz, audioCtx.currentTime)
        //   node.connect(audioCtx.destination)
        // })
        // const length = 0.1
        // const rest = 0.025
        // nodes[0].start(audioCtx.currentTime)
        // nodes[0].stop(audioCtx.currentTime + length)
        // nodes[1].start(audioCtx.currentTime + length + rest)
        // nodes[1].stop(audioCtx.currentTime + length * 2 + rest)

        // useSound(lineSound)
        // const audio = new Audio(lineSound);
        // audio.play();
        const audio: HTMLAudioElement = new Audio(lineSound);
        audio.play();
        toast(infoline, {
          autoClose: false,
          draggable: true,
          //etc you get the idea

                    // 通知が表示されたタイミングでカウントを＋１
                    onOpen: () => this.setState({ activeToasts: this.state.activeToasts + 1 }),
                    // 通知が閉じられたタイミングでカウントを－１
                    onClose: () => this.setState({ activeToasts: this.state.activeToasts > 0 ? this.state.activeToasts - 1 : 0 }),

        })

      }

    }

    if (prevProps.fire_mypage !== this.props.fire_mypage) {
      console.log("マイページ受信"+this.props.fire_mypage.length)
      // if (this.props.fire_line.replytoken !== "globalcenter") {
        const infoline = <div><div style={{ color: "#000" }}>{ this.props.fire_mypage.remarks.slice(0, 20) }</div><div style={{ fontWeight: "bold", color: "blue" }}>マイページから受信しました。</div><div style={{ fontWeight: "bold", color: "#000" }}>{this.props.fire_mypage.name} 様{this.props.fire_mypage.rent_response_id ? <ResponseToastComponent RentResponseId={this.props.fire_mypage.rent_response_id} /> : ""}</div></div>
        console.log("マイページ受信")


        // const audioCtx = new window.AudioContext()
        // const nodes = [
        //   audioCtx.createOscillator(),
        //   audioCtx.createOscillator()
        // ]
        // const hz = 1700
        // nodes.map(node => {
        //   node.type = 'sine'
        //   node.frequency.setValueAtTime(hz, audioCtx.currentTime)
        //   node.connect(audioCtx.destination)
        // })
        // const length = 0.1
        // const rest = 0.025
        // nodes[0].start(audioCtx.currentTime)
        // nodes[0].stop(audioCtx.currentTime + length)
        // nodes[1].start(audioCtx.currentTime + length + rest)
        // nodes[1].stop(audioCtx.currentTime + length * 2 + rest)
      
        // useSound(MypageSound)
        const audio: HTMLAudioElement = new Audio(MypageSound);
        audio.play();
        toast(infoline, {
          autoClose: false,
          draggable: true,

          // 通知が表示されたタイミングでカウントを＋１
          onOpen: () => this.setState({ activeToasts: this.state.activeToasts + 1 }),
          // 通知が閉じられたタイミングでカウントを－１
          onClose: () => this.setState({ activeToasts: this.state.activeToasts > 0 ? this.state.activeToasts - 1 : 0 }),

          //etc you get the idea

        })

      // }

    }


  }

  // notify = () => toast("Wow so easy !");
  notify = () => toast("aaaaaaaa", {
    autoClose: false,
    draggable: true,

    // 通知が表示されたタイミングでカウントを＋１
    onOpen: () => this.setState({ activeToasts: this.state.activeToasts + 1 }),
    // 通知が閉じられたタイミングでカウントを－１
    onClose: () => this.setState({ activeToasts: this.state.activeToasts > 0 ? this.state.activeToasts - 1 : 0 }),

    //etc you get the idea

  })
  
 

  render() {
   
  // すべてのトーストを削除する関数
  const dismissAllToasts = () => {
    toast.dismiss();
    this.setState({ activeToasts: 0 })

  };

    return (

      <>
        {this.props.pathname.indexOf("/FileDisp") != -1 ?
          <div><div style={{ height: "100%", width: "100%", backgroundColor: "#525659", paddingTop: 20 }}>
            {this.props.children}
          </div></div>
          :

          <div className="app">
            {/* <Child /> */}
            {/* <p>pathname: {this.props.pathname}</p> */}
            <Header user_details={this.props.user_details} shops={this.props.shops} />
            <LeftSide />

            {this.props.user_details.id ? 
            <>
            <div id="container">
              {this.props.children}
            </div>
            {this.props.mail_new === 1 ? <Draggable enableUserSelectHack={false} disabled={this.props.draggable === 1 ? false : true}>
              <div id="mail" style={{ display: (this.props.mail_minimum === 0) ? "" : "none", width: this.props.editor_width, height: this.props.editor_height }}><MailEditorComponent /> </div></Draggable> : ""}

            <div id="mailminimum" style={{ display: (this.props.mail_new === 1 && this.props.mail_minimum === 1) ? "" : "none" }} onClick={() => this.props.change_mail_minimum(0)}> 新規メッセージ<span className="far fa-window-minimize float-right" /></div>




            {this.props.line_open_flg === true && this.props.line_user_details.line_user_id ? <div id="line"><LineComponent /> </div> : ""}

            {this.props.stock_rooms_open ? <div id="StockRooms"><StockRooms /> </div> : ""}

            {this.props.line_template_open ? <div id="LineTemplate"><LineTemplate /> </div> : ""}



            <Modal isOpen={this.props.limitmodal} size="lg" fade={false}>
              <LimitComponent />
            </Modal>

            {/* トーストが１件以上表示されていれば、上部に閉じるボタンを表示 */}
            {/* {this.state.activeToasts} */}
            {this.state.activeToasts > 0 && (
              <div
                style={{
                  position: "fixed",
                  top: "20px",
                  right: "10px",
                  zIndex: 9999,
                }}
              >
                <Button variant="contained" startIcon={<CloseIcon />} color="inherit" size="small" onClick={dismissAllToasts}>すべて閉じる</Button>
                {/* <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={() => this.handleUpdate(this.props.eigyou_detail.eigyou_no, 0)} startIcon={<SaveAltIcon/>}>登録</Button> */}
              </div>
            )}

            {/* <button onClick={this.notify}>Notify !</button> */}
            <ToastContainer style={{ marginTop: 40 }} />
            {/* {this.props.user_details.amSectionId && this.props.firebaselogin ? <Firestore user_details={this.props.user_details} openResponse={this.openResponse} /> : ""} */}



            <HelpDialog
              open={this.props.manual_disp}
              // open={true}
              props={this.props}
              onClose={() => this.props.change_manual_disp(false)}
            />

            </>:""}
          </div>}

      </>
    )
  }
};
//コンテナ
const mapStateToProps = (state: any) => {
  return {

    user_details: state.masterReducer.user_details,
    shops: state.masterReducer.shops,
    mail_new: state.mailReducer.mail_new,
    mail_minimum: state.mailReducer.mail_minimum,
    limitmodal: state.commonReducer.limitmodal,
    editor_width: state.mailReducer.editor_width,
    editor_height: state.mailReducer.editor_height,
    draggable: state.masterReducer.draggable,
    line_open_flg: state.line.line_open_flg,
    line_user_details: state.line.line_user_details,
    stock_rooms_open: state.StockRooms.stock_rooms_open,
    line_template_open: state.responselist.line_template_open,
    // firebaselogin: state.firebaseReducer.firebaselogin,
    fire_mail: state.firebasestoreReducer.fire_mail,
    fire_line: state.firebasestoreReducer.fire_line,
    fire_mypage: state.firebasestoreReducer.fire_mypage,
    manual_disp: state.help.manual_disp,
    pathname: state.router.location.pathname,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    change_mail_minimum(state: number) {
      dispatch(Mail.change_mail_minimum(state))
    },
    change_responseid(state: number) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state: boolean) {
      dispatch(Responses.change_modalResponseDetail(state))
    },
    change_manual_disp(state: boolean) {
      dispatch(Help.change_manual_disp(state))
    },


  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);



// interface FProps {

//   user_details: any,
//   openResponse: any,

// }
// class Firestore extends React.Component<FProps, State>  {
//   async componentDidMount() {
//     const citiesRef = collection(db, "response");

//     // const query6 =db.collection("response").where("Timestamp",">",Math.floor( new Date().getTime() / 1000 )).where("RentContractId","==",0).where("MailAccountId","==",6);

//     //   let MailAccountId = 0;
//     //   if (this.props.user_details.amSectionId === 1) {
//     //     MailAccountId = 1;
//     //   }
//     //   else if (this.props.user_details.amSectionId === 2) {
//     //     MailAccountId = 2;
//     //   }
//     //   else if (this.props.user_details.amSectionId === 3) {
//     //     MailAccountId = 3;
//     //   }
//     //   else if (this.props.user_details.amSectionId === 4) {
//     //     MailAccountId = 4;
//     //   }
//     //   else if (this.props.user_details.amSectionId === 6) {
//     //     MailAccountId = 5;
//     //   }
//     //   else if (this.props.user_details.amSectionId === 17) {
//     //     MailAccountId = 6;
//     //   }
//     //   else if (this.props.user_details.amSectionId === 47) {
//     //     MailAccountId = 11;
//     //   }
//     //   const db = firebase.firestore();

//     //   console.log(this.props.user_details.amSectionId)

//     // let query11
//     // // query11 = db.collection("response").where("Timestamp", ">", Math.floor(new Date().getTime() / 1000));
//     // query11 = query(citiesRef, where("Timestamp", ">", Math.floor(new Date().getTime() / 1000)));
//     // onSnapshot(query11, { includeMetadataChanges: true }, (snapshot: any) => {
//     //   snapshot.docChanges().forEach((change: any) => {
//     //     alert(change.type)
//     //     if (change.type === 'added') {

//     //     }
//     //   })
//     // })
//     //                     //     snapshot.docChanges().forEach((change: any) => {
//     //                     //         alert(change.type)
//     //                     //         if (change.type === 'added') {
//     //   if (this.props.user_details.amSectionId === 10 || this.props.user_details.amSectionId === 26) {
//     //     query11 = db.collection("response").where("Timestamp", ">", Math.floor(new Date().getTime() / 1000));
//     //     console.log(this.props.user_details.amSectionId)

//     //   } else {
//     //     query11 = db.collection("response").where("MailAccountId", "==", MailAccountId).where("Timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("Timestamp", "desc");

//     // }


//     //   // query11.onSnapshot(function(snapshot) {
//     //   //   snapshot.docChanges().forEach(function(change) {
//     //   // query11.onSnapshot(snapshot => {
//     //   //   snapshot.docChanges().forEach(function(change) {
//     //   query11.onSnapshot(function (snapshot) {
//     //     snapshot.docChanges().forEach(function (change) {
//     //       // snapshot.forEach(doc => {

//     //       console.log(change.doc.data())
//     //       if (change.type === 'added') {
//     //         if (change.doc.data().MailAccountId !== 8 && change.doc.data().MailAccountId !== 9) {//テナントマンスリー省く



//     //           if (change.doc.data().RentContractId > 0) {

//     //           } else {
//     //             let shopname = "";
//     //             if (change.doc.data().MailAccountId === 1) {
//     //               shopname = "番町店";
//     //             }
//     //             else if (change.doc.data().MailAccountId === 2) {
//     //               shopname = "レインボーロード店";
//     //             }
//     //             else if (change.doc.data().MailAccountId === 3) {
//     //               shopname = "高松駅前店";
//     //             }
//     //             else if (change.doc.data().MailAccountId === 4) {
//     //               shopname = "高松東店";
//     //             }
//     //             else if (change.doc.data().MailAccountId === 5) {
//     //               shopname = "中央公園店";
//     //             }
//     //             else if (change.doc.data().MailAccountId === 6) {
//     //               shopname = "サンフラワー店";
//     //             }
//     //             else if (change.doc.data().MailAccountId === 11) {
//     //               shopname = "中央通り店";
//     //             }



//     //             let info = <div>{change.doc.data().Subject}</div>
//     //             if (change.doc.data().Status === 4) {
//     //               info = <div><div style={{ color: "#000" }}>{change.doc.data().Subject == null ? "" : change.doc.data().Subject.slice(0, 20)}</div><div>既読になりました</div><div style={{ fontWeight: "bold", color: "#000" }}>{change.doc.data().FromName}様</div><div style={{ color: "#000" }}>{moment(change.doc.data().Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div style={{ color: "#000" }}>{shopname} </div></div>
//     //             } else if (change.doc.data().Status === 1) {
//     //               info = <div><div style={{ color: "#000" }}>{change.doc.data().Subject == null ? "" : change.doc.data().Subject.slice(0, 20)}</div><div style={{ fontWeight: "bold", color: "red" }}>メールを受信しました。</div><div style={{ fontWeight: "bold", color: "#000" }} >{change.doc.data().FromName} 様<ResponseToastComponent RentResponseId={change.doc.data().RentResponseId} /></div><div style={{ color: "#000" }}>{moment(change.doc.data().Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div style={{ color: "#000" }}>{shopname} </div></div>

//     //             } else {
//     //               info = <div><div style={{ color: "#000" }}>{change.doc.data().Subject == null ? "" : change.doc.data().Subject.slice(0, 20)}</div><div style={{ fontWeight: "bold", color: "red" }}>反響メールを受信しました。</div><div style={{ fontWeight: "bold", color: "#000" }}>{change.doc.data().FromName} 様</div><div style={{ color: "#000" }}>{moment(change.doc.data().Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div style={{ color: "#000" }}>{shopname} </div></div>

//     //             }
//     //             toast(info, {
//     //               autoClose: false,
//     //               draggable: true,
//     //               //etc you get the idea

//     //             })
//     //             notify = change.doc.data();
//     //           }
//     //         }
//     //       }
//     //     });
//     //   })









//     //   console.log(this.props.user_details.amSectionId)
//     //   db.collection("line").where("section_id", "==", this.props.user_details.amSectionId).where("timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("timestamp", "desc")
//     //     .onSnapshot((snapshot) => {
//     //       snapshot.docChanges().forEach(function (change) {
//     //         if (change.doc.data().replytoken !== "globalcenter") {
//     //           const infoline = <div><div style={{ color: "#000" }}>{change.doc.data().message_type === "text" ? change.doc.data().message_text.slice(0, 20) : ""}</div><div style={{ fontWeight: "bold", color: "green" }}>LINEを受信しました。</div><div style={{ fontWeight: "bold", color: "#000" }}>{change.doc.data().name} 様</div></div>
//     //           console.log("LINE受信")
//     //           toast(infoline, {
//     //             autoClose: false,
//     //             draggable: true,
//     //             //etc you get the idea

//     //           })
//     //           notify = change.doc.data();
//     //         }
//     //       }, this)

//     //     })

//   }





//   render() {
//     return (
//       <div></div >
//     );
//   }
// }




