import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as ResponseList from './Saga/ResponseList';
import * as Responses from '../ResponseDetail/Saga/Responses';
import * as Customers from './../Customers/Saga/Customers';

import './Css/Table.css';
import * as ResponseDetail from './../ResponseDetail/Saga/Responses';
import moment from 'moment';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
// import { ModalHeader,Modal,ModalFooter,Button,ModalBody} from 'reactstrap';
import MailDetail from "../Mail/MailDetailComponent";
import CustomerAttentionList from '../Customers/CustomerAttentionList';
import ResponseMatchingGridComponent from "./ResponseMatchingGridComponent";
import ResponseMatchingSelectGridComponent from "./ResponseMatchingSelectGridComponent";

import ResponseLineMatchingGridComponent from "./ResponseLineMatchingGridComponent";
import ResponseCardMatchingGridComponent from "./ResponseCardMatchingGridComponent";

import ResponseComponent from "../ResponseDetail/ResponseComponent";
import AutoSend from "../AutoSend/Index";
// import Modal from "react-modal";
import * as Line from '../Line/Saga/Line';
import { Button } from '@mui/material';


import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css'

import IconButton from '@mui/material/IconButton';
import WebIcon from '@mui/icons-material/Web';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';






class ResponseListTableComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
            modalselect: false,
            // columnWidths: {
            //     building_name: 150,
            //     contractor_name: 150,
            // },

            columnWidths: {
                column1: 330,
                column2: 320,
                column3: 200,
                column4: 180,
            },
            line_open: false,
            line_delete: false,
            line_del: {},
            card_delete: false,
            card_del: {},
            kidoku_status: "",
            kidoku_status_id: 0,
        };
        let formData = this.props.formData
        formData.section_id = this.props.user_details.amSectionId
        formData.condition = this.props.user_details.amSectionId == 26 ? "1,6" : ""// 反響対策課なら来店なし、自動追客にチェック
        // alert(JSON.stringify(this.props.user_details))
        this.props.change_formData(formData);
        // alert(JSON.stringify(formData))

        this.props.responseList()
        // this.onScroll = this.onScroll.bind(this);
        // this.getCellActions = this.getCellActions.bind(this);
        this.selectedrow = this.selectedrow.bind(this);
        this.addResponse = this.addResponse.bind(this);
        this.togglemailtaiou = this.togglemailtaiou.bind(this);
        this.mailtaioudelete = this.mailtaioudelete.bind(this);
        this.closeResponseDetail = this.closeResponseDetail.bind(this);

        this.open_response_detail = this.open_response_detail.bind(this);
        // this.toggle_line_delete = this.toggle_line_delete.bind(this);
        this.open_line = this.open_line.bind(this);

        this.toggle_line_delete = this.toggle_line_delete.bind(this);
        this.line_delete = this.line_delete.bind(this);
        this.toggle_card_delete = this.toggle_card_delete.bind(this);
        this.card_delete = this.card_delete.bind(this);
        this.line_bind = this.line_bind.bind(this);
        this.card_bind = this.card_bind.bind(this);
        this.line_add = this.line_add.bind(this);

        this.matching = this.matching.bind(this);

        this.open_customer_window = this.open_customer_window.bind(this);


        this.toggleAutoSend = this.toggleAutoSend.bind(this);

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        this.props.get_rent_m_medea();
        this.props.get_rent_m_reasons();
        this.props.get_rent_m_schools();
        this.props.get_rent_m_jobs();



    }

    selectedrow(responseId, mailAllReceiveId) {


        this.setState({
            responseId: responseId,
            mailAllReceiveId: mailAllReceiveId
        })
    }

    closeResponseDetail = () => {

        this.setState(prevState => ({
            modalResponseDetail: false,
        }));
    }

    addResponse() {

        let matching = {};
        matching.response_matching_detail = this.props.response_matching_list.filter(a => a.mail_all_receive_id === this.state.mailAllReceiveId)[0]

        matching.responseId = this.state.responseId

        if (this.state.responseId > 0) {
            // alert(this.state.responseId)
            console.log(this.props.response_matching_select_list)
            // matching.response_matching_select_list = this.props.response_matching_select_list.filter(a => a.selected === true)
            console.log(matching)

            // alert()
            matching.response_matching_select_list = this.props.response_matching_select_list.filter(a => a.selected === true).map(function (value) {

                return value.id;

            });

            console.log(this.props.history)



            this.props.customerNewDispSet(0)
            matching.history = this.props.history
            this.props.merge_response_mails(matching)
        } else {

            matching.response_matching_select_list = this.props.response_matching_select_list.filter(a => a.selected === true).map(function (value) {

                return value.id;

            });

            console.log(matching)

            this.props.change_line_user_id({ line_user_id: null, line_account_section_id: 0 })

            var fromrequestRoomMadoriNo = null;
            var torequestRoomMadoriNo = null;
            var madoriNo = matching.response_matching_detail.request_room_madori_no;



            if (madoriNo === 1
                || madoriNo === 2
                || madoriNo === 3
                || madoriNo === 4
                || madoriNo === 57
                || madoriNo === 54
                || madoriNo === 51
            ) {
                fromrequestRoomMadoriNo = 2;
                torequestRoomMadoriNo = 3;
            } else if (madoriNo === 5
                || madoriNo === 6
                || madoriNo === 7
                || madoriNo === 8
                || madoriNo === 9
                || madoriNo === 10
                || madoriNo === 50
                || madoriNo === 59
                || madoriNo === 60
            ) {
                fromrequestRoomMadoriNo = 5;
                torequestRoomMadoriNo = 9;
            } else if (madoriNo === 11
                || madoriNo === 12
            ) {
                fromrequestRoomMadoriNo = 11;
                torequestRoomMadoriNo = 11;
            } else if (madoriNo === 13
                || madoriNo === 14
                || madoriNo === 15
                || madoriNo === 16
            ) {
                fromrequestRoomMadoriNo = 15;
                torequestRoomMadoriNo = 15;
            } else if (madoriNo > 0) {
                fromrequestRoomMadoriNo = 17;
                torequestRoomMadoriNo = null;
            }

            var area = matching.response_matching_detail.request_room_area;
            var areas = "";
            if (area === "緑") {
                areas = [1];
            } else if (area === "赤") {
                areas = [1];
            } else if (area === "黄") {
                areas = [5];
            } else if (area === "黒") {
                areas = [4];
            } else if (area === "オレンジ") {
                areas = [2, 3];
            } else if (area === "紫") {
                areas = [1];
            } else if (area === "ピンク") {
                areas = [3];
            } else if (area === "青") {
                areas = [2, 3];
            } else if (area === "白") {


                // areas =[1];
            }
            let shop = [];

            shop = this.props.shops.filter(function (shop) {
                return shop.tenpoNo === matching.response_matching_detail.section_id;
            });

            let user = [];
            const shopps = shop[0].tenpoNo;
            user = this.props.users.filter(function (user) {
                return user.mPositionId === 4 && user.amSectionId === shopps;
            });


            let rank = 4
            if (matching.response_matching_detail.tel1 && this.props.response_matching_select_list.length === 1) {
                rank = 1
            } else if (matching.response_matching_detail.tel1) {
                rank = 2
            } else if (this.props.response_matching_select_list.length === 1) {
                rank = 3
            }

            this.props.open_response_add(
                {
                    contract_form: 0,
                    name1: matching.response_matching_detail.name,
                    kana1: matching.response_matching_detail.kana1,
                    tel1: matching.response_matching_detail.tel1,
                    // section_id: matching.response_matching_detail.section_id,2023年小松さんより　全店で登録可能に
                    // section_id: 0,
                    section_id: this.props.user_details.amSectionId == 26 ? matching.response_matching_detail.section_id : 0,
                    // section_id: this.props.user_details.amSectionId == 26 || this.props.user_details.amSectionId == 10 ? matching.response_matching_detail.section_id : 0,
                    mail1: matching.response_matching_detail.email1,
                    rent_m_medium_id: matching.response_matching_detail.rent_m_medium_id,
                    building_name: matching.response_matching_detail.building_name,
                    request_from_plan: fromrequestRoomMadoriNo,
                    request_to_plan: torequestRoomMadoriNo,
                    request_from_cost: Number(matching.response_matching_detail.request_room_chinryou) > 0 ? Math.round((Number(matching.response_matching_detail.request_room_chinryou)) / 10000) * 10000 - 5000 : null,
                    request_to_cost: Number(matching.response_matching_detail.request_room_chinryou) > 0 ? Math.round((Number(matching.response_matching_detail.request_room_chinryou)) / 10000) * 10000 + 5000 : null,
                    areas: areas ? areas.join(',') : "",
                    rent_m_way_id: 2,
                    line_user_id: 0,
                    line_account_section_id: 0,
                })



            var response_add_select_mail_id_list = this.props.response_matching_select_list.filter(a => a.selected === true).map(function (value) {
                return value.id;

            });
            if (this.state.mailAllReceiveId > 0) {

                response_add_select_mail_id_list.push(this.state.mailAllReceiveId)
            }


            this.props.change_response_add_select_mail_id_list(response_add_select_mail_id_list)
            this.props.change_response_add_disp(1)
        }

        // 信用情報初期化
        this.props.change_response_attention_disp(false)
    }


    togglemailtaiou = (rent_response_id) => {
        this.setState({ rentResponseId: rent_response_id })
        this.setState(prevState => ({
            modalmailtaiou: !prevState.modalmailtaiou,
        }));
    }
    mailtaioudelete() {
        this.props.change_status({ id: this.state.rentResponseId, status: this.state.kidoku_status, status_id: this.state.kidoku_status_id })
        this.togglemailtaiou(0)
    }

    open_response_detail(id) {
        // alert(id)

        this.props.change_modalResponseDetail(true)
        this.props.change_responseid(id)
    }


    open_line(row) {
        this.props.get_line_user_details({ line_user_id: row.id, line_account_section_id: row.line_account_section_id })

        this.props.get_line_open({ line_user_id: row.id, line_account_section_id: row.line_account_section_id, take: 20 })
    }


    toggle_line_delete(value) {
        // alert(value.line_user_id)
        // this.setState({ line_delete: true })


        if (!this.state.line_delete) {
            //     // alert(value.line_user_id)
            this.setState({ line_del: value })
            this.setState({ line_delete: true })
        } else {
            this.setState({ line_delete: false })
            this.setState({ line_del: {} })
        }

    };


    line_delete(value) {


        this.props.delete_line_user(this.state.line_del)
        this.setState({ line_del: {} })
        this.setState({ line_delete: false })

    };


    line_bind(row) {

        var data = new Date()
        data.setMonth(data.getMonth() - 1)
        this.props.change_line_user_id({ line_user_id: row.id, line_account_section_id: row.line_account_section_id });
        this.props.get_response_line_matching_list({
            name: '',
            section_id: this.props.user_details.amSectionId,

            user_id: this.props.user_details.id,
            response_date_start: data.getFullYear() + '/' + (data.getMonth() + 1) + '/' + data.getDate()
        })
        // this.props.get_response_matching_select_list(row.id)
        // this.togglemail(row.id)
        // this.toggleMatching(row.id)
        this.props.change_modalLineMatching(true)
    }

    line_add(row) {
        // alert(row.name)
        this.props.change_line_user_id({ line_user_id: row.id, line_account_section_id: row.line_account_section_id })
        this.props.open_response_add(
            {
                contract_form: 0,
                name1: row.name1,
                section_id: 0,
                rent_m_medium_id: row.rent_m_medium_id,
                medium_remarks: row.medium_remarks,
                mypage_request_number_of_people: row.mypage_request_number_of_people,
                building_name: "",
                request_to_cost: row.request_to_cost,
                request_cost_remarks: row.request_cost_remarks,
                request_from_plan: row.request_from_plan,
                request_to_plan: row.request_to_plan,
                request_moving_time: row.request_moving_time,

                areas: row.rent_m_area_id,
                rent_m_way_id: 6,
            })
            
        // 信用情報初期化
        this.props.change_response_attention_disp(false)
    }

    toggle_card_delete(value) {
        // alert(value.line_user_id)
        // this.setState({ line_delete: true })

        if (!this.state.card_delete) {
            //     // alert(value.line_user_id)
            this.setState({ card_del: value })
            this.setState({ card_delete: true })
        } else {
            this.setState({ card_delete: false })
            this.setState({ card_del: {} })
        }

    };


    card_delete(value) {

        this.props.delete_card(this.state.card_del)
        this.setState({ card_del: {} })
        this.setState({ card_delete: false })

    };

    card_bind(row) {

        var data = new Date()
        data.setMonth(data.getMonth() - 1)
        this.props.change_hearing_sheet_id({ rent_hearing_sheet_id: row.rent_hearing_sheet_id, });
        this.props.get_response_card_matching_list({
            name: '',
            section_id: this.props.user_details.amSectionId,

            user_id: this.props.user_details.id,
            response_date_start: data.getFullYear() + '/' + (data.getMonth() + 1) + '/' + data.getDate(),

            rent_hearing_sheet_id: row.rent_hearing_sheet_id,
        })

        this.props.change_modalCardMatching(true)
    }

    card_add(row) {

        // alert(row.name)
        // this.props.change_line_user_id({ line_user_id: row.id, line_account_section_id: row.line_account_section_id })
        this.props.open_response_add(
            {
                contract_form: 0,
                // name1: row.name1,
                // section_id: 0,
                // rent_m_medium_id: row.rent_m_medium_id,
                // medium_remarks: row.medium_remarks,
                // mypage_request_number_of_people: row.mypage_request_number_of_people,
                // building_name: "",
                // request_to_cost: row.request_to_cost,
                // request_cost_remarks: row.request_cost_remarks,
                // request_from_plan: row.request_from_plan,
                // request_to_plan: row.request_to_plan,
                // request_moving_time: row.request_moving_time,

                // areas: row.rent_m_area_id,




                name1: row.name1,//ok
                kana1: row.kana1,//ok
                sex: row.sex,//ok
                birthday: row.birthday,//ok
                post: row.post,//ok
                add1: row.add1 + row.add2 + row.add3,//ok
                // "add2": row.add2,
                add2: row.add4,//ok
                tel1: row.tel1,//ok
                mail1: row.mail1,//ok

                student_independent: row.mypage_student_independent,//ok
                mypage_student_independent: row.mypage_student_independent,//ok

                mypage_rent_m_job_id: row.mypage_rent_m_job_id,//★

                office_name: row.mypage_office_name,//ok
                mypage_office_name: row.mypage_office_name,//ok

                mypage_office_add: row.mypage_office_add,//ok

                mypage_student_examinee: row.mypage_student_examinee,//★

                rent_m_school_id: row.mypage_request_rent_m_school_id,//ok
                mypage_request_rent_m_school_id: row.mypage_request_rent_m_school_id,//ok

                gakunen: row.mypage_request_gakunen,//ok
                mypage_request_gakunen: row.mypage_request_gakunen,//ok

                mypage_request_rent_m_highschool_id: row.mypage_request_rent_m_highschool_id,//★

                mypage_request_areas_request: row.mypage_request_areas_request,//★

                areas: row.mypage_request_areas,//ok
                mypage_request_areas: row.mypage_request_areas,//ok

                request_from_cost: row.mypage_request_from_cost,//ok
                request_to_cost: row.mypage_request_to_cost,//ok
                mypage_request_from_cost: row.mypage_request_from_cost,//ok
                mypage_request_to_cost: row.mypage_request_to_cost,//ok

                mypage_rent_current: row.mypage_rent_current,//ない

                mypage_request_plans: row.mypage_request_plans,//ない

                request_media: row.request_media,//ない
                rent_m_medium_id: row.request_media,//ない

                rent_m_reason_id: row.rent_m_reason_id,//ok

                mypage_introducer: row.mypage_introducer,//ない

                reason_remarks: row.reason_remarks,//ない

                request_moving_time: row.mypage_request_moving_time,//ok
                mypage_request_moving_time: row.mypage_request_moving_time,//ok

                mypage_request_number_of_people: row.mypage_request_number_of_people,//ない
                mypage_request_number_of_people_adult: row.mypage_request_number_of_people_adult,//ない
                mypage_request_number_of_people_child: row.mypage_request_number_of_people_child,//ない

                mypage_request_number_of_standardcar: row.mypage_request_number_of_standardcar,//★
                mypage_request_number_of_lightcar: row.mypage_request_number_of_lightcar,//★

                mypage_dog: row.mypage_dog,//ない
                mypage_dog_count: row.mypage_dog_count,//ない
                mypage_dog_remarks: row.mypage_dog_remarks,//ない
                mypage_cat: row.mypage_cat,//ない
                mypage_cat_count: row.mypage_cat_count,//ない
                mypage_cat_remarks: row.mypage_cat_remarks,//ない

                response_requests: row.mypage_response_requests,//ok
                mypage_response_requests: row.mypage_response_requests,//ok

                mypage_request_bike: row.mypage_request_bike,//★

                mypage_request_remarks: row.mypage_request_remarks,//★


                rent_m_way_id: 5, // 飛び込み

                rent_hearing_sheet_id: row.rent_hearing_sheet_id,
            })

        // 信用情報初期化
        this.props.change_response_attention_disp(false)
    }

    matching(row) {
        this.selectedrow(0, 0)
        // alert()
        this.props.change_response_matching_list_loading(1)
        this.props.change_response_matching_select_list_loading(1)
        this.props.get_response_matching_list(row.id)
        this.props.get_response_matching_select_list(row.id)
        // this.togglemail(row.id)
        // this.toggleMatching(row.id)
        this.props.change_modalMatching(true)
    }

    open_customer_window(row) {
        window.open(`/customer/` + row.id, '_blank', 'noopener')
    }

    toggleAutoSend = (id) => {

        this.setState(prevState => ({
            modalAutoSend: !prevState.modalAutoSend,
            rentResponseId: id
        }));
    }


    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            },
        }));
    }

    handleChangeLineSend(e, response_id) {
        let response_search_list = this.props.response_search_list


        response_search_list = response_search_list.map(function (value) {
            if (value.request === 0 && parseInt(value.id) == parseInt(response_id)) {
                value.line_send = e.target.checked
            }
            return value;

        });

        // response_search_list[row].line_send = e.target.checked

        this.props.change_response_search_list(response_search_list)

    }

    onClickAttentionDisp(detail) {

        let newDetail = {...detail}
        newDetail.mail1 = detail.email1

        if(detail.name1 || 
            detail.kana1 || 
            detail.tel1 || 
            detail.tel2 || 
            detail.email1 || 
            detail.mail2){
              
            this.props.get_attentions_matching_list(newDetail)
        }

        this.setState({ attention_disp: true })
    }


    render() {


        return (
            <div className="responselist">

                <Table name="grid_table" rowHeight={101} rowsCount={this.props.response_search_list.length} headerHeight={40}
                    width={window.innerWidth - 338}
                    height={window.innerHeight - 60}

                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                >
                    


                    <Column isResizable={true} columnKey="column1" header={<Cell>反響日</Cell>} cell={({ rowIndex, ...props }) => (<Cell name="table" className={this.props.response_search_list[rowIndex].request == 1 ? "pink" :
                        this.props.response_search_list[rowIndex].request == 2 ? "green" :
                            this.props.response_search_list[rowIndex].request == 3 ? "lightblue" :
                                this.props.response_search_list[rowIndex].receive_mail_at ? "yellow" :
                                    this.props.response_search_list[rowIndex].receive_line_at ? "yellowgreen" :
                                    this.props.response_search_list[rowIndex].coming_reservation_at || this.props.response_search_list[rowIndex].coming_reservation_cancel_at ? "purple" :
                                    this.props.response_search_list[rowIndex].favorite_set_at ? "orange" :
                                    this.props.response_search_list[rowIndex].condition_set_at ? "blue" :
                                    this.props.response_search_list[rowIndex].mypage_inquiry_at ? "red" :
                                        this.props.response_search_list[rowIndex].end_response_date != null ? "gray" : ""}>
                        <table className="in_table">
                            {/* <colgroup style={{ width: "40%" }} />
                                    <colgroup style={{ width: "60%" }} /> */}
                            <tbody>

                                {/* 1行目 */}
                                <tr>
                                    {this.props.formData.line_send == true ? <td rowSpan={3} style={{ width: 30, borderRight: "1px solid #d3d3d3" }}>
                                        {this.props.response_search_list[rowIndex].request == 0 ? <FormControlLabel control={<Checkbox style={{ paddingTop: 0, paddingBottom: 0 }} checked={this.props.response_search_list[rowIndex].line_send == true ? true : false} name="line_send" onChange={(e) => this.handleChangeLineSend(e, this.props.response_search_list[rowIndex].id)} />} label="" /> : ""}
                                    </td> : ""}
                                    <td className="th" style={{ width: "110px" }}>

                                        {(() => {

                                            if (this.props.response_search_list[rowIndex].request === 1) {
                                                // if(value==1){
                                                //     return <div style={{ textAlign: "left" }}><h5><span class="badge badge-primary">アパマン</span></h5> </div>
                                                // }else if(value==14){
                                                // return <div style={{ textAlign: "left" }}><h5><span class="badge badge-success">スーモ</span></h5> </div>co
                                                const mediaArray = this.props.response_search_list[rowIndex].media.split(",");
                                                return <span>

                                                    {mediaArray.filter((v) => v === "1").length > 0 ? <span className="badge badge-primary">アパマン</span> : ""}
                                                    {mediaArray.filter((v) => v === "14").length > 0 ? <span className="badge badge-success">スーモ</span> : ""}
                                                    {mediaArray.filter((v) => v === "16").length > 0 ? <span className="badge badge-warning">ホームズ</span> : ""}
                                                    {mediaArray.filter((v) => v === "21").length > 0 ? <span className="badge badge-danger">アットホーム</span> : ""}
                                                    {mediaArray.filter((v) => v === "29").length > 0 ? <span className="badge badge-light">すまいズ</span> : ""}
                                                    {mediaArray.filter((v) => v === "2").length > 0 ? <span className="badge badge-info">CLASO.</span> : ""}
                                                    {mediaArray.filter((v) => v === "30").length > 0 ? <span className="badge badge-info">高松賃貸.net</span> : ""}
                                                    {mediaArray.filter((v) => v === "36").length > 0 ? <span className="badge badge-info">スモッカ</span> : ""}


                                                </span>;
                                            } else if (this.props.response_search_list[rowIndex].request === 2) {
                                                return <span style={{ color: 'green', fontSize: 20, padding: "1px" }} className="fab fa-line " />
                                            } else if (this.props.response_search_list[rowIndex].request === 3) {
                                                // return <span style={{ color: 'blue', fontSize: 20, padding: "1px" }} className="far fa-address-card" />
                                                return <span className="badge badge-primary">Web来店カード</span>
                                            } else {
                                                let rank = "";
                                                if (this.props.response_search_list[rowIndex].rank === 1) {
                                                    rank = <span className="badge badge-danger">S</span>
                                                } else if (this.props.response_search_list[rowIndex].rank === 2) {
                                                    rank = <span className="badge badge-warning">A</span>
                                                } else if (this.props.response_search_list[rowIndex].rank === 3) {
                                                    rank = <span className="badge badge-primary">B</span>
                                                } else if (this.props.response_search_list[rowIndex].rank === 4) {
                                                    rank = <span className="badge badge-light">C</span>
                                                }


                                                let shop = "";
                                                if (this.props.response_search_list[rowIndex].section_id === 1) {
                                                    shop = <span className="badge badge-success">番</span>
                                                } else if (this.props.response_search_list[rowIndex].section_id === 2) {
                                                    shop = <span className="badge badge-warning">レ</span>
                                                } else if (this.props.response_search_list[rowIndex].section_id === 3) {
                                                    shop = <span className="badge badge-danger">駅</span>
                                                } else if (this.props.response_search_list[rowIndex].section_id === 4) {
                                                    shop = <span className="badge badge-dark">東</span>
                                                } else if (this.props.response_search_list[rowIndex].section_id === 6) {
                                                    shop = <span className="badge badge-light">公</span>
                                                } else if (this.props.response_search_list[rowIndex].section_id === 17) {
                                                    shop = <span className="badge badge-info">サ</span>
                                                } else if (this.props.response_search_list[rowIndex].section_id === 47) {
                                                    shop = <span className="badge badge-secondary">通</span>
                                                }


                                                // return <div style={{ textAlign: "left" }}><span style={{ fontSize: "16px" }}>{shop} {rank} {value > 0 ? <span class="badge badge-success"> <span class="fas fa-shoe-prints"> {value}</span></span> : <span class="badge badge-info"> <span class="fas fa-envelope">{props.row.col8}</span></span>}</span><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>反響日</span>{props.row.col9 ? props.row.col9 : ""}</div >;

                                                return <div style={{ textAlign: "" }}>
                                                    <span style={{ fontSize: "16px" }}>
                                                        {shop}
                                                        {rank}
                                                        {this.props.response_search_list[rowIndex].coming_response_count > 0 ? <span className="badge badge-success"> <span className="fas fa-shoe-prints"> {this.props.response_search_list[rowIndex].coming_response_count}</span></span> : <span className="badge badge-info"> <span className="fas fa-envelope">{this.props.response_search_list[rowIndex].contact_response_count}</span></span>}
                                                        {/* {this.props.response_search_list[rowIndex].coming_reservation_cancel_at} */}
                                                        
                                                    </span>
                                                    </div >;
                                            }

                                        })()}


                                    </td>
                                    <td><span className="title">反響日</span>{this.props.response_search_list[rowIndex].date ? moment(new Date(this.props.response_search_list[rowIndex].date).setTime(new Date(this.props.response_search_list[rowIndex].date).getTime() - 1000 * 60 * 60 * 9)).format("YYYY年MM月DD日") : ""}
                                    </td>
                                </tr>

                                {/* 2行目 */}

                                <tr>
                                    <td className="th">

                                      
                                        {this.props.response_search_list[rowIndex].request !== 1 && this.props.response_search_list[rowIndex].request !== 2 && this.props.response_search_list[rowIndex].request !== 3 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#5cb85c" }} onClick={() => this.open_response_detail(this.props.response_search_list[rowIndex].id)}>詳細</Button> : 
                                        
                                        this.props.response_search_list[rowIndex].request === 2 || this.props.response_search_list[rowIndex].request === 3 ?
                                                this.props.response_search_list[rowIndex].section_id === 1 ?
                                                    <span className="badge badge-success">番</span> :
                                                this.props.response_search_list[rowIndex].section_id === 2 ?
                                                    <span className="badge badge-warning">レ</span>:
                                                this.props.response_search_list[rowIndex].section_id === 3 ?
                                                    <span className="badge badge-danger">駅</span>:
                                                this.props.response_search_list[rowIndex].section_id === 4 ?
                                                    <span className="badge badge-dark">東</span>:
                                                this.props.response_search_list[rowIndex].section_id === 6 ?
                                                    <span className="badge badge-light">公</span>:
                                                this.props.response_search_list[rowIndex].section_id === 17 ?
                                                    <span className="badge badge-info">サ</span>:
                                                this.props.response_search_list[rowIndex].section_id === 47 ?
                                                    <span className="badge badge-secondary">通</span> :
                                                "" 
                                        :
                                        ""}

                                        {this.props.response_search_list[rowIndex].request !== 1 && this.props.response_search_list[rowIndex].request !== 2 && this.props.response_search_list[rowIndex].request !== 3 ? <IconButton size="small" onClick={() => this.open_customer_window(this.props.response_search_list[rowIndex])}><WebIcon style={{ color: "#007bff" }}></WebIcon></IconButton> : ""}

                                        {this.props.response_search_list[rowIndex].request == 1 && 
                                        this.props.response_search_list[rowIndex].media && 
                                        this.props.response_search_list[rowIndex].media.split(",").filter((v) => v === "2").length > 0 && 
                                        this.props.response_search_list[rowIndex].to_mail == 'syataku@global-center.co.jp' ? <span className="badge badge-light">社宅斡旋</span> : ""}

                                    </td>
                                    <td>
                                        {(() => {

                                            if (this.props.response_search_list[rowIndex].request === 1) {
                                                const time = this.props.response_search_list[rowIndex].date ? moment(new Date(this.props.response_search_list[rowIndex].date).setTime(new Date(this.props.response_search_list[rowIndex].date).getTime() - 1000 * 60 * 60 * 9)) : ""
                                                return <span className="title">反響日</span>;
                                            } else if (this.props.response_search_list[rowIndex].request === 2) {
                                                const time = this.props.response_search_list[rowIndex].date ? moment(new Date(this.props.response_search_list[rowIndex].date).setTime(new Date(this.props.response_search_list[rowIndex].date).getTime() - 1000 * 60 * 60 * 9)) : ""
                                                return <span className="title">受信日時</span>;
                                            } else if (this.props.response_search_list[rowIndex].request === 3) {
                                                const time = this.props.response_search_list[rowIndex].date ? moment(new Date(this.props.response_search_list[rowIndex].date).setTime(new Date(this.props.response_search_list[rowIndex].date).getTime() - 1000 * 60 * 60 * 9)) : ""
                                                return <span className="title">受付日時</span>;
                                            } else {
                                                let status = "";

                                                if(this.props.response_search_list[rowIndex].receive_mail_at){
                                                    status = <span className="badge badge-danger"><span className="fas fa-envelope"></span>受信</span>
                                                }else if(this.props.response_search_list[rowIndex].receive_line_at){
                                                    status = <span className="badge badge-danger"><span className="fab fa-line " />受信</span>
                                                }else if(this.props.response_search_list[rowIndex].coming_reservation_at){
                                                    status = <span className="badge badge-secondary">来店予約</span>
                                                }else if(this.props.response_search_list[rowIndex].coming_reservation_cancel_at){
                                                    status = <span className="badge badge-secondary">予約ｷｬﾝｾﾙ</span>
                                                }else if(this.props.response_search_list[rowIndex].favorite_set_at){
                                                    status = <span className="badge badge-secondary">お気に入り</span>
                                                }else if(this.props.response_search_list[rowIndex].condition_set_at){
                                                    status = <span className="badge badge-secondary">条件設定</span>
                                                }else if(this.props.response_search_list[rowIndex].mypage_inquiry_at){
                                                    status = <span className="badge badge-secondary">問合せ</span>
                                                
                                                
                                                }else if (this.props.response_search_list[rowIndex].status === 1) {
                                                    status = <span className="badge badge-danger"><span className="fas fa-envelope"></span>受信</span>
                                                } else if (this.props.response_search_list[rowIndex].status === 2) {
                                                    status = <span className="badge badge-success"><span className="fas fa-envelope"></span>送信</span>
                                                } else if (this.props.response_search_list[rowIndex].status === 3) {
                                                    status = <span className="badge badge-info">自動送信</span>
                                                } else if (this.props.response_search_list[rowIndex].status === 4) {
                                                    status = <span className="badge badge-secondary">既読</span>
                                                } else if (this.props.response_search_list[rowIndex].status === 5) {
                                                    status = <span className="badge badge-secondary">反響受信</span>
                                                } else if (this.props.response_search_list[rowIndex].status === 6) {
                                                    status = <span className="badge badge-secondary">対応</span>
                                                } else if (this.props.response_search_list[rowIndex].status === 7) {
                                                    status = <span className="badge badge-danger">マッチング物件なし</span>
                                                } else if (this.props.response_search_list[rowIndex].status === 12) {
                                                    status = <span className="badge badge-danger"><span className="fab fa-line " />受信</span>
                                                } else if (this.props.response_search_list[rowIndex].status === 13) {
                                                    status = <span className="badge badge-success"><span className="fab fa-line " />送信</span>
                                                } else {
                                                    status = <span className="badge badge-secondary">最終対応</span>
                                                }


                                                return <span style={{ fontSize: "16px" }}>{status} </span>

                                            }

                                        })()}
                                        {(() => {


                                            if (this.props.response_search_list[rowIndex].request === 1) {
                                                const time = this.props.response_search_list[rowIndex].date ? moment(new Date(this.props.response_search_list[rowIndex].date).setTime(new Date(this.props.response_search_list[rowIndex].date).getTime() - 1000 * 60 * 60 * 9)) : ""
                                                // return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>反響日</span>{value ? value : ""}</div >;
                                                return time ? time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm") : "";
                                            } else if (this.props.response_search_list[rowIndex].request === 2) {
                                                // return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>受信日時</span>{value ? value : ""}</div >;
                                                const time = this.props.response_search_list[rowIndex].date ? moment(new Date(this.props.response_search_list[rowIndex].date).setTime(new Date(this.props.response_search_list[rowIndex].date).getTime() - 1000 * 60 * 60 * 9)) : ""
                                                return time ? time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm") : "";
                                            } else if (this.props.response_search_list[rowIndex].request === 3) {
                                                // const time = this.props.response_search_list[rowIndex].date ? moment(new Date(this.props.response_search_list[rowIndex].date)) : ""
                                                // return time ? time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm") : "";
                                                const time = this.props.response_search_list[rowIndex].date ? moment(new Date(this.props.response_search_list[rowIndex].date)) : ""
                                                return time ? time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm") : "";
                                            } else {

                                                if(this.props.response_search_list[rowIndex].receive_mail_at){
                                                    return moment(this.props.response_search_list[rowIndex].receive_mail_at).utc().format("YYYY年MM月DD日 H:mm")
                                                }else if(this.props.response_search_list[rowIndex].receive_line_at){
                                                    return moment(this.props.response_search_list[rowIndex].receive_line_at).utc().format("YYYY年MM月DD日 H:mm")
                                                }else if(this.props.response_search_list[rowIndex].coming_reservation_at){
                                                    return moment(this.props.response_search_list[rowIndex].coming_reservation_at).utc().format("YYYY年MM月DD日 H:mm") 
                                                }else if(this.props.response_search_list[rowIndex].coming_reservation_cancel_at){
                                                    return moment(this.props.response_search_list[rowIndex].coming_reservation_cancel_at).utc().format("YYYY年MM月DD日 H:mm") 
                                                }else if(this.props.response_search_list[rowIndex].favorite_set_at){
                                                    return moment(this.props.response_search_list[rowIndex].favorite_set_at).utc().format("YYYY年MM月DD日 H:mm") 
                                                }else if(this.props.response_search_list[rowIndex].condition_set_at){
                                                    return moment(this.props.response_search_list[rowIndex].condition_set_at).utc().format("YYYY年MM月DD日 H:mm") 
                                                }else if(this.props.response_search_list[rowIndex].mypage_inquiry_at){
                                                    return moment(this.props.response_search_list[rowIndex].mypage_inquiry_at).utc().format("YYYY年MM月DD日 H:mm") 
                                                }else{
                                                    // return <div style={{ textAlign: "left" }}><span style={{ fontSize: "16px" }}>{status} </span><span >{value ? value : ""}</span></div >
                                                    const time = this.props.response_search_list[rowIndex].last_response_date ? moment(new Date(this.props.response_search_list[rowIndex].last_response_date).setTime(new Date(this.props.response_search_list[rowIndex].last_response_date).getTime() - 1000 * 60 * 60 * 9)) : ""
                                                    return time ? time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm") : ""
                                                }
                                            }

                                        })()}
                                    </td>
                                </tr>

                                {/* 3行目 */}

                                <tr>
                                    <td className="bottom th">

                                        {this.props.response_search_list[rowIndex].receive_mail_at ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => {this.togglemailtaiou(this.props.response_search_list[rowIndex].id);this.setState({ "kidoku_status": "receive_mail_at", "kidoku_status_id": 1 })}}>確認済みにする</Button> :
                                        this.props.response_search_list[rowIndex].receive_line_at ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => {this.togglemailtaiou(this.props.response_search_list[rowIndex].id);this.setState({ "kidoku_status": "receive_line_at", "kidoku_status_id": 12 })}}>確認済みにする</Button> :
                                        this.props.response_search_list[rowIndex].coming_reservation_at ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => {this.togglemailtaiou(this.props.response_search_list[rowIndex].id);this.setState({ "kidoku_status": "coming_reservation_at", "kidoku_status_id": 16 })}}>確認済みにする</Button> :
                                        this.props.response_search_list[rowIndex].coming_reservation_cancel_at ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => {this.togglemailtaiou(this.props.response_search_list[rowIndex].id);this.setState({ "kidoku_status": "coming_reservation_cancel_at", "kidoku_status_id": 20 })}}>確認済みにする</Button> :
                                        this.props.response_search_list[rowIndex].favorite_set_at ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => {this.togglemailtaiou(this.props.response_search_list[rowIndex].id);this.setState({ "kidoku_status": "favorite_set_at", "kidoku_status_id": 17 })}}>確認済みにする</Button> :
                                        this.props.response_search_list[rowIndex].condition_set_at ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => {this.togglemailtaiou(this.props.response_search_list[rowIndex].id);this.setState({ "kidoku_status": "condition_set_at", "kidoku_status_id": 18 })}}>確認済みにする</Button> : 
                                        this.props.response_search_list[rowIndex].mypage_inquiry_at ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => {this.togglemailtaiou(this.props.response_search_list[rowIndex].id);this.setState({ "kidoku_status": "mypage_inquiry_at", "kidoku_status_id": 19 })}}>確認済みにする</Button> :""}
                                        {this.props.response_search_list[rowIndex].request === 1 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#5cb85c", marginRight: 3 }} onClick={() => this.matching(this.props.response_search_list[rowIndex])}>マッチング</Button> : ""}


                                        {this.props.response_search_list[rowIndex].request === 2 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#5cb85c", marginRight: 3 }} onClick={() => this.open_line(this.props.response_search_list[rowIndex])}>LINE表示</Button> : ""}

                                        {this.props.response_search_list[rowIndex].request === 2 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff", marginRight: 3 }} onClick={() => this.line_bind(this.props.response_search_list[rowIndex])} >反響結付け</Button> : ""}
                                        {this.props.response_search_list[rowIndex].request === 3 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff", marginRight: 3 }} onClick={() => this.card_bind(this.props.response_search_list[rowIndex])} >反響結付け</Button> : ""}

                                        {this.props.response_search_list[rowIndex].request === 2 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#ffc107", marginRight: 3 }} onClick={() => this.line_add(this.props.response_search_list[rowIndex])}  >新規登録</Button> : ""}
                                        {this.props.response_search_list[rowIndex].request === 3 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#ffc107", marginRight: 3 }} onClick={() => this.card_add(this.props.response_search_list[rowIndex])}  >新規登録</Button> : ""}

                                        {this.props.response_search_list[rowIndex].request === 2 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", marginRight: 3 }} onClick={() => this.toggle_line_delete({ line_user_id: this.props.response_search_list[rowIndex].id, line_account_section_id: this.props.response_search_list[rowIndex].line_account_section_id })} >削除</Button> : ""}
                                        {this.props.response_search_list[rowIndex].request === 3 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", marginRight: 3 }} onClick={() => this.toggle_card_delete({ rent_hearing_sheet_id: this.props.response_search_list[rowIndex].rent_hearing_sheet_id })} >削除</Button> : ""}

                                        {this.props.response_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? <Button size="small" variant="contained" color="secondary" style={{ paddingLeft: 5, paddingRight: 5, color: "white", backgroundColor: "#007bff" }} onClick={() => this.toggleAutoSend(this.props.response_search_list[rowIndex].id)} >次回自動送信予定</Button> : ""}
                                    </td>
                                    <td className="bottom">
                                        {(() => {

                                            if (this.props.response_search_list[rowIndex].request === 1) {
                                                if (this.props.response_search_list[rowIndex].shops !== null) {
                                                    const shopArray = this.props.response_search_list[rowIndex].shops.split(",");
                                                    return <span>

                                                        {shopArray.filter((v) => v === "1").length > 0 ? <span className="badge badge-success">番</span> : ""}
                                                        {shopArray.filter((v) => v === "2").length > 0 ? <span className="badge badge-warning">レ</span> : ""}
                                                        {shopArray.filter((v) => v === "3").length > 0 ? <span className="badge badge-danger">駅</span> : ""}
                                                        {shopArray.filter((v) => v === "4").length > 0 ? <span className="badge badge-dark">東</span> : ""}
                                                        {shopArray.filter((v) => v === "6").length > 0 ? <span className="badge badge-light">公</span> : ""}
                                                        {shopArray.filter((v) => v === "17").length > 0 ? <span className="badge badge-info">サ</span> : ""}
                                                        {shopArray.filter((v) => v === "47").length > 0 ? <span className="badge badge-secondary">通</span> : ""}
                                                    </span>;
                                                } else {
                                                    // return this.props.response_search_list[rowIndex].section_id;
                                                    return "";
                                                }

                                            } else if (this.props.response_search_list[rowIndex].request === 2 || this.props.response_search_list[rowIndex].request === 3) {
                                                return "";
                                            } else {
                                                //１メール受信　２送信　3自動送信　4既読  5反響受信　6対応　7マッチング物件なし 10追客終了　11追客終了戻し 12LINE受信　14来店カード新規
                                                let status = "";

                                                if (this.props.response_search_list[rowIndex].raiten_date) {
                                                    if (this.props.response_search_list[rowIndex].intend_coming) {
                                                        status = <span className="badge badge-success">来店予定</span>
                                                    } else {
                                                        status = <span className="badge badge-secondary">来店</span>
                                                    }
                                                    let time = this.props.response_search_list[rowIndex].raiten_date ? moment(new Date(this.props.response_search_list[rowIndex].raiten_date).setTime(new Date(this.props.response_search_list[rowIndex].raiten_date).getTime() - 1000 * 60 * 60 * 9)) : ""
                                                    return <span >{time === "" ? "" : <span><span style={{ fontSize: "16px" }}>{status} </span>{time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm")}</span>}</span>

                                                }

                                                if (String(this.props.response_search_list[rowIndex].intended_rent_m_response_status_id) === "2") {
                                                    status = <span className="badge badge-success">メール予定</span>
                                                } else if (String(this.props.response_search_list[rowIndex].intended_rent_m_response_status_id) === "3") {
                                                    status = <span className="badge badge-info">自動送信予定</span>
                                                } else if (String(this.props.response_search_list[rowIndex].intended_rent_m_response_status_id) === "6") {
                                                    status = <span className="badge badge-secondary">対応予定</span>
                                                } else if (String(this.props.response_search_list[rowIndex].intended_rent_m_response_status_id) === "8") {
                                                    status = <span className="badge badge-success">来店予定</span>
                                                } else if (String(this.props.response_search_list[rowIndex].intended_rent_m_response_status_id) === "9") {
                                                    status = <span className="badge badge-warning">TEL予定</span>

                                                } else {
                                                    status = <span className="badge badge-success">予定</span>
                                                }

                                                let time = this.props.response_search_list[rowIndex].intended_at ? moment(new Date(this.props.response_search_list[rowIndex].intended_at).setTime(new Date(this.props.response_search_list[rowIndex].intended_at).getTime() - 1000 * 60 * 60 * 9)) : ""
                                                return <span >{time === "" ? "" : <span><span style={{ fontSize: "16px" }}>{status} </span>{time.format("H:mm") === "0:00" ? time.format("YYYY年MM月DD日") : time.format("YYYY年MM月DD日 H:mm")}</span>}</span>



                                                // return <span style={{ fontSize: "16px" }}>{status} </span>


                                                // return <div style={{ textAlign: "left" }}> {value}</div >;
                                            }



                                        })()}
                                        {/* <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => this.toggleAutoSend(this.props.response_search_list[rowIndex].id)} >次回自動送信予定</Button> */}
                                        {/* {this.props.response_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#007bff" }} onClick={() => this.toggleAutoSend(this.props.response_search_list[rowIndex].id)} >次回自動送信予定</Button> : ""} */}

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Cell>)} width={this.state.columnWidths.column1} />

                    {/* 2列目 */}
                    <Column isResizable={true} columnKey="column2" header={<Cell>お客様名</Cell>} cell={({ rowIndex, ...props }) => (<Cell name="table" className={this.props.response_search_list[rowIndex].request == 1 ? "pink" :
                        this.props.response_search_list[rowIndex].request == 2 ? "green" :
                            this.props.response_search_list[rowIndex].request == 3 ? "lightblue" :
                                this.props.response_search_list[rowIndex].receive_mail_at ? "yellow" :
                                    this.props.response_search_list[rowIndex].receive_line_at ? "yellowgreen" :
                                    this.props.response_search_list[rowIndex].coming_reservation_at || this.props.response_search_list[rowIndex].coming_reservation_cancel_at ? "purple" :
                                    this.props.response_search_list[rowIndex].favorite_set_at ? "orange" :
                                    this.props.response_search_list[rowIndex].condition_set_at ? "blue" :
                                    this.props.response_search_list[rowIndex].mypage_inquiry_at ? "red" :
                                        this.props.response_search_list[rowIndex].end_response_date != null ? "gray" : ""}>
                        <table className="in_table">
                            {/* <colgroup style={{ width: "40%" }} />
                                    <colgroup style={{ width: "50%" }} />
                                    <colgroup style={{ width: "10%" }} /> */}
                            <tbody>
                                {/* 1行目 */}
                                <tr>
                                    <td className="th" style={{ width: "80px" }}><span className="title">顧客名</span></td>
                                    <td colSpan={2} style={{ fontWeight: "bold" }}>

                                        {(() => {

                                            if (this.props.response_search_list[rowIndex].request === 2) {
                                                return <div>{this.props.response_search_list[rowIndex].line_img_url ? <span style={{position: "relative"}}><img className="faceicon" src={'https://img.global-center.co.jp/v1/line/img/' + this.props.response_search_list[rowIndex].line_user_id + '/200/200'} alt="lineimg" />{this.props.response_search_list[rowIndex].unfollowed_at ? <i style={{ color: "red", position: "absolute", right: 0, top: -2 }} class="fas fa-ban fa-lg"></i> : ""}</span> : ""}<b>{this.props.response_search_list[rowIndex].name1}</b></div >;
                                            } else if (this.props.response_search_list[rowIndex].request === 1) {
                                                return <div>{this.props.response_search_list[rowIndex].line_img_url ? <span style={{position: "relative"}}><img className="faceicon" src={'https://img.global-center.co.jp/v1/line/img/' + this.props.response_search_list[rowIndex].line_user_id + '/200/200'} alt="lineimg" />{this.props.response_search_list[rowIndex].unfollowed_at ? <i style={{ color: "red", position: "absolute", right: 0, top: -2 }} class="fas fa-ban fa-lg"></i> : ""}</span> : ""}<b>{this.props.response_search_list[rowIndex].name1}</b>{this.props.response_search_list[rowIndex].attentions > 0 ? <span onClick={()=>this.onClickAttentionDisp(this.props.response_search_list[rowIndex])} class="badge badge-danger p-2">信用情報マッチングあり！</span> : ""}</div >;
                                            } else {
                                                return <div>
                                                    {
                                                        this.props.response_search_list[rowIndex].line_img_url ? this.props.response_search_list[rowIndex].line_img_url.split(',').map(function (item, key) {
                                                            return (
                                                                <span>
                                                                    {/* {this.props.response_search_list[rowIndex].unfollowed_at && this.props.response_search_list[rowIndex].unfollowed_at.split(',')[key] ? <i style={{ color: "red" }} class="fas fa-ban fa-lg"></i> : ""} */}
                                                                    {this.props.response_search_list[rowIndex].line_img_url.split(',')[key] != "" ? <span style={{position: "relative"}}><img className="faceicon" src={'https://img.global-center.co.jp/v1/line/img/' + this.props.response_search_list[rowIndex].line_user_id.split(',')[key] + '/200/200'} alt="lineimg" />{this.props.response_search_list[rowIndex].unfollowed_at.split(',')[key] ? <Tooltip title="ブロックされています"><span><i style={{ color: "red", position: "absolute", right: 0, top: -2 }} class="fas fa-ban fa-lg"></i></span></Tooltip> : ""}</span> : this.props.response_search_list[rowIndex].unfollowed_at && this.props.response_search_list[rowIndex].unfollowed_at.split(',')[key] ? <i style={{ color: "red" }} class="fas fa-ban fa-lg"></i> : ""}
                                                                </span>
                                                            )
                                                        }, this) : ""
                                                    }

                                                    <b>
                                                        <span className="badge badge-info">{this.props.response_search_list[rowIndex].memo}</span>
                                                        {this.props.response_search_list[rowIndex].name1}
                                                        {this.props.response_search_list[rowIndex].contract_form ? this.props.response_search_list[rowIndex].name2 ? "(" + this.props.response_search_list[rowIndex].name2 + ")" : "" : ""}
                                                    </b></div >
                                            }



                                        })()}

                                        {/* 
                                                {this.props.response_search_list[rowIndex].line_img_url ? <img style={{margin:"0px 3px"}} className="faceicon" src={this.props.response_search_list[rowIndex].line_img_url} alt="lineimg" /> : ""}
                                                {this.props.response_search_list[rowIndex].name} */}
                                    </td>
                                </tr>

                                {/* 2列目 */}
                                <tr>
                                    <td className="th">
                                        {this.props.response_search_list[rowIndex].request === 2 ? <span className="title">メッセージ</span> : this.props.response_search_list[rowIndex].request === 3 ? <span className="title">職業</span> : <span className="title">{this.props.response_search_list[rowIndex].request === 0 && this.props.formData.condition_radio == 1 ? "契約物件" : "問合物件"}</span>}
                                    </td>
                                    <td colSpan={2}>
                                        {this.props.response_search_list[rowIndex].request === 3 && this.props.response_search_list[rowIndex].mypage_student_independent == 1 ? "社会人　" :
                                            this.props.response_search_list[rowIndex].request === 3 && this.props.response_search_list[rowIndex].mypage_student_independent == 2 ? "学生　" :
                                            this.props.response_search_list[rowIndex].request === 0 && this.props.formData.condition_radio == 1 ? this.props.response_search_list[rowIndex].contract_building_name + " " + this.props.response_search_list[rowIndex].contract_room_name : this.props.response_search_list[rowIndex].building_name}
                                        {this.props.response_search_list[rowIndex].request === 3 && this.props.response_search_list[rowIndex].mypage_student_independent == 2 ? this.props.response_search_list[rowIndex].mypage_student_examinee == 1 ? "在学中" : this.props.response_search_list[rowIndex].mypage_student_examinee == 2 ? "受験生" : "" : ""}
                                    </td>
                                </tr>

                                {/* 3列目 */}
                                <tr>
                                    <td className="th" style={{ width: "50px" }}>
                                        {this.props.response_search_list[rowIndex].request === 0 ? <span className="title">最終閲覧</span> : this.props.response_search_list[rowIndex].request === 2 ? <span className="title">登録元</span> : ""}
                                    </td>
                                    <td>
                                        {this.props.response_search_list[rowIndex].request === 0 && this.props.response_search_list[rowIndex].display_at ?
                                            moment().diff(moment(moment(this.props.response_search_list[rowIndex].display_at).utc().format("YYYY-MM-DD HH:mm:ss")), 'minutes', false) <= 5 ? <span className='badge badge-warning' style={{ fontWeight: "bold" }}><VisibilityIcon /> マイページ閲覧中</span> :
                                                moment().diff(moment(moment(this.props.response_search_list[rowIndex].display_at).utc().format("YYYY-MM-DD HH:mm:ss")), 'minutes', false) < 60 ? moment().diff(moment(moment(this.props.response_search_list[rowIndex].display_at).utc().format("YYYY-MM-DD HH:mm:ss")), 'minutes', false) + "分前" :
                                                    moment().diff(moment(moment(this.props.response_search_list[rowIndex].display_at).utc().format("YYYY-MM-DD HH:mm:ss")), 'hours', false) < 24 ? moment().diff(moment(moment(this.props.response_search_list[rowIndex].display_at).utc().format("YYYY-MM-DD HH:mm:ss")), 'hours', false) + "時間前" :
                                                        moment().diff(moment(moment(this.props.response_search_list[rowIndex].display_at).utc().format("YYYY-MM-DD HH:mm:ss")), 'days', false) < 31 ? moment().diff(moment(moment(this.props.response_search_list[rowIndex].display_at).utc().format("YYYY-MM-DD HH:mm:ss")), 'days', false) + "日前" :
                                                            moment(this.props.response_search_list[rowIndex].display_at).utc().format("YYYY年M月D日 HH:mm:ss")
                                            : ""}
                                        {this.props.response_search_list[rowIndex].request === 2 ? this.props.response_search_list[rowIndex].line_m_reaction_name:""}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </Cell>)} width={this.state.columnWidths.column2} />
                    <Column isResizable={true} columnKey="column3" header={<Cell>連絡先</Cell>} cell={({ rowIndex, ...props }) => (<Cell name="table" className={this.props.response_search_list[rowIndex].request == 1 ? "pink" :
                        this.props.response_search_list[rowIndex].request == 2 ? "green" :
                            this.props.response_search_list[rowIndex].request == 3 ? "lightblue" :
                                this.props.response_search_list[rowIndex].receive_mail_at ? "yellow" :
                                    this.props.response_search_list[rowIndex].receive_line_at ? "yellowgreen" :
                                    this.props.response_search_list[rowIndex].coming_reservation_at || this.props.response_search_list[rowIndex].coming_reservation_cancel_at ? "purple" :
                                    this.props.response_search_list[rowIndex].favorite_set_at ? "orange" :
                                    this.props.response_search_list[rowIndex].condition_set_at ? "blue" :
                                    this.props.response_search_list[rowIndex].mypage_inquiry_at ? "red" :
                                        this.props.response_search_list[rowIndex].end_response_date != null ? "gray" : ""}>
                        <table className="in_table">
                            {/* <colgroup style={{ width: "40%" }} />
                                    <colgroup style={{ width: "60%" }} /> */}
                            <tr>
                                <td className="th" style={{ width: "80px" }}>
                                    {this.props.response_search_list[rowIndex].request === 2 ? "" : <span className="title">TEL</span>}
                                </td>
                                <td>
                                    {this.props.response_search_list[rowIndex].request === 2 ? "" : this.props.response_search_list[rowIndex].tel1}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    {this.props.response_search_list[rowIndex].request === 2 ? "" : <span className="title">メール</span>}
                                </td>
                                <td className="">
                                    {this.props.response_search_list[rowIndex].request === 2 ? "" : this.props.response_search_list[rowIndex].email1}
                                </td>
                                {/* <td className="bottom" style={{ padding: 0 }}>
                                        {this.props.response_search_list[rowIndex].request === 2 ? "" : <IconButton size="small" style={{ float: "right" }}><EmailIcon style={{ color: "#007bff" }}></EmailIcon></IconButton>}
                                    </td> */}
                            </tr>
                            <tr>

                                <td className="th bottom">
                                    {this.props.response_search_list[rowIndex].request === 1 ? <span className="title">連絡方法</span> : this.props.response_search_list[rowIndex].request === 2 || this.props.response_search_list[rowIndex].request === 3 ? "" : <span className="title">担当 反/来</span>}

                                </td>
                                <td className="bottom">
                                    {this.props.response_search_list[rowIndex].request === 1 ? this.props.response_search_list[rowIndex].main_media_name : this.props.response_search_list[rowIndex].request === 2 || this.props.response_search_list[rowIndex].request === 3 ? "" : <span style={{ fontWeight: "bold" }}>
                                        {this.props.response_search_list[rowIndex].request === 0 && this.props.formData.condition_radio == 1 && this.props.response_search_list[rowIndex].contract_user_id > 0 ? this.props.users.filter(a=>a.id == this.props.response_search_list[rowIndex].contract_user_id)[0].nickname : 
                                        // this.props.response_search_list[rowIndex].primary_user_name
                                            (this.props.response_search_list[rowIndex].user_name ? this.props.response_search_list[rowIndex].user_name : "") + "/" + (this.props.response_search_list[rowIndex].coming_user_name ? this.props.response_search_list[rowIndex].coming_user_name : "")
                                        }
                                        </span>}


                                </td>
                            </tr>
                        </table>
                    </Cell>)} width={this.state.columnWidths.column3} />
                    <Column isResizable={true} columnKey="column4" header={<Cell>反響内容</Cell>} cell={({ rowIndex, ...props }) => (<Cell name="table" className={this.props.response_search_list[rowIndex].request == 1 ? "pink" :
                        this.props.response_search_list[rowIndex].request == 2 ? "green" :
                            this.props.response_search_list[rowIndex].request == 3 ? "lightblue" :
                                this.props.response_search_list[rowIndex].receive_mail_at ? "yellow" :
                                    this.props.response_search_list[rowIndex].receive_line_at ? "yellowgreen" :
                                    this.props.response_search_list[rowIndex].coming_reservation_at || this.props.response_search_list[rowIndex].coming_reservation_cancel_at ? "purple" :
                                    this.props.response_search_list[rowIndex].favorite_set_at ? "orange" :
                                    this.props.response_search_list[rowIndex].condition_set_at ? "blue" :
                                    this.props.response_search_list[rowIndex].mypage_inquiry_at ? "red" :
                                        this.props.response_search_list[rowIndex].end_response_date != null ? "gray" : ""}>
                        <table className="in_table">
                            {/* <colgroup style={{ width: "40%" }} />
                                    <colgroup style={{ width: "60%" }} /> */}
                            <tr>
                                <td className="th">
                                    {this.props.response_search_list[rowIndex].request === 1 || this.props.response_search_list[rowIndex].request === 3 ? <span className="title">引越予定</span> : this.props.response_search_list[rowIndex].request === 2 ? "" : <span className="title">媒体</span>}
                                </td>
                                <td>
                                    {this.props.response_search_list[rowIndex].request === 1 || this.props.response_search_list[rowIndex].request === 3 ? this.props.response_search_list[rowIndex].mypage_request_moving_time == 1 ? "今月中" :
                                        this.props.response_search_list[rowIndex].mypage_request_moving_time == 2 ? "来月中" :
                                            this.props.response_search_list[rowIndex].mypage_request_moving_time == 3 ? "2ヶ月後" :
                                                this.props.response_search_list[rowIndex].mypage_request_moving_time == 4 ? "3ヶ月後" :
                                                    this.props.response_search_list[rowIndex].mypage_request_moving_time == 5 ? "4ヶ月後" :
                                                        this.props.response_search_list[rowIndex].mypage_request_moving_time == 6 ? "5ヶ月後" :
                                                            this.props.response_search_list[rowIndex].mypage_request_moving_time == 7 ? "半年後" :
                                                                this.props.response_search_list[rowIndex].mypage_request_moving_time == 8 ? "1年以内" :
                                                                    this.props.response_search_list[rowIndex].mypage_request_moving_time == 9 ? "未定いいのがあれば" : "" : this.props.response_search_list[rowIndex].request === 2 ? "" : this.props.response_search_list[rowIndex].main_media_name}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    {this.props.response_search_list[rowIndex].request === 1 ? "" : this.props.response_search_list[rowIndex].request === 2 ? "" : <span className="title">経路</span>}
                                </td>
                                <td>
                                    {this.props.response_search_list[rowIndex].path_name ? this.props.response_search_list[rowIndex].path_name : ""}
                                </td>
                            </tr>
                            <tr>
                                <td className="th bottom">
                                    {this.props.response_search_list[rowIndex].request === 1 || this.props.response_search_list[rowIndex].request === 2 || this.props.response_search_list[rowIndex].request === 3 ? "" : <span className="title">引越予定</span>}
                                </td>
                                <td className="bottom">
                                    {this.props.response_search_list[rowIndex].request === 1 || this.props.response_search_list[rowIndex].request === 2 || this.props.response_search_list[rowIndex].request === 3 ? "" : this.props.response_search_list[rowIndex].request_moving_time == 1 ? "今月中" :
                                        this.props.response_search_list[rowIndex].request_moving_time == 2 ? "来月中" :
                                            this.props.response_search_list[rowIndex].request_moving_time == 3 ? "2ヶ月後" :
                                                this.props.response_search_list[rowIndex].request_moving_time == 4 ? "3ヶ月後" :
                                                    this.props.response_search_list[rowIndex].request_moving_time == 5 ? "4ヶ月後" :
                                                        this.props.response_search_list[rowIndex].request_moving_time == 6 ? "5ヶ月後" :
                                                            this.props.response_search_list[rowIndex].request_moving_time == 7 ? "半年後" :
                                                                this.props.response_search_list[rowIndex].request_moving_time == 8 ? "1年以内" :
                                                                    this.props.response_search_list[rowIndex].request_moving_time == 9 ? "未定いいのがあれば" : ""}


                                    {/* {this.props.response_search_list[rowIndex].request === 1 ? "" :this.props.response_search_list[rowIndex].request === 2 ? "" : ""} */}
                                </td>
                            </tr>
                        </table>
                    </Cell>)} width={this.state.columnWidths.column4} />
                    {/* <Column header={<Cell>備考</Cell>} cell={({ rowIndex, ...props }) => (<Cell name="table" className={this.props.response_search_list[rowIndex].request == 1 ? "pink" :
                        this.props.response_search_list[rowIndex].request == 2 ? "green" :
                        this.props.response_search_list[rowIndex].request == 3 ? "lightblue" :
                            this.props.response_search_list[rowIndex].status == 1 ? "yellow" :
                                this.props.response_search_list[rowIndex].status == 12 ? "yellowgreen" : ""}>
                        <table className="in_table">
                            <colgroup style={{ width: "100%" }} />
                            <tr>
                                <td></td>
                            </tr>
                            <tr>

                                <td>

                                    {this.props.response_search_list[rowIndex].status === 1 || this.props.response_search_list[rowIndex].status === 12 ? <Button size="small" variant="contained" color="secondary" style={{ float: "right", color: "white", backgroundColor: "#007bff" }} onClick={() => this.togglemailtaiou(this.props.response_search_list[rowIndex].id)}>確認済みにする</Button> : ""}

                                </td>
                            </tr>
                            <tr>
                                <td className="bottom" style={{ textAlign: "right", padding: 0 }}>
                                    {this.props.response_search_list[rowIndex].request === 1 ? <Button size="small" variant="contained" color="secondary" style={{ float: "right", color: "white", backgroundColor: "#5cb85c" }} onClick={() => this.matching(this.props.response_search_list[rowIndex])}>マッチング</Button> : ""}



                                    {this.props.response_search_list[rowIndex].request === 2 ? <Button size="small" variant="contained" color="secondary" style={{ float: "right", color: "white" }} onClick={() => this.toggle_line_delete({ line_user_id: this.props.response_search_list[rowIndex].id, line_account_section_id: this.props.response_search_list[rowIndex].line_account_section_id })} >削除</Button> : ""}
                                    {this.props.response_search_list[rowIndex].request === 3 ? <Button size="small" variant="contained" color="secondary" style={{ float: "right", color: "white" }} onClick={() => this.toggle_card_delete({ rent_hearing_sheet_id: this.props.response_search_list[rowIndex].rent_hearing_sheet_id })} >削除</Button> : ""}
                                    
                                    {this.props.response_search_list[rowIndex].request !== 1 && this.props.response_search_list[rowIndex].request !== 2 && this.props.response_search_list[rowIndex].request !== 3 ? <IconButton size="small" style={{ float: "right" }} onClick={() => this.open_customer_window(this.props.response_search_list[rowIndex])}><WebIcon style={{ color: "#007bff" }}></WebIcon></IconButton> : ""}
                                    {this.props.response_search_list[rowIndex].request !== 1 && this.props.response_search_list[rowIndex].request !== 2 && this.props.response_search_list[rowIndex].request !== 3 ? <Button size="small" variant="contained" color="secondary" style={{ float: "right", color: "white", backgroundColor: "#5cb85c" }} onClick={() => this.open_response_detail(this.props.response_search_list[rowIndex].id)}>詳細</Button> : ""}

                                </td>
                            </tr>
                        </table>
                    </Cell>)} width={150} /> */}
                </Table>









                <Modal isOpen={this.state.modalmail} toggle={this.toggleMatching} size="lg" fade={false}>

                    <ModalHeader toggle={this.togglemail}>メール詳細</ModalHeader>
                    <ModalBody>
                        <MailDetail toggle={this.toggleselect} mailid={this.state.mailid} />
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.props.modalMatching} toggle={() => this.props.change_modalMatching(false)}
                    size="xxl" fade={false}

                >


                    <ModalHeader toggle={() => this.props.change_modalMatching(false)}>メールマッチング</ModalHeader>
                    <ModalBody>
                        <ResponseMatchingGridComponent selectedrow={this.selectedrow} />
                        <br />
                        取り込み反響選択
                        <ResponseMatchingSelectGridComponent setrow={this.state.mailAllReceiveId} />

                    </ModalBody>

                    <ModalFooter>

                        <button className="btn btn-primary float-right" type="button" onClick={this.addResponse}>登録</button>

                        <Button color="secondary" onClick={() => this.props.change_modalMatching(false)}>キャンセル</Button>
                    </ModalFooter>
                </Modal>







                <Modal isOpen={this.props.modalLineMatching} toggle={() => this.props.change_modalLineMatching(false)}
                    size="xxl" fade={false}

                >


                    <ModalHeader toggle={() => this.props.change_modalLineMatching(false)}>LINEマッチング</ModalHeader>
                    <ModalBody>

                        <ResponseLineMatchingGridComponent selectedrow={this.selectedrow} />


                    </ModalBody>

                </Modal>

                <Modal isOpen={this.props.modalCardMatching} toggle={() => this.props.change_modalCardMatching(false)}
                    size="xxl" fade={false}

                >


                    <ModalHeader toggle={() => this.props.change_modalCardMatching(false)}>Web来店カードマッチング</ModalHeader>
                    <ModalBody>

                        <ResponseCardMatchingGridComponent selectedrow={this.selectedrow} />


                    </ModalBody>

                </Modal>












                <Modal isOpen={this.props.modalResponseDetail} toggle={() => this.props.change_modalResponseDetail(false)}
                    size="xxl" fade={false}

                >


                    <ResponseComponent change_modalResponseDetail={(a) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responseid} response_modal={true} />

                </Modal>

                <Modal isOpen={this.state.modalAutoSend} toggle={this.toggleAutoSend}
                    size="xl" fade={false}>


                    <ModalHeader toggle={this.toggleAutoSend}>自動送信予定</ModalHeader>
                    <ModalBody style={{ height: "550px" }}>

                        <AutoSend rentResponseId={this.state.rentResponseId} />

                    </ModalBody>



                </Modal>

                <Modal isOpen={this.state.modalmailtaiou} toggle={this.togglemailtaiou} size="sm" fade={false}>

                    <ModalHeader toggle={this.togglemailtaiou}>確認済みにする</ModalHeader>
                    <ModalBody>
                        確認済みにするとメール・LINE・来店予約受信のアラートが削除されます。
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.mailtaioudelete}>確認済みにする</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.togglemailtaiou}>キャンセル</button>
                    </ModalFooter>
                </Modal>



                < Modal isOpen={this.state.line_delete} toggle={this.toggle_line_delete} fade={false} >

                    <ModalHeader toggle={this.toggle_line_delete}>LINE削除</ModalHeader>
                    <ModalBody>
                        本当にLINEアカウントを削除してもよろしいですか？
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={() => this.line_delete()}>削除</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggle_line_delete}>キャンセル</button>
                    </ModalFooter>
                </Modal >

                < Modal isOpen={this.state.card_delete} toggle={this.toggle_card_delete} fade={false} >

                    <ModalHeader toggle={this.toggle_card_delete}>Web来店カード削除</ModalHeader>
                    <ModalBody>
                        本当にWeb来店カード情報を削除してもよろしいですか？
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={() => this.card_delete()}>削除</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggle_card_delete}>キャンセル</button>
                    </ModalFooter>
                </Modal >

                <Modal isOpen={this.state.attention_disp} toggle={()=>this.setState({ attention_disp: false })}
                    size="xxl" fade={false}

                >


                    <ModalHeader toggle={()=>this.setState({ attention_disp: false })}>信用情報</ModalHeader>
                    <ModalBody>

                        <CustomerAttentionList grid_width={window.innerWidth-100} />


                    </ModalBody>

                </Modal>
            </div >
        );
    }


}



const mapStateToProps = state => {
    return {

        users: state.masterReducer.users,
        user_details: state.masterReducer.user_details,

        line_user_details: state.line.line_user_details,
        shops: state.masterReducer.shops,
        response_search_list: state.responselist.response_search_list,
        formData: state.responselist.formData,
        modalLineMatching: state.responselist.modalLineMatching,
        modalCardMatching: state.responselist.modalCardMatching,
        modalMatching: state.responsesReducer.modalMatching,
        response_matching_list: state.responsesReducer.response_matching_list,
        response_matching_select_list: state.responsesReducer.response_matching_select_list,
        response_matching_list_loading: state.responsesReducer.response_matching_list_loading,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        responseList(state) {
            dispatch(ResponseList.responseList(state))
        },
        change_formData(state) {
            dispatch(ResponseList.change_formData(state))
        },
        change_modalResponseDetail(state) {
            dispatch(ResponseDetail.change_modalResponseDetail(state))
        },
        change_responseid(state) {
            dispatch(ResponseDetail.change_responseid(state))
        },

        get_response_matching_list(state) {
            dispatch(ResponseList.get_response_matching_list(state))
        },
        get_response_matching_select_list(state) {
            dispatch(ResponseList.get_response_matching_select_list(state))
        },
        change_response_matching_list_loading(state) {
            dispatch(ResponseList.change_response_matching_list_loading(state))
        },
        change_response_matching_select_list_loading(state) {
            dispatch(ResponseList.change_response_matching_select_list_loading(state))
        },
        merge_response_mails(state) {
            dispatch(Responses.merge_response_mails(state))
        },
        change_modalMatching(state) {
            dispatch(Responses.change_modalMatching(state))
        },
        change_modalLineMatching(state) {
            dispatch(ResponseList.change_modalLineMatching(state))
        },


        get_line_open(state) {
            dispatch(Line.get_line_open(state))
        },
        get_line_user_details(state) {
            dispatch(Line.get_line_user_details(state))
        },
        delete_line_user(state) {
            dispatch(Line.delete_line_user(state))
        },
        change_line_user_id(state) {
            dispatch(Responses.change_line_user_id(state))
        },
        change_response_add_select_mail_id_list(state) {
            dispatch(Responses.change_response_add_select_mail_id_list(state))
        },

        get_response_line_matching_list(state) {
            dispatch(ResponseList.get_response_line_matching_list(state))
        },
        open_response_add(state) {
            dispatch(Responses.open_response_add(state))
        },
        change_response_add_disp(state) {
            dispatch(Responses.change_response_add_disp(state))
        },
        change_status(state) {
            dispatch(ResponseList.change_status(state))
        },
        customerNewDispSet(state) {
            dispatch(Customers.customerNewDispSet(state))
        },

        get_response_card_matching_list(state) {
            dispatch(ResponseList.get_response_card_matching_list(state))
        },
        change_modalCardMatching(state) {
            dispatch(ResponseList.change_modalCardMatching(state))
        },
        change_hearing_sheet_id(state) {
            dispatch(Responses.change_hearing_sheet_id(state))
        },
        delete_card(state) {
            dispatch(ResponseList.delete_card(state))
        },

        get_rent_m_medea(state) {
            dispatch(Responses.get_rent_m_medea(state))
        },
        get_rent_m_reasons(state) {
            dispatch(Responses.get_rent_m_reasons(state))
        },
        get_rent_m_schools(state) {
            dispatch(Responses.get_rent_m_schools(state))
        },
        get_rent_m_jobs(state) {
            dispatch(Responses.get_rent_m_jobs(state))
        },
        change_response_search_list(state) {
            dispatch(ResponseList.change_response_search_list(state))
        },
        change_response_attention_disp(state) {
            dispatch(Responses.change_response_attention_disp(state))
        },
        get_attentions_matching_list(state) {
            dispatch(Customers.get_attentions_matching_list(state))
          },

    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponseListTableComponent));
// export default withRouter(connect(mapStateToProps, mapDispatchToProps,appElement)(ResponseListGridComponent));
