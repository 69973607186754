import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as Contacts from './Saga/CustomerContacts';
// import * as Responses from '../../Store/Responses';
import '../../Css/Grid.css';
import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment-timezone';
// import ReactDataGrid from "react-data-grid"
const ReactDataGrid = require('react-data-grid');

// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {
  customer_contacts: any,
  rent_response_id: number,
  get_rent_customer_contacts: any,
  toggle: any,
  toggle_calendar: any,
  contacts_loading: number,
  responsedetail: any
}


interface State {

  // state types
}

// const DateFormatter = (payload: any) => {

//     if (payload.row.start_at) {
//       // return payload.value;
//       if (moment(payload.row.start_at).utc().format("HH") === "00" && moment(payload.row.start_at).utc().format("mm") === "00") {
//         return moment(payload.row.start_at).utc().format("YYYY/MM/DD");
//       } else {
//         return moment(payload.row.start_at).utc().format("YYYY/MM/DD HH:mm");
//       }
//     } else if (payload.value) {
//       // return payload.value;
//       if (moment(payload.value).utc().format("HH") === "00" && moment(payload.value).utc().format("mm") === "00") {
//         return moment(payload.value).utc().format("YYYY/MM/DD");
//       } else {
//         return moment(payload.value).utc().format("YYYY/MM/DD HH:mm");
//       }
//     } else {
//       return "";
//     }

// };
const DateFormatter = ({ value, ...props }:any) => {
  if (props.row.start_at){
    if (moment(props.row.start_at).utc().format("HH") === "00" && moment(props.row.start_at).utc().format("mm") === "00") {
      return moment(props.row.start_at).utc().format("YYYY/MM/DD");
    } else {
      return moment(props.row.start_at).utc().format("YYYY/MM/DD HH:mm");
    }
  }else if (value) {
    // return payload.value;
    if (moment(value).utc().format("HH") === "00" && moment(value).utc().format("mm") === "00") {
      return moment(value).utc().format("YYYY/MM/DD");
    } else {
      return moment(value).utc().format("YYYY/MM/DD HH:mm");
    }
  } else {
    return "";
  }
};
// "TEL".
const WayFormatter = ({ value, ...props }:any) => {

  if (value == -1) {
    return "来店キャンセル";
  } else if (value == 1) {
    return "電話";
  } else if (value == 2) {
    return "メール";
  } else if (value == 3) {
    if(props.row.intend == 2){
      return "来店ｷｬﾝｾﾙ";
    }else{
      return "来店";
    }
    
  } else if (value == 4) {
    return "LINE";
  } else if (value == 5) {
    return "問合せ（マイページ）";

  } else if (value == 7) {
    return "事前準備";
  } else if (value == 8) {
    return "物件確認";
  } else if (value == 9) {
    return "動画撮影";
  } else if (value == 10) {
    return "成約済み";

  } else {
    return "";
  }
};

const RemarksFormatter = (payload: any) => {

  let remarks = payload.row.remarks

  // ラインでブロックされている場合に自動送信しようとした場合。
  if(payload.row.line_blocked_at){
    remarks = (remarks ? remarks + " " : "") + "ブロック済のため未送信"
  }else if(payload.row.end_response_no_send_at){
    remarks = (remarks ? remarks + " " : "") + "追客終了のため未送信"
  }
  

  return remarks
};

const telActions = [
  {
    text: "電話",
    icon: <span style={{ color: "green" }} className="fas fa-phone" />,
    // callback: () => {
    //   alert("Deleting");
    // }
  },

];
const comingActions = [
  {
    text: "来店",
    icon: <span style={{ color: "orange" }} className="fas fa-shoe-prints" />,
    // callback: () => {
    //   alert("Deleting");
    // }
  },

];
const mailActions = [
  {
    text: "メール",
    icon: <span className="fas fa-envelope" />,

    // callback: () => {
    //   alert("Deleting");
    // }
  },

];
const lineActions = [
  {
    text: "LINE",
    icon: <span style={{ color: "green" }} className="fab fa-line" />,

    // callback: () => {
    //   alert("Deleting");
    // }
  },

];
function getCellActions(column: any, row: any) {
  console.log(row.way)

  const cellActions = {
    tel: telActions,
    mail: mailActions,
    coming: comingActions,
    line: lineActions,
  };
  if (column.key == "way") {
    if (row.way == 1) {
      return cellActions["tel"];
    } else if (row.way == 2) {
      return cellActions["mail"];
    } else if (row.way == 3) {
      return cellActions["coming"];
    } else if (row.way == 4) {
      return cellActions["line"];
    }
  } else {
    return "";
  }
}



const columns = [

  {
    key: 'date', name: '対応日', width: 140, resizable: true, formatter: DateFormatter
  },
  { key: 'way', name: '対応方法', width: 90, resizable: true, formatter: WayFormatter },
  { key: 'user_name', name: '担当', width: 70, resizable: true },
  { key: 'remarks', name: '備考', width: 250, resizable: true, formatter: RemarksFormatter },
  // { key: 'mail_all_receive_id', name: '備考', width: 250, resizable: true },

  // { key: 'id', name: 'ID', resizable: true },
  // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
];



const RowRenderer = ({ renderBaseRow, ...props }: any) => {
  console.log(props.row.intend)

  const backgroundColor = (props.row.intend === 1 || props.row.intend === true) ? "row-highlight-green" : (props.row.line_blocked_at || props.row.end_response_no_send_at || props.row.intend == 2) ? "row-highlight-gray" : "";
  return <span className={backgroundColor}>{renderBaseRow(props)}</span>;
};



// function getCellActions(column, row) {
//     //const cellActions = {
//     //    firstName: firstNameActions
//     //};
//     console.log(column)
//     //return row.id % 2 === 0 ? cellActions[column.key] : null;
// }

class ContactsGridComponent extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);


  }
  componentWillMount() {

    // this.props.get_rent_customer_contacts(this.props.rent_response_id)
    // this.props.customerList()

  }
  getSelectedCell(event: any) {
    alert()
  }

  onRowClick = (rowIdx: any, row: any) => {

    // if (this.props.customerno === 0) {
    //     this.props.change_customerloding0(0)
    //     let customerdetail: { [key: string]: number; } = {};
    //     customerdetail.customerid=row.id;
    //     customerdetail.no=0;
    //     this.props.customerdetailData(customerdetail)
    //     //this.props.responseUpdate(this.props.responsedetail);
    // }else if (this.props.customerno === 1) {
    //     this.props.change_customerloding1(0)
    //     this.props.responsedetail.rentCustomerId = row.id;
    //     this.props.responseUpdate(this.props.responsedetail);

    // } else if (this.props.customerno === 2) {
    //     this.props.change_customerloding2(0)
    //     this.props.responsedetail.rentCustomerId2 = row.id;
    //     this.props.responseUpdate(this.props.responsedetail);

    // }
    if (row) {
// console.log("aaaaaaaa")
// console.log(row)
      this.props.toggle_calendar(row);
      // this.props.toggle(row);
    }



  }

  EmptyRowsView = () => {
    const message = "検索結果がありません。";

    return (
      this.props.contacts_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", height: 250, padding: "100px" }} > <h3>{message}</h3></div>

    );
  };

  render() {
    // console.log(this.props.customerlists)
    // alert(this.props.customerlists+"sss")
    // const ss:any=[];
    return (
      <div>

        <ReactDataGrid
          // ref={node => this.grid = node}
          enableCellSelect={false}
          columns={columns}
          rowGetter={(i: number) => this.props.customer_contacts[i]}
          rowRenderer={RowRenderer}
          rowsCount={this.props.customer_contacts.length}
          headerRenderer={true}
          dragable={true}
          getCellActions={getCellActions}

          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          emptyRowsView={this.EmptyRowsView}
          onRowClick={this.onRowClick}
          //onCellSelected={this.getSelectedCell}
          minHeight={300} />
      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {
    // customer_contacts: state.customerContactsReducer.customer_contacts,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    responsedetail: state.responsesReducer.responsedetail,
    contacts_loading: state.customerContactsReducer.contacts_loading
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    get_rent_customer_contacts(state: any) {
      dispatch(Contacts.get_rent_customer_contacts(state))
    },
    // responseUpdate(state:any) {
    //     dispatch(Responses.responseUpdate(state))
    // },
    // change_customerloding0(state:number) {
    //     dispatch(Customers.change_customerloding0(state))
    // },
    // change_customerloding1(state:number) {
    //     dispatch(Customers.change_customerloding1(state))
    // },
    // change_customerloding2(state:number) {
    //     dispatch(Customers.change_customerloding2(state))
    // },
    // customerdetailData(state:any) {
    //     dispatch(Customers.customerDetail(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsGridComponent);