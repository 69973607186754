
const authentication = require('../../../react-azure-adb2c2').default;

var moment = require('moment-timezone');


export const list = (values: any) => {


  // let url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContacts/` + values;
  // const url = `http://localhost:5000/v1/rent/customer/contacts/list/` + values;

  const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/customer/contacts/list/` + values;//これ
  // alert(url)

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    // body: JSON.stringify(search),

    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));

};














export const detail = (Id: number) => {

  const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContacts/` + Id;

  const token = authentication.getAccessToken();

  return fetch(url, {
    headers: { 'Authorization': 'Bearer ' + token }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));


}


export const updatetime = (values: any) => {


  const token = authentication.getAccessToken();
  const url = `http://localhost:5000/v1/rent/customer/contacts/updatetime`;


  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  })
    .then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));


}

export const update = (values: any) => {
  values.rent_customer_contacts.intend = values.rent_customer_contacts.intend == true ? 1 : 0;
  values.rent_customer_contacts.going = values.rent_customer_contacts.going == true ? 1 : values.rent_customer_contacts.going;
  // alert(values.rentMResponseStatusId)
  //     const date = new Date(values.date);
  // const year = date.getFullYear();
  // const month = date.getMonth() + 1;
  // const day = date.getDate();
  console.log(values)
  // alert()
  // values.date = year + '/' + month + '/' + day

  // let H = 0;
  // let m = 0;
  // if (values.rent_customer_contacts.intend_time == 1) {
  //   H = 0;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 2) {
  //   H = 0;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 3) {
  //   H = 1;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 4) {
  //   H = 1;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 5) {
  //   H = 2;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 6) {
  //   H = 2;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 7) {
  //   H = 3;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 8) {
  //   H = 3;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 9) {
  //   H = 4;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 10) {
  //   H = 4;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 11) {
  //   H = 5;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 12) {
  //   H = 5;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 13) {
  //   H = 6;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 14) {
  //   H = 6;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 15) {
  //   H = 7;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 16) {
  //   H = 7;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 17) {
  //   H = 8;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 18) {
  //   H = 8;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 19) {
  //   H = 9;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 20) {
  //   H = 9;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 21) {
  //   H = 10;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 22) {
  //   H = 10;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 23) {
  //   H = 11;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 24) {
  //   H = 11;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 25) {
  //   H = 12;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 26) {
  //   H = 12;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 27) {
  //   H = 13;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 28) {
  //   H = 13;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 29) {
  //   H = 14;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 30) {
  //   H = 14;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 31) {
  //   H = 15;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 32) {
  //   H = 15;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 33) {
  //   H = 16;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 34) {
  //   H = 16;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 35) {
  //   H = 17;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 36) {
  //   H = 17;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 37) {
  //   H = 18;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 38) {
  //   H = 18;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 39) {
  //   H = 19;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 40) {
  //   H = 19;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 41) {
  //   H = 20;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 42) {
  //   H = 20;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 43) {
  //   H = 21;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 44) {
  //   H = 21;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 45) {
  //   H = 22;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intendTime == 46) {
  //   H = 22;
  //   m = 30;
  // } else if (values.rent_customer_contacts.intend_time == 47) {
  //   H = 23;
  //   m = 0;
  // } else if (values.rent_customer_contacts.intend_time == 48) {
  //   H = 23;
  //   m = 30;
  // }

  // const date = new Date(values.rent_customer_contacts.date);
  // values.date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), H, m);

  // // values.rent_customer_contacts.date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), H, m);
  // alert(values.rent_customer_contacts.date)

  values.rent_customer_contacts.date = moment(values.rent_customer_contacts.date).format("YYYY/MM/DD HH:mm");
  // alert(values.rent_customer_contacts.date)
  // values.date.setTime(values.date.getTime() + 1000*60*60*9);// JSTに変換
  // alert(values.rent_customer_contacts.intend_time)
  // alert(values.date)

  const token = authentication.getAccessToken();
  // const url = `http://localhost:5000/v1/rent/customer/contacts/updatenew`;
  const url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/customer/contacts/updatenew` //これ

  // alert(JSON.stringify(values))

  // alert(url)
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  })
    .then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));


}

export function contact_delete(id: number) {


  const token = authentication.getAccessToken();

  // const url = `http://localhost:5000/v1/rent/customer/contacts/delete/` + id;
  const url = process.env.REACT_APP_API_CRM_DELETE_URL + `/v1/rent/customer/contacts/delete/` + id;//これ

  // alert(url)
  return fetch(url, {
    method: 'get',

    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  })
    .then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));


}

export const auto_send_all = (values: any) => {
  // alert(values)
  
    let url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/customer/contacts/insertauto`
    // const url = `http://localhost:5000/v1/rent/customer/contacts/insertauto`;
  
    const token = authentication.getAccessToken();
  
    return fetch(url, {
      method: 'post',
      body: JSON.stringify(values),
  
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  
    }).then(res => res.json())
      .then(payload => ({ payload }))
      .catch(error => ({ error }));
  
  };

// export const auto_send_all = (values: any) => {
// // alert(values)

//   // let url = process.env.REACT_APP_API_CRM_CONTACS_URL + `/v1/rent/customer/contacts/insertauto/` + values;
//   const url = `http://localhost:5000/v1/rent/customer/contacts/insertauto/` + values;

//   const token = authentication.getAccessToken();

//   return fetch(url, {
//     method: 'get',
//     // body: JSON.stringify(search),

//     headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//   }).then(res => res.json())
//     .then(payload => ({ payload }))
//     .catch(error => ({ error }));

// };