
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';


import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import * as rent_response_docs_api from '../Api/rent_response_docs_api';
import * as rent_application_docs_api from '../Api/rent_application_docs_api';
import * as rent_contract_docs_api from '../Api/rent_contract_docs';
import * as rent_limited_public_pages_api from '../Api/rent_limited_public_pages_api';
import * as rent_limited_public_docs_api from '../Api/rent_limited_public_docs_api';
import * as rent_response_contract_docs_api from '../Api/rent_response_contract_docs_api';

// import * as RentIntroductionFilesApi from '../Api/RentIntroductionFilesApi';
import * as rent_files_api from '../Api/rent_files_api';

import * as Contracts from '../../Contracts/Saga/Contracts';

export interface RoomsState {
  rent_response_docs: any;
  application_files: any;
  blob_application_files: any;
  public_files: any;
  rent_response_files_loading: boolean;
  response_file_data: any;
  contract_file_update_disp: boolean;
  flie_loading: boolean;
  contract_files: any;
  contract_files_all: any;
  contract_files_all_disp: boolean;
  file_tab: any;
  rent_limited_public_pages_list: any;
  rent_limited_public_pages: any;
  rent_limited_public_docs_list: any;
  // rent_limited_public_page_id: any;
  rent_limited_public_docs_update_disp: boolean;

  rent_response_contract_docs: any;
}
const initialState: RoomsState = {

  rent_response_docs: [],
  application_files: [],
  blob_application_files: [],
  public_files: [],
  contract_files: [],
  contract_files_all: [],
  contract_files_all_disp: false,
  rent_response_files_loading: false,
  response_file_data: null,
  contract_file_update_disp: false,
  flie_loading: false,

  file_tab: "f1",
  rent_limited_public_pages_list: [],
  rent_limited_public_pages: [],
  rent_limited_public_docs_list: [],
  // rent_limited_public_page_id: 0,
  rent_limited_public_docs_update_disp: false,

  rent_response_contract_docs: [],
}



///action 
const actionCreator = actionCreatorFactory();


// export const get_rent_response_docs = actionCreator('GET_RENT_RESPONSE_FILES');
export const change_rent_response_docs = actionCreator<any>("CHANGE_RENT_RESPONSE_DOCS");
// export const change_contract_files = actionCreator<any>("CHANGE_CONTRACT_FILES");
export const change_contract_files_loading = actionCreator<boolean>('CHANGE_CONTRACT_FILES_LOADING');
// export const change_contract_file_update_disp = actionCreator<boolean>('CHANGE_CONTRACT_FILE_UPDATE_DISP');
export const download_rent_response_file = actionCreator('DOWNLOAD_RENT_RESPONSE_FILE');

export const get_files = actionCreator<number>('GET_FILES');
export const change_files = actionCreator('CHANGE_FILES');
// export const change_public_files = actionCreator<any>('CHANGE_PUBLIC_FILES');
export const change_application_files = actionCreator<any>('CHANGE_APPLICATION_FILES');

export const change_flie_loading = actionCreator<boolean>('CHANGE_FILE_LOADING');


export const change_contract_files = actionCreator<boolean>('CHANGE_CONTRACT_FILES');
export const download_disp_contract_files = actionCreator<boolean>('DOWNLOAD_DISP_CONTRACT_FILES');
export const download_contract_files = actionCreator<boolean>('DOWNLOAD_CONTRACT_FILES');
export const change_contract_files_all = actionCreator<boolean>('CHANGE_CONTRACT_FILES_All');
export const change_contract_files_all_disp = actionCreator<boolean>('CHANGE_CONTRACT_FILES_All_DISP');

export const download_disp_application_files = actionCreator<boolean>('DOWNLOAD_DISP_APPLICATION_FILES');
export const download_application_files = actionCreator<boolean>('DOWNLOAD_APPLICATION_FILES');
export const get_blob_application_files = actionCreator<any>('GET_BLOB_APPLICATION_FILES');
export const change_blob_application_files = actionCreator<any>('CHANGE_BLOB_APPLICATION_FILES');

export const download_disp_rent_response_docs = actionCreator<boolean>('DOWNLOAD_DISP_RENT_RESPONSE_DOCS');
export const download_rent_response_docs = actionCreator<boolean>('DOWNLOAD_RENT_RESPONSE_DOCS');

export const change_contract_file_data = actionCreator<any>('CHANGE_CONTRACT_FILE_DATA');
export const change_contract_file_remarks = actionCreator<any>('CHANGE_CONTRACT_FILE_REMARKS');
export const change_contract_file_contractdoc = actionCreator<any>('CHANGE_CONTRACT_FILE_CONTRACTDOC');

export const upload_contract_file = actionCreator('UPLOAD_CONTRACT_FILE');
export const edit_contract_file_remarks = actionCreator('EDIT_CONTRACT_FILE_REMARKS');
export const edit_contract_file_contractdoc = actionCreator('EDIT_CONTRACT_FILE_CONTRACTDOC');

export const delete_contract_file = actionCreator('DELETE_CONTRACT_FILE');

export const change_contract_file_update_disp = actionCreator<boolean>('CHANGE_CONTRACT_FILE_UPDATE_DISP');

export const change_file_tab = actionCreator<any>('CHANGE_FILE_TAB');

export const get_rent_limited_public_pages_list = actionCreator<number>('GET_RENT_LIMITED_PUBLIC_PAGES_LIST');
export const change_rent_limited_public_pages_list = actionCreator('CHANGE_RENT_LIMITED_PUBLIC_PAGES_LIST');
export const change_rent_limited_public_pages = actionCreator('CHANGE_RENT_LIMITED_PUBLIC_PAGES');
export const update_rent_limited_public_pages = actionCreator('UPDATE_RENT_LIMITED_PUBLIC_PAGES');
// export const change_rent_limited_public_page_id = actionCreator('CHANGE_RENT_LIMITED_PUBLIC_PAGE_ID');
export const add_rent_limited_public_pages = actionCreator('ADD_RENT_LIMITED_PUBLIC_PAGES');

export const get_rent_limited_public_docs_list = actionCreator<number>('GET_RENT_LIMITED_PUBLIC_DOCS_LIST');
export const change_rent_limited_public_docs_list = actionCreator('CHANGE_RENT_LIMITED_PUBLIC_DOCS_LIST');
export const upload_limited_public_doc = actionCreator('UPLOAD_LIMITED_PUBLIC_DOC');
export const edit_limited_public_doc_remarks = actionCreator('EDIT_LIMITED_PUBLIC_DOC_REMARKS');
export const edit_limited_public_doc_contractdoc = actionCreator('EDIT_LIMITED_PUBLIC_DOC_CONTRACTDOC');

export const download_rent_limited_public_docs = actionCreator<boolean>('DOWNLOAD_RENT_LIMITED_PUBLIC_DOCS');
export const change_rent_limited_public_docs_update_disp = actionCreator<boolean>('CHANGE_RENT_LIMITED_PUBLIC_DOCS_UPDATE_DISP');
export const delete_rent_limited_public_docs = actionCreator('DELETE_RENT_LIMITED_PUBLIC_DOCS');
export const download_disp_limited_public_docs = actionCreator<boolean>('DOWNLOAD_DISP_LIMITED_PUBLIC_DOCS');

export const change_rent_response_contract_docs = actionCreator<any>("CHANGE_RENT_RESPONSE_CONTRACT_DOCS");
export const download_rent_response_contract_docs = actionCreator<boolean>('DOWNLOAD_RENT_RESPONSE_CONTRACT_DOCS');
export const download_disp_rent_response_contract_docs = actionCreator<boolean>('DOWNLOAD_DISP_RENT_RESPONSE_CONTRACT_DOCS');

export const filesReducer = reducerWithInitialState(initialState)
  // .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))
  // .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))
  .case(change_rent_response_docs, (state, payload) => ({ ...state, rent_response_docs: payload }))

  // .case(change_contract_files, (state, payload) => ({ ...state, contract_files: payload }))
  .case(change_contract_files_loading, (state, payload) => ({ ...state,contract_files_loading: payload }))
  // .case(change_contract_file_update_disp, (state, payload) => ({ ...state, contract_file_update_disp: payload }))

  .case(change_contract_file_data, (state, payload) => ({ ...state, contract_file_data: payload }))
  .case(change_contract_file_remarks, (state, payload) => ({ ...state, contract_file_remarks: payload }))
  .case(change_contract_file_contractdoc, (state, payload) => ({ ...state, contract_file_contractdoc: payload }))
  .case(change_contract_file_update_disp, (state, payload) => ({ ...state, contract_file_update_disp: payload }))

  // .case(change_public_files, (state, payload) => ({ ...state, public_files: payload }))

  .case(change_application_files, (state, payload) => ({ ...state, application_files: payload }))
  .case(change_blob_application_files, (state, payload) => ({ ...state, blob_application_files: payload }))

  .case(change_flie_loading, (state, payload) => ({ ...state, flie_loading: payload }))

  .case(change_contract_files, (state, payload) => ({ ...state, contract_files: payload }))
  .case(change_contract_files_all, (state, payload) => ({ ...state, contract_files_all: payload }))
  .case(change_contract_files_all_disp, (state, payload) => ({ ...state, contract_files_all_disp: payload }))

  .case(change_file_tab, (state, payload) => ({ ...state, file_tab: payload }))
  .case(change_rent_limited_public_pages_list, (state, payload) => ({ ...state, rent_limited_public_pages_list: payload }))
  .case(change_rent_limited_public_pages, (state, payload) => ({ ...state, rent_limited_public_pages: payload }))
  // .case(change_rent_limited_public_page_id, (state, payload) => ({ ...state, rent_limited_public_page_id: payload }))
  .case(change_rent_limited_public_docs_list, (state, payload) => ({ ...state, rent_limited_public_docs_list: payload }))
  .case(change_rent_limited_public_docs_update_disp, (state, payload) => ({ ...state, rent_limited_public_docs_update_disp: payload }))

  .case(change_rent_response_contract_docs, (state, payload) => ({ ...state, rent_response_contract_docs: payload }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const filesSaga = [
  takeEvery('GET_RENT_RESPONSE_FILES', handle_get_rent_response_files),
  takeEvery('DOWNLOAD_RENT_RESPONSE_FILE', handle_download_rent_response_file),
  takeEvery('UPLOAD_RENT_RESPONSE_FILE', handle_upload_rent_response_file),

  takeEvery('DOWNLOAD_DISP_CONTRACT_FILES', handle_download_disp_contract_files),
  takeEvery('DOWNLOAD_CONTRACT_FILES', handle_download_contract_files),
  takeEvery('DOWNLOAD_DISP_APPLICATION_FILES', handle_download_disp_application_files),
  takeEvery('DOWNLOAD_APPLICATION_FILES', handle_download_application_files),
  takeEvery('GET_BLOB_APPLICATION_FILES', handle_get_blob_application_files),
  takeEvery('DOWNLOAD_DISP_RENT_RESPONSE_DOCS', handle_download_disp_rent_response_docs),
  takeEvery('DOWNLOAD_RENT_RESPONSE_DOCS', handle_download_rent_response_docs),
  takeEvery('DOWNLOAD_DISP_RENT_RESPONSE_CONTRACT_DOCS', handle_download_disp_rent_response_contract_docs),
  takeEvery('DOWNLOAD_RENT_RESPONSE_CONTRACT_DOCS', handle_download_rent_response_contract_docs),

  takeEvery('EDIT_CONTRACT_FILE_REMARKS', handle_edit_contract_file_remarks),


  takeEvery('DELETE_CONTRACT_FILE', handle_delete_contract_file),


  takeEvery('GET_FILES', handle_get_files),

  takeEvery('UPLOAD_CONTRACT_FILE', handle_upload_contract_file),

  takeEvery('GET_RENT_LIMITED_PUBLIC_PAGES_LIST', handle_get_rent_limited_public_pages_list),
  takeEvery('ADD_RENT_LIMITED_PUBLIC_PAGES', handle_add_rent_limited_public_pages),
  takeEvery('UPDATE_RENT_LIMITED_PUBLIC_PAGES', handle_update_rent_limited_public_pages),
  takeEvery('GET_RENT_LIMITED_PUBLIC_DOCS_LIST', handle_get_rent_limited_public_docs_list),
  takeEvery('UPLOAD_LIMITED_PUBLIC_DOC', handle_upload_limited_public_doc),
  takeEvery('DOWNLOAD_RENT_LIMITED_PUBLIC_DOCS', handle_download_rent_limited_public_docs),
  takeEvery('EDIT_LIMITED_PUBLIC_DOC_REMARKS', handle_edit_limited_public_doc_remarks),
  takeEvery('DELETE_RENT_LIMITED_PUBLIC_DOCS', handle_delete_rent_limited_public_docs),
  takeEvery('DOWNLOAD_DISP_LIMITED_PUBLIC_DOCS', handle_download_disp_limited_public_docs),

  

];
// export default masterSaga;





function* handle_get_rent_response_files(action: any) {
  yield put(change_contract_files_loading(true));

  const { payload, error } = yield call(rent_response_docs_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_rent_response_docs(payload));
    yield put(change_contract_files_loading(false));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_download_rent_response_file(action: any) {

  const { payload, error } = yield call(rent_response_docs_api.download, action.payload.id);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    console.log(payload)
    const a = document.createElement('a');
    // ダウンロードされるファイル名
    a.download = action.payload.name;
    // a.download = "10.pdf";
    a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    a.click();
    //    yield put(get_rent_contract_monies_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_upload_rent_response_file(action: any): any {
  yield put(change_contract_files_loading(true));
  const state = yield select();
  console.log(state.responsefiles.response_file_data)
  const { payload, error } = yield call(rent_response_docs_api.upload, { response_id: state.responsesReducer.responseid, file: state.responsefiles.response_file_data, remarks: state.responsefiles.response_file_remarks });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    if(payload.length == 0){
      alert("アップロードに失敗しました。")
    }else{
      yield put(change_rent_response_docs(payload));
    }
    // 成功時アクション呼び出し
    // console.log(payload)
    // yield put(change_rent_response_file_update_disp(false));
    yield put(change_contract_files_loading(false));
    // const a = document.createElement('a');
    // ダウンロードされるファイル名
    // a.download = action.payload.name;
    // a.download = "10.pdf";
    // a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    // a.click();
    //    yield put(get_rent_contract_monies_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    alert("アップロードに失敗しました。")
  }
}

function* handle_edit_contract_file_remarks(action: any): any {
  const state = yield select();
  // console.log(state.responsefiles.response_file_data)


  let api: any
  // alert(state.files.file_tab)
  if(state.files.file_tab == "f1"){// 申込ファイル●
    api = rent_application_docs_api.update
  } else if(state.files.file_tab == "f2"){// 社内ファイル●
    api = rent_response_docs_api.update
  } else if(state.files.file_tab == "f3"){// 公開ファイル
    // api = rent_files_api.download_contracts
  } else if(state.files.file_tab == "f4"){// 契約書添付ファイル●
    // api = rent_contract_docs_api.update_doc
  } else if(state.files.file_tab == "f5"){// 契約書添付●
    api = rent_response_contract_docs_api.update
  }

  const { payload, error } = yield call(api, { 
    fileid: action.payload,
    file: state.files.contract_file_data, 
    remarks: state.files.contract_file_remarks,
    contractdoc: state.files.contract_file_contractdoc,
  });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    if(state.files.file_tab == "f1"){// 申込ファイル●
      let application_files = state.files.application_files.map(function (a: any) {
        if (a.id === action.payload) return { ...a, remarks: state.files.contract_file_remarks,  };
        return a
      })
      yield put(change_application_files(application_files))
    } else if(state.files.file_tab == "f2"){// 社内ファイル●
      let rent_response_docs = state.files.rent_response_docs.map(function (a: any) {
        if (a.id === action.payload) return { ...a, remarks: state.files.contract_file_remarks };
        return a
      })
      yield put(change_rent_response_docs(rent_response_docs));
    } else if(state.files.file_tab == "f3"){// 公開ファイル

    } else if(state.files.file_tab == "f4"){// 契約書添付ファイル●
      // let contracts: any = []
      // payload.rent_contract_docs.map(function (value: any) {
      //   contracts.push({
      //     type: 0,
      //     id: value.id,
      //     rent_contract_id: value.rent_contract_id,
      //     name: value.name,
      //     mimetype: value.mimetype,
      //     extension: value.extension,
      //     is_deleted: value.is_deleted,
      //     created_at: value.created_at,
      //     created_user: value.created_user,

      //   })
      // })
      // payload.rent_contract_documents.map(function (values: any) {
      //   contracts.push({
      //     type: 1,
      //     id: values.id,
      //     rent_contract_id: values.rent_contract_id,
      //     name: values.name,
      //     mimetype: values.mime,
      //     extension: values.mime,
      //     is_deleted: false,
      //     created_at: values.created_at,
      //     created_user: values.created_user_id,

      //   })
      // })
      // yield put(change_contract_files(contracts))
    } else if(state.files.file_tab == "f5"){// 契約書添付●
      let rent_response_contract_docs = state.files.rent_response_contract_docs.map(function (a: any) {
        if (a.id === action.payload) return { ...a, remarks: state.files.contract_file_remarks, contractdoc: state.files.contract_file_contractdoc};
        return a
      })
      yield put(change_rent_response_contract_docs(rent_response_contract_docs));
    }
    
    // 成功時アクション呼び出し
    // console.log(payload)
    yield put(change_contract_file_update_disp(false));
    yield put(change_contract_files_loading(false));
    

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

  // // サーバーからのレスポンスデータによる分岐処理
  // if (payload && !error) {

    

  //   yield put(change_contract_file_update_disp(false));
  //   // 成功時アクション呼び出し
  //   console.log(payload)


  // } else {
  //   //    // 失敗時アクション呼び出し
  //   //    yield put(loginFailure(payload));
  // }


}

function* handle_delete_contract_file(action: any): any {
  const state = yield select();
  
  let api: any
  // alert(state.files.file_tab)
  if(state.files.file_tab == "f1"){// 申込ファイル●
    api = rent_application_docs_api.deletefile
  } else if(state.files.file_tab == "f2"){// 社内ファイル●
    api = rent_response_docs_api.deletefile
  } else if(state.files.file_tab == "f3"){// 公開ファイル
    // api = rent_files_api.download_contracts
  } else if(state.files.file_tab == "f4"){// 契約書添付ファイル●
    api = rent_contract_docs_api.deletefile_doc
  } else if(state.files.file_tab == "f5"){// 契約書添付●
    api = rent_response_contract_docs_api.deletefile
  }

  const { payload, error } = yield call(api, action.payload);

  // const { payload, error } = yield call(rent_response_docs_api.deletefile, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    if(state.files.file_tab == "f1"){// 申込ファイル●
      let application_files = state.files.application_files.filter(function (a: any) {
        if (a && a.id !== action.payload) {
          return a
        }
      })
      yield put(change_application_files(application_files))
    
    } else if(state.files.file_tab == "f2"){// 社内ファイル●
      let rent_response_docs = state.files.rent_response_docs.filter(function (a: any) {
        if (a && a.id !== action.payload) {
          return a
        }
      })
      yield put(change_rent_response_docs(rent_response_docs));
    
    } else if(state.files.file_tab == "f3"){// 公開ファイル

    } else if(state.files.file_tab == "f4"){// 契約書添付ファイル●
      let contract_files = state.files.contract_files.filter(function (a: any) {
        if (!(a && a.id == action.payload && a.type == 0)) {
          return a
        }
      })
      yield put(change_contract_files(contract_files))

      let contract_files_all = state.files.contract_files_all.filter(function (a: any) {
        if (!(a && a.id == action.payload && a.type == 0)) {
          return a
        }
      })
      yield put(change_contract_files_all(contract_files_all))
      

    } else if(state.files.file_tab == "f5"){// 契約書添付●
      let rent_response_contract_docs = state.files.rent_response_contract_docs.filter(function (a: any) {
        if (a && a.id !== action.payload) {
          return a
        }
      })
      yield put(change_rent_response_contract_docs(rent_response_contract_docs));
    }

    yield put(change_contract_file_update_disp(false));
    yield put(change_contract_files_loading(false));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// ファイル取得
function* handle_get_files(action: any) : any {
// alert(action.payload)
  // yield put(change_get_mail_detail_files_loading(1))
  // console.log( action.payload)
  // yield put(get_mail_detail_files_success([]))
  yield put(change_flie_loading(true))

  const state = yield select();
  let rent_contract_details = state.contracts.rent_contract_details

  const { payload, error } = yield call(rent_files_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    console.log(payload.rent_application_docs)

    let contracts: any = []
    if(payload.rent_contract_docs){
      payload.rent_contract_docs.map(function (value: any) {
        contracts.push({
          type: 0,
          id: value.id,
          rent_contract_id: value.rent_contract_id,
          status: 0,
          name: value.name,
          mimetype: value.mimetype,
          extension: value.extension,
          is_deleted: value.is_deleted,
          created_at: value.created_at,
          created_user: value.created_user,

        })
      })
    }

    let max_id = 0
    if(payload.rent_contract_documents){
    payload.rent_contract_documents.map(function (values: any) {
      contracts.push({
        type: 1,
        id: values.id,
        rent_contract_id: values.rent_contract_id,
        status: values.status,
        name: values.name,
        mimetype: values.mime,
        extension: values.mime,
        is_deleted: false,
        created_at: values.created_at,
        created_user: values.created_user_id,

      })
      max_id = values.name == null && max_id < values.id ? values.id : max_id
    })
    }

    contracts.map(function (values: any) {
      if(values.type == 0) {// 添付ファイル
        values.disp = 1
      }else{// 契約書類
        if(values.name == null){ // 紙の契約書
          if(rent_contract_details.cloudsign_completed_at != null){// 電子契約なら普通の賃貸借契約書非表示
            values.disp = 0
          }else{
            if(max_id == values.id){
              values.disp = 1
            }else{
              values.disp = 0
            }
          }
        }else{// 電子契約
          if(values.status == 3){//署名完了
            values.disp = 1
          }else{
            values.disp = 0
          }
        }
      }


      return values
    })


    // //application_files blob取得
    // payload.rent_application_docs.map(function (value: any) {
    // yield put(download_application_files(value))

    //   return value


    // })


    yield put(change_rent_response_docs(payload.rent_response_docs))
    yield put(change_rent_response_contract_docs(payload.rent_response_contract_docs))
    // yield put(change_public_files(payload.rent_public_docs))
    // yield put(change_public_files(payload.rent_limited_public_docs))
    yield put(change_rent_limited_public_docs_list(payload.rent_limited_public_docs));
    yield put(change_application_files(payload.rent_application_docs))
    yield put(change_rent_limited_public_pages_list(payload.rent_limited_public_pages));
    yield put(change_contract_files(contracts.filter((a:any)=>a.disp == 1)))
    yield put(change_contract_files_all(contracts))
    yield put(change_contract_files_all_disp(false))
    yield put(change_flie_loading(false))
    //  yield put(change_get_mail_detail_files_loading(0))
    // yield put(change_file_tab("f2"))
    



  } else {

  }
}

// ファイル保存
function* handle_upload_contract_file(action: any): any {

  yield put(change_contract_files_loading(true));
  const state = yield select();
  console.log(state.files.contract_file_data)
  let rent_contract_details = state.contracts.rent_contract_details

  let api: any
  // alert(state.files.file_tab)
  if(state.files.file_tab == "f1"){// 申込ファイル●
    api = rent_application_docs_api.upload
  } else if(state.files.file_tab == "f2"){// 社内ファイル●
    api = rent_response_docs_api.upload
  } else if(state.files.file_tab == "f3"){// 公開ファイル
    // api = rent_files_api.download_contracts
  } else if(state.files.file_tab == "f4"){// 契約書添付ファイル●
    api = rent_contract_docs_api.upload
  } else if(state.files.file_tab == "f5"){// 契約書添付●
    api = rent_response_contract_docs_api.upload
  }
// alert(state.responsesReducer.responseid)
// alert(state.contracts.rent_contract_id)
  const { payload, error } = yield call(api, { 
    contract_id: state.contracts.rent_contract_id ? state.contracts.rent_contract_id : state.responsesReducer.responsedetail.rent_contract_id, 
    response_id: state.responsesReducer.responseid, 
    file: state.files.contract_file_data, 
    remarks: state.files.contract_file_remarks,
    contractdoc1: parseInt(state.files.contract_file_contractdoc) == 0 ? 1 : 0,
    contractdoc2: parseInt(state.files.contract_file_contractdoc) == 1 ? 1 : 0,
    contractdoc3: parseInt(state.files.contract_file_contractdoc) == 2 ? 1 : 0,
    contractdoc4: parseInt(state.files.contract_file_contractdoc) == 3 ? 1 : 0,

  });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    if(state.files.file_tab == "f1"){// 申込ファイル●
      yield put(change_application_files(payload))
    } else if(state.files.file_tab == "f2"){// 社内ファイル●
      if(payload.length == 0){
        alert("アップロードに失敗しました。")
      }else{
        yield put(change_rent_response_docs(payload))
      }
      
    } else if(state.files.file_tab == "f3"){// 公開ファイル

    } else if(state.files.file_tab == "f4"){// 契約書添付ファイル●
      let contracts: any = []
      payload.rent_contract_docs.map(function (value: any) {
        contracts.push({
          type: 0,
          id: value.id,
          rent_contract_id: value.rent_contract_id,
          status: 0,
          name: value.name,
          mimetype: value.mimetype,
          extension: value.extension,
          is_deleted: value.is_deleted,
          created_at: value.created_at,
          created_user: value.created_user,

        })
      })
      let max_id = 0
      payload.rent_contract_documents.map(function (values: any) {
        contracts.push({
          type: 1,
          id: values.id,
          rent_contract_id: values.rent_contract_id,
          status: values.status,
          name: values.name,
          mimetype: values.mime,
          extension: values.mime,
          is_deleted: false,
          created_at: values.created_at,
          created_user: values.created_user_id,

        })
        max_id = values.name == null && max_id < values.id ? values.id : max_id
      })

      contracts.map(function (values: any) {
        if(values.type == 0) {// 添付ファイル
          values.disp = 1
        }else{// 契約書類
          if(values.name == null){ // 紙の契約書
            if(rent_contract_details.cloudsign_completed_at != null){// 電子契約なら普通の賃貸借契約書非表示
              values.disp = 0
            }else{
              if(max_id == values.id){
                values.disp = 1
              }else{
                values.disp = 0
              }
            }
          }else{// 電子契約
            if(values.status == 3){//署名完了
              values.disp = 1
            }else{
              values.disp = 0
            }
          }
        }
  
  
        return values
      })

      if(state.files.contract_files_all_disp){
        yield put(change_contract_files(contracts))
      }else{
        yield put(change_contract_files(contracts.filter((a:any)=>a.disp == 1)))
      }
      yield put(change_contract_files_all(contracts))

    } else if(state.files.file_tab == "f5"){// 契約書添付●
      yield put(change_rent_response_contract_docs(payload))
    }
    
    // 成功時アクション呼び出し
    // console.log(payload)
    yield put(change_contract_file_update_disp(false));
    yield put(change_contract_files_loading(false));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    alert("アップロードに失敗しました。")
  }
}

// 契約書添付ファイル　表示用ダウンロード●
function* handle_download_disp_contract_files(action: any): any {
  // yield put(change_line_files_loading(true));
  yield put(Contracts.change_contracts_rent_application_doc_uri(""));
  
  const state = yield select();

  const { payloaddetail, errordetail } = yield call(action.payload.type == 0 ? rent_files_api.detail_contracts : rent_files_api.detail_contracts_documents, action.payload.id);
  if (payloaddetail && !errordetail) {

    let api: any
    if (action.payload.type == 0) {
      api = rent_files_api.download_contracts
    } else {
      api = rent_files_api.download_contracts_documents
    }
    // alert(action.payload.extension)

    const { payload, error } = yield call(api, action.payload.id);


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      yield put(Contracts.change_contracts_rent_application_doc_uri(payload));
      yield put(Contracts.change_contracts_doc_extension(action.payload.type == 0 ? payloaddetail.extension.trim() : payloaddetail.mime.trim()));
      yield put(Contracts.change_file_viewer_dialog_disp(true));
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  }
}

// 契約　ダウンロード
function* handle_download_contract_files(action: any): any {

  const state = yield select();
  let api: any
  if (action.payload.type == 0) {
    api = rent_files_api.download_contracts
  } else {
    api = rent_files_api.download_contracts_documents
  }
  // alert(action.payload.extension)

  const { payload, error } = yield call(api, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      // 成功時アクション呼び出し
      const a = document.createElement('a');
      // alert(action.payload.name)
      // console.log(action.payload)
      // ダウンロードされるファイル名
      a.download = action.payload.type == 0 ? action.payload.name+"."+action.payload.extension.trim() : action.payload.status !== 1 ? action.payload.name + ".pdf" : "賃貸借契約書.xlsx";
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
      //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

// 申込ファイル　表示用ダウンロード ●
function* handle_download_disp_application_files(action: any): any {

  const state = yield select();

  const { payloaddetail, errordetail } = yield call(rent_application_docs_api.detail, action.payload.id);
  if (payloaddetail && !errordetail) {


    // yield put(change_line_files_loading(true));
    yield put(Contracts.change_contracts_rent_application_doc_uri(""));

    const { payload, error } = yield call(rent_application_docs_api.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      yield put(Contracts.change_contracts_rent_application_doc_uri(payload));
      yield put(Contracts.change_contracts_doc_extension(payloaddetail.mime.trim()));
      yield put(Contracts.change_file_viewer_dialog_disp(true));
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  }
}

// 申込　ダウンロード
function* handle_download_application_files(action: any): any {

  const state = yield select();

  // alert(action.payload.extension)
  const { payload, error } = yield call(rent_application_docs_api.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      // 成功時アクション呼び出し
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = action.payload.name;
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
      //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }

}

// 申込　ダウンロード blob取得
function* handle_get_blob_application_files(action: any): any {

  const state = yield select();
  let application_files = state.files.application_files

  // alert(action.payload.extension)
  const { payload, error } = yield call(rent_application_docs_api.download, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

      //application_files blob取得
      application_files.map(function (value: any) {
        if(action.payload == value.id){
          value["blob"] = payload
        }
        return value
      })

      yield put(change_application_files(application_files));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }

}

// 社内ファイル　表示用ダウンロード ●
function* handle_download_disp_rent_response_docs(action: any): any {
  // yield put(change_line_files_loading(true));
  yield put(Contracts.change_contracts_rent_application_doc_uri(""));
  
  const state = yield select();

  const { payloaddetail, errordetail } = yield call(rent_response_docs_api.detail, action.payload.id);
  if (payloaddetail && !errordetail) {

    const { payload, error } = yield call(rent_response_docs_api.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      yield put(Contracts.change_contracts_rent_application_doc_uri(payload));
      yield put(Contracts.change_contracts_doc_extension(payloaddetail.mime.trim()));
      yield put(Contracts.change_file_viewer_dialog_disp(true));
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  }
}

// 契約書添付　ダウンロード
function* handle_download_rent_response_docs(action: any): any {

  const state = yield select();

  // alert(action.payload.extension)
  const { payload, error } = yield call(rent_response_docs_api.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      // 成功時アクション呼び出し
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = action.payload.name;
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
      //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }

}

// 契約書添付　表示用ダウンロード ●
function* handle_download_disp_rent_response_contract_docs(action: any): any {
  // yield put(change_line_files_loading(true));
  yield put(Contracts.change_contracts_rent_application_doc_uri(""));
  
  const state = yield select();

  const { payloaddetail, errordetail } = yield call(rent_response_contract_docs_api.detail, action.payload.id);
  if (payloaddetail && !errordetail) {

    const { payload, error } = yield call(rent_response_contract_docs_api.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      yield put(Contracts.change_contracts_rent_application_doc_uri(payload));
      yield put(Contracts.change_contracts_doc_extension(payloaddetail.mime.trim()));
      yield put(Contracts.change_file_viewer_dialog_disp(true));
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  }
}

// 契約書添付　ダウンロード
function* handle_download_rent_response_contract_docs(action: any): any {

  const state = yield select();

  // alert(action.payload.extension)
  const { payload, error } = yield call(rent_response_contract_docs_api.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      // 成功時アクション呼び出し
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = action.payload.name;
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
      //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }

}


// 公開ファイル　ページリスト
function* handle_get_rent_limited_public_pages_list(action: any) {
  // yield put(change_contract_files_loading(true));

  const { payload, error } = yield call(rent_limited_public_pages_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_rent_limited_public_pages_list(payload));
    // yield put(change_contract_files_loading(false));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// 公開ファイル　ページ追加
function* handle_add_rent_limited_public_pages(action: any): any {

  // yield put(change_contract_files_loading(true));
  const state = yield select();

  let rent_limited_public_pages_list = state.files.rent_limited_public_pages_list
  const { payload, error } = yield call(rent_limited_public_pages_api.add, {rent_response_id: state.responsesReducer.responseid});

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    rent_limited_public_pages_list = rent_limited_public_pages_list.concat(payload)
    rent_limited_public_pages_list.sort(function (a: any, b: any) {
      if (a.limited < b.limited) return 1;
      if (a.limited > b.limited) return -1;
      return 0;
    });
    yield put(change_rent_limited_public_pages_list(rent_limited_public_pages_list))
    // yield put(change_rent_limited_public_page_id(payload[0].id))
    yield put(change_rent_limited_public_pages(payload[0]))
    
    // 成功時アクション呼び出し
    // console.log(payload)

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// 公開ファイル　ページ削除更新
function* handle_update_rent_limited_public_pages(action: any): any {
  const state = yield select();

  // console.log(state.files.rent_limited_public_pages)
  // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa")
  let rent_limited_public_pages = state.files.rent_limited_public_pages
  const { payload, error } = yield call(rent_limited_public_pages_api.update, rent_limited_public_pages);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

      let rent_limited_public_pages_list = state.files.rent_limited_public_pages_list
      let rent_limited_public_docs_list = state.files.rent_limited_public_docs_list
      if(rent_limited_public_pages.is_deleted == 1 ){
        // 削除
        rent_limited_public_pages_list = rent_limited_public_pages_list.filter(function (a: any) {
          if (a && a.id !== rent_limited_public_pages.id) {
            return a
          }
        })

        rent_limited_public_docs_list = rent_limited_public_docs_list.filter(function (a: any) {
          if (a && a.rent_limited_public_page_id !== rent_limited_public_pages.id) {
            return a
          }
        })


      }else{
        // 更新
        rent_limited_public_pages_list = rent_limited_public_pages_list.map(function (value: any) {
          if (value.id === rent_limited_public_pages.id) {
              // alert(payload.title)
              value.name = rent_limited_public_pages.name
              value.remarks = rent_limited_public_pages.remarks
              value.limited = rent_limited_public_pages.limited
              value.is_deleted = rent_limited_public_pages.is_deleted
          }
          return value
        })

        rent_limited_public_docs_list = rent_limited_public_docs_list.map(function (value: any) {
          if (value.rent_limited_public_page_id === rent_limited_public_pages.id) {
              value.page_name = rent_limited_public_pages.name
              value.page_remarks = rent_limited_public_pages.remarks
              value.page_limited = rent_limited_public_pages.limited
          }
          return value
        })



      }

      yield put(change_rent_limited_public_pages_list(rent_limited_public_pages_list))
      yield put(change_rent_limited_public_docs_list(rent_limited_public_docs_list))
  


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// 公開ファイル　ファイルリスト
function* handle_get_rent_limited_public_docs_list(action: any) {////////////////つかってない
  // yield put(change_contract_files_loading(true));
alert("つかってない")
  const { payload, error } = yield call(rent_limited_public_docs_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_rent_limited_public_docs_list(payload));
    // yield put(change_contract_files_loading(false));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// 公開ファイル保存
function* handle_upload_limited_public_doc(action: any): any {

  // yield put(change_contract_files_loading(true));
  const state = yield select();
  // console.log(state.files.contract_file_data)

  const { payload, error } = yield call(rent_limited_public_docs_api.upload, { 
    response_id: state.responsesReducer.responseid, 
    file: state.files.contract_file_data, 
    remarks: state.files.contract_file_remarks,
    rent_limited_public_page_id: state.files.rent_limited_public_pages.id, 
  });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    yield put(change_rent_limited_public_docs_list(payload))
    
    // 成功時アクション呼び出し
    // console.log(payload)
    // yield put(change_contract_file_update_disp(false));
    // yield put(change_contract_files_loading(false));
    yield put(change_rent_limited_public_docs_update_disp(false));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// 公開ファイル　ダウンロード
function* handle_download_rent_limited_public_docs(action: any): any {

  const state = yield select();

  // alert(action.payload.extension)
  const { payload, error } = yield call(rent_limited_public_docs_api.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      // 成功時アクション呼び出し
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = action.payload.name;
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
      //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }

}

// 公開ファイル　修正(備考)
function* handle_edit_limited_public_doc_remarks(action: any): any {
  const state = yield select();
  // console.log(state.responsefiles.response_file_data)

  const { payload, error } = yield call(rent_limited_public_docs_api.update, { fileid: action.payload, remarks: state.files.contract_file_remarks});

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let rent_limited_public_docs_list = state.files.rent_limited_public_docs_list.map(function (a: any) {
      if (a.id === action.payload) return { ...a, remarks: state.files.contract_file_remarks };
      return a
    })
    yield put(change_rent_limited_public_docs_list(rent_limited_public_docs_list))
   
    
    // 成功時アクション呼び出し
    // console.log(payload)
    yield put(change_rent_limited_public_docs_update_disp(false));
    

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }



}

// 公開ファイル　削除
function* handle_delete_rent_limited_public_docs(action: any): any {
  const state = yield select();

  const { payload, error } = yield call(rent_limited_public_docs_api.deletefile, action.payload);

  // const { payload, error } = yield call(rent_response_docs_api.deletefile, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {


      let rent_limited_public_docs_list = state.files.rent_limited_public_docs_list.filter(function (a: any) {
        if (a && a.id !== action.payload) {
          return a
        }
      })
      yield put(change_rent_limited_public_docs_list(rent_limited_public_docs_list))
      yield put(change_rent_limited_public_docs_update_disp(false));
  


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// 公開ファイル　表示用ダウンロード
function* handle_download_disp_limited_public_docs(action: any): any {
  // yield put(change_line_files_loading(true));
  yield put(Contracts.change_contracts_rent_application_doc_uri(""));
  
  const state = yield select();

  const { payloaddetail, errordetail } = yield call(rent_limited_public_docs_api.detail, action.payload.id);
  if (payloaddetail && !errordetail) {
    
    const { payload, error } = yield call(rent_limited_public_docs_api.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      yield put(Contracts.change_contracts_rent_application_doc_uri(payload));
      yield put(Contracts.change_contracts_doc_extension(payloaddetail.ext.trim()));
      yield put(Contracts.change_file_viewer_dialog_disp(true));
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  }
}