import React, { Component } from 'react';
import { connect } from 'react-redux';

import ResponseInfo from './ResponseInfoComponent';
import ResponseCustmerDisp from './ResponseCustmerDisp';
import ResponseStatus from './ResponseStatusComponent';
import ResponsesSearchGridComponent from './ResponsesSearchGridComponent';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
// import CustomerInfo from '../Customers/CustomerInfoComponent';
import CustomerLogGridComponent from '../Customers/CustomerLogGridComponent';
import CustomerInfoSearch from '../Customers/CustomerInfoSearch';
// import CustomerInfoEdit from '../Customers/CustomerInfoEditComponent';
import CustomerInfoEdit from '../Customers/CustomerEdit';
import ContactsComponent from '../CustomerContacts/ContactsComponent';
import ResponseFreeDispComponent from './ResponseFreeDispComponent';
import ResponseFreeEditComponent from './ResponseFreeEditComponent';
import RoomList from '../RoomList/Index';
import IntroductionsGridComponent from '../IntroductionList/Index';
import FileUpComponent from '../IntroductionList/FileUpComponent';

import MapSearchComponent from '../MapSearch/Index';
import RoomDocComponent from '../Rooms/RoomDocComponent';

import * as MapSearch from '../MapSearch/Saga/MapSearch';
import * as RoomListSaga from '../RoomList/Saga/RoomList';
import * as MailTemplate from '../MailTemplate/Saga/MailTemplate';
import MapMypage from '../MapMypage/Index';
import Alert from '@mui/material/Alert';
// import Search from '../Search/SearchComponent';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';

import MtButton from '@mui/material/Button';

import LineComponent from '../Line/LineComponent';
import LineDispByUser from '../Line/LineDispByUser';

import LineSign from '../Line/LineSign';
// import LineResponseComponent from '../Line/LineResponseComponent.js_';
import Mail from '../Mail/MailComponent';
import MailResponseComponent from '../Mail/MailResponseComponent';

import * as Responses from './Saga/Responses';
import * as Customers from './../Customers/Saga/Customers';
import * as Rooms from '../Rooms/Saga/Rooms';

import * as IntroductionList from './../IntroductionList/Saga/IntroductionList';

// import ResponseFiles from '../ResponseFiles/Index';
import ResponseFiles from '../Files/Index';

import Loading from '../Layout/LoadingComponent';

import { withRouter } from 'react-router';
import CustomerGrid from "../Customers/CustomerGridComponent";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import '../../Css/Modal.css';
import Customer from '../Customers/Index';

import MypageLog from "../MypageLog/Index";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MuiButton from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';


var moment = require('moment-timezone');

const roomdetail = {
    tatemonoName: "",
    shubetsu: [0, 2, 7, 21],
    kanriKubunNo: [1],
}
interface Props {
    customerloding: number,
    rentResponseId: number,

    customeredit: number,
    // customeredit2: number,
    responseloding: number,
    change_customeredit: any,
    // customereditchange2: any,
    responseeditchange: any,
    customerdetail: any,
    // customerdetail2: any,
    responsedetail: any,
    responseid: number,
    responsedetailData: any,
    responseedit: number,

    customerNewDispSet: any,
    customerNewDisp: number,
    update_customerdetail: any,
    customersearchdisp: number,
    customerloding_search: number,
    customerdetail_search: any,
    responseUpdate: any,
    customerdetailData: any,

    // get_rent_customer_contacts: any,
    // customer_contacts: any,
    // customer_contacts_calendar: any,
    insert_introductions: any,
    get_response_rooms: any,
    responses_search_list_loading: any,
    responses_search_list: any,
    customersubmit: any,
    selectTatemono: any,
    // response_mail_loading: any,
    // response_mails: any,
    rent_mypage_logs_loading: any,
    rent_mypage_logs: any,
    onMypage: any,
    response_lines: any,
    line_user_details: any,
    delete_map_introduction: any,
    response_detail_loading: any,
    get_introduction_details: any,
    introduction_detail_loading: boolean,
    change_fromMapOpen: any,
    classes: any,
    estimate_loading: boolean,
    line_open_flg: boolean,
    lines: any,
    delete_responses: any,
    line_users: any,
    room_list_search_form: any,
    change_room_list_search_form: any,
    customer_contacts: any,
    change_mail_template_category_default: any,

    shops: any,
    copy_customerdetail: any,
    customer_copy_modal: any,
    change_customer_copy_modal: any,

    rent_responses_free_edit_disp: any,

    responses_coming_list: any,
}

interface State {
    modalmap: boolean,
    modalfileup: boolean,

    modalrooms: boolean,
    modalsheet: boolean,
    modalscheduler: boolean,
    tatemonoId: number,
    roomId: number,
    customerdetail: any,
    introduction_file_id: number,
    modalsettings: boolean,
    delete_btn_flg: boolean,

    change: boolean,
    section_id: number,
    copy_loading: boolean,
    type: number,

    // state types
}


const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))


class ResponseDetailsComponent extends Component<Props, State> {
    // constructor(props: Readonly<Props>) {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalmap: false,
            modalfileup: false,

            modalrooms: false,
            modalscheduler: false,
            modalsheet: false,
            tatemonoId: 0,
            roomId: 0,
            customerdetail: this.props.customerdetail,
            introduction_file_id: 0,
            modalsettings: false,
            delete_btn_flg: false,

            change: false,
            section_id: 0,
            copy_loading: false,
            type: 0,
        }


        this.selectsheet = this.selectsheet.bind(this);
        this.togglesheet = this.togglesheet.bind(this);
        this.togglefileup = this.togglefileup.bind(this);
        this.togglefileupsave = this.togglefileupsave.bind(this);


        this.handleCustomerreset = this.handleCustomerreset.bind(this);

        props.customerNewDispSet(1)
        props.change_customeredit(0)
        this.customerNewDispSet = this.customerNewDispSet.bind(this);

        this.handleSelectCustomer = this.handleSelectCustomer.bind(this);



        this.mypage = this.mypage.bind(this);
        this.mypage_edit = this.mypage_edit.bind(this);
        this.togglesettings = this.togglesettings.bind(this);
        this.mypageOpen = this.mypageOpen.bind(this);

        this.pdf = this.pdf.bind(this);
        this.pdf2 = this.pdf2.bind(this);

        this.handleCopyOpen = this.handleCopyOpen.bind(this);
        this.handleCopyClose = this.handleCopyClose.bind(this);
        this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleResponseCopy = this.handleResponseCopy.bind(this);
        

        this.props.change_mail_template_category_default(72)

        this.open_customer_window = this.open_customer_window.bind(this);


        // let searchdata: { rentResponseId: any; tatemonoName: any; }[] = [{
        //     rentResponseId: this.props.responseid,
        //     tatemonoName:""

        //    }];
        // this.props.get_response_rooms(searchdata[0])
        // this.toggle = this.toggle.bind(this);
    }
    handleSelectCustomer(state: any) {
        // console.log(this.props.responsedetail.id)
        this.props.responsedetail.rent_customer_id = state.id;
        this.props.responseUpdate(this.props.responsedetail)

        this.props.customerdetailData({ customerid: state.id })
        this.props.change_customeredit(0)



        // this.setState({ customerdetail: state })


        // this.props.change_customerloding(1)
        // this.props.change_customeredit(0)
    }


    customerNewDispSet() {
        this.props.customerNewDispSet(1)//新規登録の表示

    }

    componentWillMount() {

    }

    handleCustomerreset() {

        this.setState({
            customerdetail: {
                id: 0,
                type: "0",
                sex: "1"
            }
        })
        this.props.change_customeredit(1)
    }

    togglemap = () => {
        this.props.change_fromMapOpen(1)
        this.setState(prevState => ({
            modalmap: !prevState.modalmap,

        }));
    }


    togglerooms = () => {

        this.setState(prevState => ({
            modalrooms: !prevState.modalrooms,

        }));
    }
    togglefileup = (id: any) => {

        this.props.get_introduction_details(id)

        this.setState(prevState => ({
            modalfileup: !prevState.modalfileup,
            introduction_file_id: id
        }));
    }

    togglesettings = () => {

        // this.props.get_introduction_details(id)

        this.setState(prevState => ({
            modalsettings: !prevState.modalsettings,
            //   introduction_file_id:id
        }));
    }


    togglefileupsave = () => {

        this.setState(prevState => ({
            modalfileup: !prevState.modalfileup,
            introduction_file_id: 0
        }));
    }
    togglesheet = () => {

        this.setState(prevState => ({
            modalsheet: !prevState.modalsheet,

        }));
    }




    selectsheet(row: any) {
        console.log(row)
        if (row)
            this.setState(prevState => ({
                modalsheet: !prevState.modalmap,
                tatemonoId: row.tatemonoId,
                roomId: row.roomId
            }));
    }


    mypage() {
        window.open("https://rent.global-center.co.jp/mypage/home/" + this.props.responsedetail.response_code)
    }
    mypage_edit() {
        window.open("https://www.homestation.jp/manage/room/mypage/list?code=" + this.props.responsedetail.response_code)

    }
    mypageOpen() {
        window.open("https://rooms.global-center.co.jp/rent/" + this.props.responsedetail.response_code)

    }

    pdf() {

        window.open("https://www.homestation.jp/manage/room/sheet/rent/all?responseid=" + this.props.responsedetail.id)

    }
    pdf2() {

        window.open("https://www.homestation.jp/manage/room/sheet/sasaki/all?responseid=" + this.props.responsedetail.id)

    }

    roomlistsubmit(state: any) {
        console.log(state)

        this.props.get_response_rooms(state)
    }

    handleCopyOpen(){
        this.props.change_customer_copy_modal(1)
        this.setState({ copy_loading: false })
        this.setState({ change: !this.state.change })
    }
    handleCopyClose(){
        this.props.change_customer_copy_modal(0)
        this.setState({ copy_loading: false })
        this.setState({ change: !this.state.change })
    }
    handleChangeSectionId(e:any){
        this.setState({ section_id: e.target.value })
    }
    handleChangeType(e:any){
        this.setState({ type: e.target.value })
    }
    handleResponseCopy(){
        this.setState({ copy_loading: true })
        this.props.copy_customerdetail({section_id: this.state.section_id, type: this.state.type})
    }

    open_customer_window(id:any) {
        window.open(`/customer/` + id, '_blank', 'noopener')
    }

    render() {
        // console.log("ああああああああああああああああああ")
        console.log(this.props.response_lines)
        // alert(this.props.responsedetail.id)
        return (


            <Row className="item-outer-outer">

                <Col lg="12" className="item-outer-outer" style={{ paddingRight: 15 }}>
                    {this.props.responsedetail.id ? <Col lg="12" className="item-outer" style={{ paddingRight:15 }}>
                        <Card>
                            <CardContent style={{paddingBottom:15}}>
                                {this.props.responseloding === 0 ? <Loading /> : <ResponseStatus />}
                                {/* </div> */}
                            </CardContent>
                        </Card >
                    </Col>:""}
                </Col>
                <Col lg="6" className="item-outer-outer" style={{ paddingRight: 0 }}>
                {(() => {

                    const tyouhuku_list = this.props.responses_search_list.length > 0 && this.props.responsedetail.id ? this.props.responses_search_list.filter(
                        (a:any) => a.endResponseDate == null && 
                        a.sectionId == this.props.responsedetail.section_id && 
                        (
                            (a.tel1 && this.props.customerdetail.tel1 && a.tel1.replace("-", "") == this.props.customerdetail.tel1.replace("-", "")) || 
                            (a.tel2 && this.props.customerdetail.tel1 && a.tel2.replace("-", "") == this.props.customerdetail.tel1.replace("-", "")) || 
                            (a.tel1 && this.props.customerdetail.tel2 && a.tel1.replace("-", "") == this.props.customerdetail.tel2.replace("-", "")) || 
                            (a.tel2 && this.props.customerdetail.tel2 && a.tel2.replace("-", "") == this.props.customerdetail.tel2.replace("-", "")) || 
                            
                            (a.email1 && this.props.customerdetail.mail1 && a.email1 == this.props.customerdetail.mail1) || 
                            (a.email2 && this.props.customerdetail.mail1 && a.email2 == this.props.customerdetail.mail1) ||
                            (a.email1 && this.props.customerdetail.mail2 && a.email1 == this.props.customerdetail.mail2) || 
                            (a.email2 && this.props.customerdetail.mail2 && a.email2 == this.props.customerdetail.mail2) 
                        )) : []

                    // const coming_list = this.props.responses_coming_list && this.props.responses_coming_list.length > 0 ? this.props.responses_coming_list.filter((a:any)=> a.section_id != this.props.responsedetail.section_id && a.max_date && moment(a.max_date) >= moment().add(-3, 'months')) : []
                    const coming_list: any[] = [];

            
                    if(tyouhuku_list.length > 0 || coming_list.length > 0){
                        return <Col lg="12" className="item-outer" style={{ paddingRight: 5 }}>
                            {/* <div className="item"> */}
                            <Card className={this.props.classes.root}>
                                <CardContent>

                                    {tyouhuku_list.length > 0 ? 
                                    <>
                                        <span>重複する反響が登録されています。</span>
                                        {tyouhuku_list.map((value: any) => ( 
                                        <Alert onClick={()=>this.open_customer_window(value.id)} severity="warning" style={{marginTop:2}}>


                                            
                                            {/* <span style={{marginRight:5}} className={"badge " + (value.sectionId == 1 ? "badge-success" : value.sectionId == 2 ? "badge-warning" : value.sectionId == 3 ? "badge-danger" : value.sectionId == 4 ? "badge-dark" : value.sectionId == 6 ? "badge-light" : value.sectionId == 17 ? "badge-info" : value.sectionId == 47 ? "badge-secondary" : "badge-dark")}>{value.sectionName}</span> */}
                                            {value.name}
                                            {value.date ? <span style={{marginLeft:10}}>反響日：{moment(value.date).format("YYYY/MM/DD")}</span>:""}
                                            </Alert>
                                        ))}
                                    </>:""}

                                    {coming_list.length > 0 ? 
                                    <>
                                        <span>他店舗に3ヶ月以内に来店のある反響があります。</span>
                                        {coming_list.map((value: any) => ( 
                                        <Alert onClick={()=>this.open_customer_window(value.id)} severity="warning" style={{marginTop:2}}>

                                            {/* <span style={{marginRight:5}} className={"badge " + (value.sectionId == 1 ? "badge-success" : value.sectionId == 2 ? "badge-warning" : value.sectionId == 3 ? "badge-danger" : value.sectionId == 4 ? "badge-dark" : value.sectionId == 6 ? "badge-light" : value.sectionId == 17 ? "badge-info" : value.sectionId == 47 ? "badge-secondary" : "badge-dark")}>{value.sectionName}</span> */}
                                            
                                            {
                                                value.section_id === 1 ? <span className="badge badge-success">番</span>:
                                                value.section_id === 2 ? <span className="badge badge-warning">レ</span>:
                                                value.section_id === 3 ? <span className="badge badge-danger">駅</span>:
                                                value.section_id === 4 ? <span className="badge badge-dark">東</span>:
                                                value.section_id === 6 ? <span className="badge badge-light">公</span>:
                                                value.section_id === 17 ? <span className="badge badge-info">サ</span>:
                                                value.section_id === 47 ? <span className="badge badge-secondary">通</span>:
                                                value.section_id === 10 ? <span className="badge badge-secondary">シ</span>:
                                                ""
                                            }　
                                            
                                            {value.name}

                                            {value.max_date ? <span style={{marginLeft:10}}>来店日：{moment(value.max_date).format("YYYY/MM/DD")}</span>:""}
                                            
                                        </Alert>
                                        ))}
                                    </>:""}


                                </CardContent>
                            </Card >
                        </Col>
                    }else{
                        return ""
                    }
                    
                })()}





                    <Col lg="12" className="item-outer" style={{ paddingRight: 5 }}>
                        {/* <div className="item"> */}
                        <Card className={this.props.classes.root}>
                            <CardContent>
                                {/* <CountComing /> */}


                                {/* {(this.props.customerloding === 0)? <Loading /> : <CustomerInfo customerno={1} customerdetail={this.props.customerdetail} customeredit={this.props.customeredit} customereditchange={this.props.customereditchange}  handleCustomerreset={this.handleCustomerreset} customersubmit={this.customersubmit}/>} */}
                                {this.props.responsedetail.id ? < Customer rent_contract_id={0} rent_response_id={this.props.responsedetail.id} new={0} old={0} /> : ""}


                                {/* {(this.props.customerloding === 0) ? <Loading /> : this.props.customeredit === 0 ? <CustomerInfoDisp
                                    change_customerloding0={null}
                                    // customerdetailData={null}
                                    change_customerloding1={null}
                                    change_customerloding2={null}
                                    responsedetail={null}
                                    select={0}
                                    responseUpdate={null}
                                    edit={1}
                                    customerno={1}
                                    handleSelectCustomer={null}
                                    customerdetail={this.props.customerdetail}
                                    customereditchange={this.props.change_customeredit}
                                    handleCustomerreset={this.handleCustomerreset}
                                /> : <CustomerInfoEdit closed={1} customerdetail={this.props.customerdetail} />} */}

                            </CardContent>
                        </Card>

                    </Col>

                    {/* closed={1} customerdetail={this.props.customerdetail} customereditchange={this.props.customereditchange} customerno={1} onSubmit={this.props.customersubmit} */}


                    {this.props.customeredit !== 0 ? <div id="customer_search" style={{ display: (this.props.customerNewDisp === 0) ? '' : 'none' }}>
                        <div className="item">
                            顧客サーチ
                            {/* <CustomerListSearchComponent /> */}
                            <CustomerGrid />
                            <br />

                            {this.props.customersearchdisp === 1 ? this.props.customerloding_search === 1 ? <CustomerInfoSearch
                                change_customerloding0={null}
                                // customerdetailData={null}
                                change_customerloding1={null}
                                change_customerloding2={null}
                                responsedetail={null}
                                select={1}
                                responseUpdate={null}
                                edit={0}
                                customerno={-1}
                                handleSelectCustomer={null}
                                customerdetail={this.props.customerdetail_search}
                                customereditchange={null}
                                handleCustomerreset={this.handleCustomerreset}
                            /> : this.props.customerloding_search === 2 ? "" : <Loading /> : ""}
                            {this.props.customersearchdisp === 1 ? this.props.customerloding_search === 1 ? <CustomerLogGridComponent /> : "" : ""}
                        </div>
                    </div> : ""
                    }
                    {/* <Col lg="12" className="item-outer" style={{ display: 'none' }}>
                        <div className="item">

                            {this.props.customerloding2 === 0 ? <Loading /> : <CustomerInfo customerno={2} customerdetail={this.props.customerdetail2} customeredit={this.props.customeredit2} customereditchange={this.props.customereditchange2} />}


                        </div>
                    </Col> */}

                    <Col lg="12" className="item-outer" style={{ paddingRight: 5 }}>
                        {/* <div className="item"> */}
                        <Card className={this.props.classes.root}>
                            <CardContent>
                                {this.props.responseloding === 0 ? <Loading /> : <ResponseInfo responsedetail={this.props.responsedetail} responseedit={this.props.responseedit} responseeditchange={this.props.responseeditchange} />}
                                {/* </div> */}
                            </CardContent>
                        </Card >
                    </Col>

                    <Col lg="12" className="item-outer" style={{ paddingRight: 5 }}>
                        {/* <div className="item"> */}
                        <Card className={this.props.classes.root}>
                        <CardContent>
                                {this.props.responseloding === 0 ? <Loading /> : this.props.rent_responses_free_edit_disp ? <ResponseFreeEditComponent responsedetail={this.props.responsedetail} responseedit={this.props.responseedit} responseeditchange={this.props.responseeditchange} /> : <ResponseFreeDispComponent responsedetail={this.props.responsedetail} responseedit={this.props.responseedit} responseeditchange={this.props.responseeditchange} />}
                                {/* </div> */}
                            </CardContent>
                        </Card >
                    </Col>

                    {this.props.responsedetail.mypage_edited_at ? <Col lg="12" className="item-outer" style={{ paddingRight: 5 }}>
                        {/* <div className="item"> */}
                        <Card className={this.props.classes.root}>
                            <CardContent>
                                {this.props.responseloding === 0 ? <Loading /> : <ResponseCustmerDisp responsedetail={this.props.responsedetail} responseedit={this.props.responseedit} responseeditchange={this.props.responseeditchange} />}
                                {/* </div> */}
                            </CardContent>
                        </Card >
                    </Col> : ""}

                    <Col lg="12" className="item-outer" style={{ paddingRight: 5 }}>
                        {/* <div className="item"> */}
                        <Card className={this.props.classes.root}>
                            <CardContent>
                                <button type="button" style={{ margin: "0 0 5px 5px" }} className="btn btn-sm btn-success float-left"
                                    onClick={this.togglerooms}>部屋検索</button>
                                <button type="button" style={{ margin: "0 0 5px 5px" }} className="btn btn-sm btn-success float-left"
                                    onClick={this.togglemap}>マップ検索</button>


                                <button type="button" style={{ margin: "0 0 5px 5px" }} className="btn btn-sm btn-info float-left"
                                    onClick={this.pdf}>物件資料oldPDF</button>
                                <button type="button" style={{ margin: "0 0 5px 5px" }} className="btn btn-sm btn-info float-left"
                                    onClick={this.pdf2}>物件資料PDF</button>

                                <button type="button" style={{ margin: "0 0 5px 5px" }} className="btn btn-sm btn-success float-left"
                                    onClick={() => this.togglefileup(0)}>資料添付</button>


                                <button type="button" style={{ margin: "0 0 5px 5px" }} className="btn btn-sm btn-primary float-left"
                                    onClick={this.togglesettings}>公開設定</button>




                                {this.props.response_detail_loading === true ? <Loading /> : <IntroductionsGridComponent
                                    rent_response_id={this.props.rentResponseId}

                                    // onMypage={this.props.onMypage}
                                    togglefileup={this.togglefileup}
                                />}
                                {/* </div> */}
                            </CardContent>
                        </Card >
                        <Modal isOpen={this.state.modalsheet} toggle={this.togglesheet} size="xxl" fade={false}>

                            < RoomDocComponent tatemonoId={this.state.tatemonoId} roomId={this.state.roomId} />

                        </Modal>

                        <Modal isOpen={this.state.modalmap} toggle={this.togglemap} size="xxl" fade={false}>
                            <ModalHeader toggle={this.togglemap}>地図検索</ModalHeader>
                            <MapSearchComponent rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} selectsheet={this.selectsheet} />

                        </Modal>

                        <Modal isOpen={this.state.modalfileup} toggle={() => this.togglefileup(0)} size="lg" fade={false}>
                            <ModalHeader toggle={() => this.togglefileup(0)}>資料添付</ModalHeader>
                            <ModalBody >
                                {this.props.introduction_detail_loading === true ? <Loading /> : <FileUpComponent introduction_file_id={this.state.introduction_file_id} rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} togglefileupsave={this.togglefileupsave} />}
                            </ModalBody >
                        </Modal>


                        <Modal isOpen={this.state.modalrooms} toggle={this.togglerooms} size="xxl" fade={false}>
                            {/* <Modal isOpen={this.state.modalsearch} zIndex={3000} toggle={this.modalsearch} size="xxl" fade={false}> */}
                            <ModalHeader toggle={this.togglerooms}>物件検索</ModalHeader>
                            <RoomList
                                rent_response_id={this.props.responseid}
                                mail={null}
                                visit={null}
                            />




                        </Modal>

                        <Modal isOpen={this.state.modalsettings} toggle={this.togglesettings} size="xxl" fade={false}>
                            {/* <Modal isOpen={this.state.modalsearch} zIndex={3000} toggle={this.modalsearch} size="xxl" fade={false}> */}
                            <ModalHeader toggle={this.togglesettings}>公開設定</ModalHeader>
                            <MapMypage rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} selectsheet={this.selectsheet} />

                        </Modal>

                    </Col>

                    <Col lg="12" className="item-outer" style={{ paddingRight: 5 }}>
                        <Card className={this.props.classes.root}>
                            <CardContent>
                                {/* <div className="item"> */}
                                <span className="float-left"> マイページ閲覧履歴</span>
                                <button type="button" style={{ margin: "0 0 5px 5px" }} className="btn btn-sm btn-info float-left" onClick={this.mypage}>マイページ</button>
                                <button type="button" style={{ margin: "0 0 5px 5px" }} className="btn btn-sm btn-success float-left" onClick={this.mypage_edit}>マイページ編集</button>

                                <MypageLog
                                    rent_mypage_logs={this.props.rent_mypage_logs}
                                    rent_mypage_logs_loading={this.props.rent_mypage_logs_loading}
                                />
                                {/* </div> */}
                            </CardContent>
                        </Card >


                    </Col>


                </Col>

                <Col lg="6" className="item-outer-outer" style={{ paddingLeft: 0 }}>

                    <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}>
                        {/* <div className="item"> */}
                        <Card className={this.props.classes.root}>
                            <CardContent>


                                {this.props.responsedetail.id ? <ContactsComponent /> : <Loading />}
                                {/* </div>  */}
                            </CardContent>
                        </Card >

                    </Col>


                    {this.props.line_open_flg ? "" : this.props.line_users.length > 0 ? this.props.line_users.map(function (value: any) {
                        return <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }} key={value.line_user_id}>
                            {value.unfollowed_at ? <Card><CardContent style={{padding:0}}><Alert severity="warning">このラインアカウントからはブロックされています。<br/>{moment(value.unfollowed_at).utc().format("YYYY/MM/DD HH:mm:ss")}</Alert></CardContent></Card> :""}
                            <LineDispByUser line_user={value} key={value.line_user_id} />

                            {/* {this.props.line_open_flg ? "" : this.props.lines.length > 0 ? <LineComponent rent_response_id={this.props.responseid} /> : ""} */}
                            {/* //timelineの長さをとるためlinesの長さが必要　this.props.linesがとれる前に描画されると一番下にスクロールできない */}
                        </Col>
                    }, this) : <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}>
                        <Card className={this.props.classes.root}>
                            <CardContent><LineSign />  </CardContent>
                        </Card >  </Col>}

                    
                    <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}>
                        {/* <div className="item"> */}
                        <Card className={this.props.classes.root}>
                            <CardContent>

                                <MailResponseComponent
                                // rent_response_id={this.props.responseid}
                                // response_mails={this.props.response_mails}
                                // responsedetail={this.props.responsedetail}
                                // customerdetail={this.props.customerdetail}
                                // response_mail_loading={this.props.response_mail_loading} 
                                />
                            </CardContent>
                        </Card >

                    </Col>

                    <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}>
                        <Card className={this.props.classes.root}>
                            <CardContent>
                                {this.props.responsedetail.id ? <ResponseFiles /> : <Loading />}
                            </CardContent>
                        </Card >

                    </Col>
                    <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}>
                        <Card className={this.props.classes.root}>
                            <CardContent>
                                <span className="float-left"> 他反響</span>
                                <ResponsesSearchGridComponent
                                    row={null}
                                    mail_id={0}
                                    responses_search_list_loading={this.props.responses_search_list_loading}
                                    responses_search_list={this.props.responses_search_list}
                                />
                            </CardContent>
                        </Card >
                    </Col>


                    <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}>
                        <Card className={this.props.classes.root}>
                            <CardContent>
                                
                                <div style={{marginBottom:"20px"}}>
                                <MuiButton size="small" style={{ float: "right", marginRight:5 }} variant="contained" color="warning" onClick={() => this.handleCopyOpen()}>
                                    反響複製
                                </MuiButton>
                                </div>

                                <Dialog onClose={this.handleCopyClose} aria-labelledby="案内物件" open={this.props.customer_copy_modal != 0}>
                                    <DialogTitle>反響複製</DialogTitle>
                                    <DialogContent style={{paddingTop:20}}>
                                        {this.props.customer_copy_modal == 1 ? <><FormControl variant="outlined">
                                            <InputLabel id="sectionId-label">店舗</InputLabel>
                                            <Select
                                                style={{width:300}}
                                                labelId="sectionId-label"
                                                id="section_id"
                                                name="section_id"
                                                value={this.state.section_id}
                                                onChange={this.handleChangeSectionId}
                                                label="section_id"
                                                size="small"
                                            >
                                                {this.props.shops.sort((a:any, b:any) => {return a.dispOrder < b.dispOrder ? -1 : 1;}).filter((a:any) => (a.tenpoNo > 0 && a.tenpoNo <=6 && a.tenpoNo !=5) || a.tenpoNo == 10 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 37 || a.tenpoNo == 47 || a.tenpoNo == 55).map(function (item:any, i:any) {
                                                    return (
                                                        <MenuItem key={i} value={item.tenpoNo}>{item.tenpoName}</MenuItem>
                                                    );

                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10, marginTop:20 }}>対象</FormLabel>
                                        <RadioGroup row name="type" onChange={this.handleChangeType} value={this.state.type} >
                                            <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={0} />} label="反響基本情報" />
                                            <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={1} />} label="反響基本情報＋マイページ" />
                                        </RadioGroup>

                                        <div>※マイページも複製する場合、複製元のマイページは<br/>複製後のマイページへ移行されます。</div>
                                        
                                        </>:""}


                                        {this.props.customer_copy_modal == 2 ? <div style={{width:300}}>複製しました。</div>:""}
                                    </DialogContent>
                                    <DialogActions>
                                        {this.props.customer_copy_modal == 1 ? 
                                        <LoadingButton color="warning" disabled={this.state.section_id == 0} loadingPosition="start" loading={this.state.copy_loading} startIcon={<ContentCopyIcon />} variant="contained" onClick={this.handleResponseCopy}>複製する</LoadingButton>
                                        // <Button disabled={this.state.section_id == 0} variant="contained" color="primary" style={{ margin: 5 }} onClick={this.handleResponseCopy}>複製する</Button>
                                        :""}
                                        <MtButton variant="outlined" style={{ margin: 5 }} onClick={this.handleCopyClose}>閉じる</MtButton>
                                    </DialogActions>
                                </Dialog>

                            </CardContent>
                        </Card >
                    </Col>
                    
                    <Col lg="12" className="item-outer" style={{ paddingLeft: 5 }}>
                        <Card className={this.props.classes.root}>
                            <CardContent>

                                {this.state.delete_btn_flg ? <Alert severity="error">反響を削除するともとには戻せません。よろしいですか？
                                    <MtButton style={{ margin: "10px" }} variant="contained" color="secondary" onClick={() => this.props.delete_responses(this.props.responsedetail.id)}>本当に削除する</MtButton>
                                    <MtButton style={{ margin: "10px" }} variant="contained" onClick={() => this.setState({ delete_btn_flg: false })}>キャンセル</MtButton>

                                </Alert> :
                                    // (this.props.responsedetail.rent_contract_id !== 0 ? "契約登録があるため削除できません。" : (this.props.responsedetail.coming_response_count !== 0 ? "来店登録があるため削除できません。" : <MtButton style={{ margin: "10px" }} variant="contained" color="secondary" onClick={() => this.setState({ delete_btn_flg: true })}>反響削除</MtButton>))
                                    (this.props.responsedetail.rent_contract_id !== 0 ? "契約登録があるため削除できません。" : (this.props.customer_contacts.filter((a:any)=>a.way == 3).length ? "来店登録があるため削除できません。" : <MtButton style={{ margin: "10px" }} variant="contained" color="secondary" onClick={() => this.setState({ delete_btn_flg: true })}>反響削除</MtButton>))
                                    }



                            </CardContent>
                        </Card >
                    </Col>




                </Col>
            </Row>

        );
    }
}



//containers
const mapStateToProps = (state: any) => {
    return {
        customer_contacts: state.customerContactsReducer.customer_contacts,
        line_user_details: state.line.line_user_details,
        introduction_detail_loading: state.introductionlist.introduction_detail_loading,
        estimate_loading: state.estimates.estimate_loading,
        line_open_flg: state.line.line_open_flg,
        lines: state.line.lines,
        line_users: state.line.line_users,
        responsedetail: state.responsesReducer.responsedetail,
        room_list_search_form: state.roomlist.room_list_search_form,
        // customerdetail2: state.customersReducer.customerdetail2,
        // responsedetail: state.responsesReducer.responsedetail,

        // customeredit2: state.customersReducer.customeredit2,

        // customerloding2: state.customersReducer.customerloding2,
        customer_copy_modal: state.customersReducer.customer_copy_modal,
        shops: state.masterReducer.shops,
        rent_responses_free_edit_disp: state.responsesReducer.rent_responses_free_edit_disp,
        responses_coming_list: state.responsesReducer.responses_coming_list,


    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        // customereditchange(state: number) {
        //     dispatch(Customers.change_customeredit(state))
        // },
        //     customereditchange2(state: number) {
        //         dispatch(Customers.change_customeredit2(state))
        // },
        responseeditchange(state: any) {
            dispatch(Responses.responseeditchange(state))
        },
        customerdetailData(state: { customerId: number; no: number }) {
            dispatch(Customers.customerDetail(state))
        },
        // responsedetailData(state:number) {
        //     dispatch(Responses.responsedetailData({ responseId: state }))
        // },
        customerNewDispSet(state: number) {
            dispatch(Customers.customerNewDispSet(state))
        },

        responseUpdate(state: any) {
            dispatch(Responses.responseUpdate(state))
        },
        change_customeredit(state: any) {
            dispatch(Customers.change_customeredit(state))
        },


        get_response_rooms(state: any) {
            dispatch(Rooms.get_response_rooms(state))
        },
        get_introduction_details(state: any) {
            dispatch(IntroductionList.get_introduction_details(state))
        },
        change_fromMapOpen(state: any) {
            dispatch(MapSearch.change_fromMapOpen(state))
        },
        delete_responses(state: any) {
            dispatch(Responses.delete_responses(state))
        },

        change_room_list_search_form(state: any) {
            dispatch(RoomListSaga.change_room_list_search_form(state))
        },
        change_mail_template_category_default(state: any) {
            dispatch(MailTemplate.change_mail_template_category_default(state))
        },

        copy_customerdetail(state: any) {
            dispatch(Customers.copy_customerdetail(state))
        },
        change_customer_copy_modal(state: any) {
            dispatch(Customers.change_customer_copy_modal(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResponseDetailsComponent));
